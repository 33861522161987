<script setup lang="ts">

const colorMode = useColorMode()

const cm = computed(() => colorMode.value)

const props = defineProps<{
  col: any[]
  row: Record<string, any>
  cell: string | number
  title: string
  metadata: {
    inferredColumnTitles?: Record<string, string>
    columnToCanonical?: Record<string, string>
  }
  botanicalnames: string[]
  heightValues: (string | number)[]
  widthValues: (string | number)[]
}>()

const cellValue = computed(() => props.cell || '')

const uniqueInCol = computed(() => {
  return [...new Set(props.col)]
})

const shouldRender = computed(() => {
  return !!props.col;
})

onMounted(() => {
  // console.log('propsmounted', props)
})

const sizes_codes = computed(() => {
  if (!props.col) return {};

  return props.col.reduce<Record<string, string>>((acc, size, index) => {
    acc[props.botanicalnames[index] || `Plant ${index + 1}`] = String(size);
    return acc;
  }, {});
});

const data = computed(() => {
  if (props.heightValues && props.widthValues && props.heightValues.length > 0) {
    return null;
  }
  return props.col;
});

// console.log('ColumnStatsPanel computed values:');
// console.log('sizes_codes:', sizes_codes.value);
// console.log('data:', data.value);
// console.log('heightValues:', props.heightValues);
// console.log('widthValues:', props.widthValues);

const toNumber = (value: string | number | undefined): number => {
    if (typeof value === 'undefined') return 0;
    if (typeof value === 'string') {
        const cleanedValue = value.replace(/[^\d.-]/g, '');
        const num = parseFloat(cleanedValue);
        return isNaN(num) ? 0 : num;
    }
    const num = Number(value);
    return isNaN(num) ? 0 : num;
}

const heightValues = computed(() => {
  const heightKey = props.metadata?.columnToCanonical?.['matureheight']
  
  if (!heightKey || !props.col) return [];
  
  return props.col.map(row => {
    const value = row[heightKey];
    return value ? String(value).replace(/[^\d.-]/g, '') : null;
  }).filter(Boolean);
});

const widthValues = computed(() => {
  const widthKey = props.metadata?.columnToCanonical?.['maturewidth']
  if (!widthKey || !props.col) return [];
  
  return props.col.map(row => {
    const value = row[widthKey];
    return value ? String(value).replace(/[^\d.-]/g, '') : null;
  }).filter(Boolean);
});

const isSizeColumn = computed(() => {
  return props.title.includes('x') || 
         props.title.includes('Height') || 
         props.title.includes('Width') || 
         props.title.includes('Size');
})

</script>

<template>
  <div class="w-full h-fit flex flex-col p-3 text-muted-foreground bg-background">
    <div v-if="uniqueInCol" class="scale-75 origin-left pl-3 text-3xl font-bold mb-6 flex items-center">
      <div class="flex flex-col items-center bg-muted-foreground/10 text-muted-foreground rounded-xl p-2 scale-[0.8]">
        <div class="w-full text-center">
          {{ uniqueInCol.length }}
          <div class="text-xs font-regular">UNIQUE</div>
        </div>
      </div>
      <span class="font-normal text-sm px-2">VALUES OF</span>
      <div v-if="props.title" class="text-lg flex">
        <span class="p-1 bg-muted-foreground/10 text-muted-foreground rounded-xl px-2 font-regular">
          {{ props.title }}
        </span>
      </div>
    </div>
    <div class="">
      <StatsPanelBar 
        maxHeight="20vh"
        v-if="shouldRender"
        :cell="cellValue"
        :key="`${props.title}-${cellValue}`"
        :data="props.col"
        :cm="cm"
        :metadata="props.metadata"
        :columnTitle="props.title"
        :botanicalnames="props.botanicalnames"
      />
    
      <StatsPanelBubbleChart 
        v-if="shouldRender && isSizeColumn" 
        :data="data" 
        :cm="cm" 
        :metadata="props.metadata" 
        :title="props.title" 
        :sizes_codes="sizes_codes"  
        :columnTitle="props.title"
        :cell="toNumber(props.cell)"
        :heightValues="props.heightValues"
        :widthValues="props.widthValues"
      />

      <NameStatus 
        v-if="props.col && (props?.metadata?.inferredColumnTitles?.[props.title] === 'botanicalname')"
        :data="props.col" 
        :cell="props.cell" 
        :metadata="metadata" 
      />
    </div>
  </div>
</template>

<style scoped>

</style>

