<script setup>
import { useTools } from '@/composables/tools'
import { useChat } from '@/composables/chat'
import { theme } from "#tailwind-config";
import { watchDebounced, useElementBounding, useMagicKeys, whenever } from '@vueuse/core'
import { read, writeFileXLSX } from "xlsx";
import '~/assets/css/tabulator_style.css';
import { Loader2 } from 'lucide-vue-next'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card'
import { onClickOutside } from '@vueuse/core'
import { row } from '@unovis/ts/components/timeline/style';
import { useDockStore } from '@/stores/dockStore'
import TabulatorTable from './TabulatorTable.vue'
import { useProjectData } from '~/composables/projectData'
import PanelComponent from '@/components/PanelComponent.vue'

import { useDataUtils } from '@/composables/dataUtils';
import { useMouse } from '@vueuse/core'
import { useThrottleFn } from '@vueuse/core'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'


const { mapSpeciesToColors } = useDataUtils();
// Store imports and setup
const projectStore = useProjectStore()
const { selectedProject } = storeToRefs(projectStore)
const dockStore = useDockStore();
const uiStore = useUiStore()

// Composables
const { tools } = useTools()
const { chatLLM } = useChat()
const colorMode = useColorMode();
const client = useSupabaseClient();
useSquircleObserver()
// Refs and computed properties
const plantscheduleRef = ref(null)
const { width: plantscheduleWidth, height: plantscheduleHeight, top: plantscheduleTop, left: plantscheduleLeft } = useElementBounding(plantscheduleRef)
const { windowWidth, chatDialogOpen } = storeToRefs(uiStore)
const beamSize = computed(() => Math.floor(windowWidth.value * 1.4))
const loggingEnabled = ref(false)

// Store refs
const { dragOver, panelProps, includeDataInChat, activePanel, saving, panelState, panelToRefresh, availablePanels } = storeToRefs(dockStore);
const { setDragOver, updatePanelState, mergeData, setActivePanel, addPanelState, removePanelToRefresh, setAvailablePanel, removeAvailablePanel, updatePanelRows, updatePanelColumns } = dockStore;

const isMobile = computed(() => {
  return windowWidth.value <= 760;
});

const enableHovers = ref({
  row: false,
  column: isMobile.value ? false : true,
})

// Props and emits
const props = defineProps({
  panel: { type: Object, required: true },
  height: { type: [Number, String], required: false },
  panel_id: { type: String, required: true },
  fetchedPanelData: { type: Object, required: false },
  testMode: { type: Boolean, required: false }
});

const emit = defineEmits(['error', 'update:shared-data', 'column-title-changed', 'column-updated'])

// State
const loading = ref(true);
const tabulator = ref(null)
const tables = ref(null)
const forceUseText = ref(null);
const title = ref('')
const inferredColumnTitles = ref(null)

const refreshedKey = ref(0)

const isEditingFalseOnMouseOut = ref(false)

// Computed properties
const tool_id = computed(() => tools.find(tool => tool.label === 'Schedule').uid)
const fileAnalysis = computed(() => {
  return props.panel?.data?.output?.[0]?.items || props.panel?.data?.output?.message?.[0]?.pages?.[0]?.items
})
const projectAnalysis = computed(() => {
  return props.panel?.data?.output?.tables
})

const metadata = ref(null)

// Methods
const log = (...args) => {
  if (loggingEnabled.value) {
    console.log(`[PlantSchedule.vue]`, ...args)
  }
}

const parseFileAnalysis = async (output) => {
  let useText = forceUseText.value;

  if (useText === null) {
    // Auto-detect if not forced
    const totalMdLength = output.reduce((sum, item) => sum + (item.md?.replace(/\s/g, '') || '').length, 0);
    const totalTextLength = output.reduce((sum, item) => sum + (item.text?.replace(/\s/g, '') || '').length, 0);
    useText = totalTextLength > totalMdLength * 1.2;
  }

  const flattenedArray = output.flatMap(item => useText ? item.text : item.md).filter(Boolean);

  let md_items = flattenedArray.join('\n\n');
  let { data: cached_grq_js } = useNuxtData(`grq-js-${props.panel_id}`)

  if (cached_grq_js.value?.data?.tables) {
    console.log('using cached grq response')
    return toRaw(cached_grq_js.value.data.tables);
  }
  // let res = ref({data: {tables: []}})
  console.log('getting grq response')
  loading.value = true
  let { data: res } = await useAsyncData(
    `grq-js-${props.panel_id}`, // Unique key for this request
    () => $fetch('/api/ai/grq-js', {
      method: 'POST',
      body: {
        prompt: md_items
      }
    })
  );
  loading.value = false
  return formatForTabulator(toRaw(res?.value?.data?.tables));
}

const getTables = async () => {
  if (projectAnalysis.value) {
    console.log('using cached project analysis', projectAnalysis.value)
    return formatForTabulator(toRaw(projectAnalysis.value));
  }
  const output = fileAnalysis.value
  console.log('output', output)
  const parsedTables = await parseFileAnalysis(output)
  return formatForTabulator(parsedTables);
}

const formatForTabulator = (parsedTables) => {
  if (!parsedTables) {
    console.log('could not get tabulator data');
    return null;
  }

  console.log('formatting for tabulator');

  // Check if parsedTables is already in {rows: [], columns: []} format
  if (Array.isArray(parsedTables.rows) && Array.isArray(parsedTables.columns)) {
    // Data is already in the correct format, just need to format columns
    const columns = parsedTables.columns.map(col => ({
      title: col.title || col.field,
      field: col.field,
      headerWordWrap: true,
      headerSort: true,
      editor: "input",
      resizable: "header",
      headerVertical: false,
      formatter: function (cell, formatterParams) {
        const value = cell.getValue();
        if (typeof value === 'number') {
          return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
        }
        return value;
      },
    }));

    return { columns, rows: parsedTables.rows };
  }

  // If not in the fallback format, process as before
  const allColumns = new Set();
  const rows = [];

  parsedTables.forEach((table, tableIndex) => {
    table.columns.forEach(col => allColumns.add(col));
    table.rows.forEach((row, rowIndex) => {
      const rowData = {
        id: `${tableIndex}-${rowIndex}`,
        _group: table.group_id || 'Plants'
      };
      table.columns.forEach((col, colIndex) => {
        rowData[col] = row[colIndex];
      });
      rows.push(rowData);
    });
  });

  const columns = Array.from(allColumns).map(col => ({
    title: col,
    field: col,
    headerWordWrap: true,
    headerSort: true,
    editor: "input",
    resizable: "header",
    headerVertical: false,
    formatter: function (cell, formatterParams) {
      const value = cell.getValue();
      if (typeof value === 'number') {
        return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
      }
      return value;
    },
  }));

  return { columns, rows };
};

const downloadAs = (type) => {
  console.log('downloading', tabulator)
  try {
    tabulator.value.download({
      filename: `${selectedProject.value.title} (${panelState?.[panel_id]?.fileMetadata?.filename}).${type}`,
      sheetName: selectedProject.value.title,
      fileType: type
    });
  } catch (e) {
    console.error('error downloading', e)
  }
}

const handleClick = () => {
  const newActivePanel = props.panel.panel.name;
  if (newActivePanel !== activePanel.value) {
    setActivePanel(newActivePanel);
  }
}

const updateSharedData = () => {
  if (tables.value && metadata.value) {
    console.log('updating Shared Data', tables.value, metadata.value)
    const botanicalNameKey = metadata?.value?.columnToCanonical?.['botanicalname'];
    const botanicalNames = tables.value?.rows?.map(row => row[botanicalNameKey]).filter(Boolean);
    console.log('updateSharedData botanicalNames:', botanicalNames);
    const colorMap = mapSpeciesToColors(botanicalNames);
    const taxon = botanicalNames?.map(name => ({
      name,
      color: colorMap[name],
      visible: true
    }));
    console.log('updateSharedData taxon:', taxon);
    const sharedData = {
      botanicalNames,
      metadata: metadata.value,
      tool_uid: tool_id.value
    };
    
    emit('update:shared-data', sharedData);

    // // Update the panelState directly
    // addPanelState({
    //   [props.panel_id]: {
    //     ...panelState.value[props.panel_id],
    //     sharedData
    //   }
    // });

    console.log('Registering PlantSchedule as available panel:', props.panel_id, sharedData);
    setAvailablePanel({
      panel_id: props.panel_id,
      tool_uid: tool_id.value,
      metadata: sharedData
    });
  }
};

// Watch for changes in tables and metadata
watch([() => tables.value, () => metadata.value], ([newTables, newMetadata]) => {
  updateSharedData()
  if (newTables && newMetadata) {
    console.log('newTables and newMetadata', newTables, newMetadata)
    
  } else {
    console.log('Removing PlantSchedule from available panels:', props.panel_id);
    removeAvailablePanel(props.panel_id);
  }
}, { deep: true });

// Use projectData composable
const projectData = useProjectData();
const { updatePanelStateOutput, updatedAndSavedPanel, setupPanelWatchers, getImageForProject } = projectData;

// Setup watchers

watch(panelToRefresh, (newVal) => {
  if(newVal.includes('PlantSchedule')){
    refreshPanel()
    removePanelToRefresh('PlantSchedule')
  }
})

const refreshPanel = async () => {
  refreshedKey.value++;
  console.log('Refreshing panel, current tables:', tables.value);
  if (!tables.value) {
    console.log('Fetching tables...');
    tables.value = await getTables();
    console.log('Fetched tables:', tables.value);
  }
  if (tables.value && !metadata.value) {
    console.log('Fetching metadata...');
    metadata.value = await getMetadata();
    console.log('Fetched metadata:', metadata.value);
  }
  metadata.value = await getMetadata();
  updateSharedData();
  // console.log('Updated Shared Data with Botanical Names:', tables.value.map(row => row[metadata.value.inferredColumnTitles.botanicalname]));
};

if(!props.testMode){
  const updateState = setupPanelWatchers(props.panel, props.panel_id, tool_id.value, tables, 'tables', saving, metadata)
  
  watch(() => updateState(), (newState) => {
    if (newState) {
      addPanelState(newState)
      // Update shared data when state changes
      updateSharedData()
    }
  }, { deep: true })
}

watch(tables, async (newTables) => {
  if (newTables == undefined) {
    push.error({ title: 'Error', message: "Couldn't read the plant schedule. Try again with a different file" })
  } else {
    loading.value = false
    // Update shared data when tables change
    await updateSharedData()
  }
})

// New watcher for updating available panels
watch([() => tables.value, () => metadata.value], ([newTables, newMetadata]) => {
  if (newTables && newMetadata) {
    nextTick(() => {
      setAvailablePanel({
        panel_id: props.panel_id,
        metadata: newMetadata,
        tool_uid: tool_id.value
      })
    })
  } else {
    nextTick(() => {
      removeAvailablePanel(props.panel_id)
    })
  }
}, { deep: true })

const { ctrl_z, escape, enter } = useMagicKeys()

watch(ctrl_z, (pressed) => {
  if (pressed && tabulator.value) {
    try {
      tabulator.value.undo()
    } catch (e) {
      console.warn("can't undo", e)
    }
  }
})

watch(escape, (pressed) => {
  if (pressed) {
    console.log('escape pressed')
    cleanupEditingState()
  }
})

watch(enter, (pressed) => {
  if (pressed && isEditing.value) {
    console.log('enter pressed while editing')
    // Wait for the next tick to ensure the value is saved
    nextTick(() => {
      cleanupEditingState()
    })
  }
})

const colDiv = reactive({
  show: false,
  top: 0,
  left: 0,
  width: 0,
  height: 0
})

const cellDiv = reactive({
  show: false,
  top: 0,
  left: 0,
  width: 0,
  height: 0
})

const rowDiv = reactive({
  show: false, 
  top: 0,
  left: 0,
  width: 0,
  height: 0
})

const handleRowMouseOver = ({ event, rowData, boundingBox }) => {
}

const handleHeaderMouseOver = ({ event, column }) => {
  // console.log('header mouse over', event, column)
}

const scheduleOffsets = computed(() => {
  return {
    top: plantscheduleRef.value.getBoundingClientRect().top,
    left: plantscheduleRef.value.getBoundingClientRect().left,
  width: plantscheduleRef.value.getBoundingClientRect().width,
    height: plantscheduleRef.value.getBoundingClientRect().height
  }
})
const plantscheduleHeader = ref(null)

const tableHeight = ref(0)

const tableRect = ref(null)

const headerHeight = ref(null)
const cellData = ref(null)

const showColumnStats = ref(true)
const showRowStats = ref(true)
const columnStats = ref(null)
const columnStatsHeight = ref(0)
const lastCol = ref(false)
const isEditing = ref(false)

const columnData = ref(null)
const rowData = ref(null)

const columnTitle = ref('')


const handleCellMouseOver = ({ event, cell, boundingBox }) => {
  // Don't apply hover effects if we're editing, transitioning to edit mode, or in bulk edit preview
  if (isEditing.value || isEditingFalseOnMouseOut.value || cell._cell.table.element.classList.contains('bulk-edit-preview')) {
    return
  }
  
  // Reset visibility flags when hovering over new cells
  showColumnStats.value = true
  showRowStats.value = true
  
  cellData.value = cell._cell.value
  rowData.value = cell._cell.row.getData()
  columnData.value = cell._cell.column.cells
    .map(cell => cell.value)
    .filter(value => value !== null && value !== undefined && value !== '')
  columnTitle.value = cell._cell.column?.definition?.title || ''
  
   
  let colElement = cell._cell.column.element.getBoundingClientRect()
  let firstCell = cell._cell.column.cells[0].getElement().getBoundingClientRect()
  let lastCell = cell._cell.column.cells[cell._cell.column.cells.length - 1].getElement().getBoundingClientRect()
  // console.log('lastCell', cell._cell.column.cells[cell._cell.column.cells.length - 1])
  let plantscheduleRefRect = plantscheduleRef.value.getBoundingClientRect()
  let cellElement = cell.getElement().getBoundingClientRect()
  let rowElement = cell._cell.row.getElement().getBoundingClientRect()
  let headerElement = cell._cell.column.parent.headersElement.getBoundingClientRect()
  
  headerHeight.value = headerElement.value?.height
  
  let plantscheduleHeaderElement = plantscheduleHeader.value.getBoundingClientRect()

  if (!cell._cell.column.field) {
    // cell.getElement().classList.remove('hover')
    showColumnStats.value = false
  } else{
    showColumnStats.value = true
    showRowStats.value = true
  }
    
  if (enableHovers.value.column) {
    
      // loop over all cells and add a class to all of them
      cell._cell.column.cells.forEach((cell, index) => {
          // cell.getElement().classList.add('hover')
      })
      
    
  }
  if (enableHovers.value.row) {
    // console.log(cell._cell.row)
    cell._cell.row.cells.forEach(cell => {
      // cell.getElement().classList.add('hover')
    })
  }

  let convexBoundingRect = {
    x: lastCell.x,
    y: colElement.y,
    width: lastCell.x + lastCell.width - firstCell.x,
    height: ((lastCell.y) - (colElement.y))
  }


  let cellBoundingRect = {
    x: cellElement.x,
    y: cellElement.y,
    width: cellElement.width,
    height: cellElement.height
  }

  
  let hardCodedOffsets = {
    top: (-67 - headerElement.height),
    left: 5.5,
    width: 0,
    height: 0 + headerElement.height
  }

  let groupElement = cell._cell.column.groupElement.getBoundingClientRect()

  colDiv.show = true
  colDiv.top = 0
  colDiv.left = convexBoundingRect?.x + hardCodedOffsets.left - plantscheduleLeft.value + hardCodedOffsets.left
  colDiv.width = cellBoundingRect?.width + hardCodedOffsets.width
  colDiv.height = ((cell._cell.row.parent.displayRowsCount) * cell._cell.height)  
  

  cellDiv.show = true
  cellDiv.top = cellBoundingRect.y + hardCodedOffsets.top
  cellDiv.left = cell._cell.element.x + hardCodedOffsets.left
  cellDiv.width = cellBoundingRect.width + hardCodedOffsets.width
  cellDiv.height = groupElement.height

  rowDiv.show = true
  rowDiv.left = 0
  rowDiv.width = plantscheduleRefRect.width
  rowDiv.height = rowElement.height
  rowDiv.top = rowElement.top - scheduleOffsets.value.top + plantscheduleHeaderElement.height + 10
  rowDiv.bottom = rowElement.bottom

  
} 





watch(columnStats, (newVal) => {
  if(newVal){
    columnStatsHeight.value = newVal.getBoundingClientRect().height
  }
})

onClickOutside(columnStats, () => {
  if (isEditing.value) {
    // showColumnStats.value = false
    // showRowStats.value = false
  }
}, { ignore: ['.tabulator-cell', '.tabulator-table'] })


onClickOutside(plantscheduleRef, (event) => {
  // console.log('onClicOutside triggered', event.target);

  if (isEditing.value && isInteractingWithStats.value) {
    console.log('Ignoring click because interacting with stats');
    return;
  }

  if (isEditing.value) {
    // Clear editing state
    if (lastEditedCell.value) {
      lastEditedCell.value.table.element.classList.remove('table-editing');
      lastEditedCell.value.getElement().classList.remove('cell-editing');
    }
    isEditing.value = false;
    editingCell.value = null;
    editMenuOptions.value = [];
    frozenStatsPosition.value = null;
    isEditingFalseOnMouseOut.value = false;
    lastEditedCell.value = null;
    selectedBulkEditOption.value = null;
    originalCellValue.value = '';
    showColumnStats.value = false;
    showRowStats.value = false;
    isInteractingWithStats.value = false;
  }
}, { ignore: ['.tabulator-cell', '.stats-panel', '.bulk-edit-options'] });

const handleTableMouseLeave = () => {
  // colDiv.show = false
}

const handleGroupMouseMove = ({ e, group }) => {
  if (isEditing.value) return
  showColumnStats.value = false
  showRowStats.value = false
}

const handleCellMouseLeave = ({ e, cell }) => {
  // Don't remove hover effects if we're editing, transitioning to edit mode, or in bulk edit preview
  if (isEditing.value || isEditingFalseOnMouseOut.value || cell._cell.table.element.classList.contains('bulk-edit-preview')) {
    return
  }

  if (enableHovers.value.column) {
    cell._cell.column.cells.forEach(cell => {
      cell.getElement().classList.remove('hover')
    })
  }
  if(enableHovers.value.row){
    cell._cell.row.cells.forEach(cell => {
      cell.getElement().classList.remove('hover')
    })
  }
}


const editingValue = ref('')

// Add this new ref to store the original value
const originalCellValue = ref('')

// Modify handleCellEditing to properly store cell reference and initial value
const handleCellEditing = ({ e, cell }) => {
  console.log('applyTo: handleCellEditing', {
    tabulator: tabulator.value,
    cell
  });
  
  tabulator.value.setTableEditing(false);
  
  frozenStatsPosition.value = {
    x: statsPosition.x,
    y: statsPosition.y
  };
  
  lastCol.value = cell._cell.column.parent.columnsByIndex.indexOf(cell._cell.column) === cell._cell.column.parent.columnsByIndex.length - 1;
  cellData.value = cell._cell.value;
  originalCellValue.value = cell._cell.value;
  editingCell.value = cell; // Store the entire cell component
  lastEditedCell.value = cell;
  editingValue.value = cell._cell.value;
  
  const columnField = cell._cell.column.field;
  const botanicalNameKey = metadata.value?.columnToCanonical['botanicalname'];
  const botanicalName = cell._cell.row.getData()?.[botanicalNameKey];
  
  console.log('applyTo: getting botanical name', {
    botanicalNameKey,
    botanicalName,
    rowData: cell._cell.row.getData(),
    cell: cell
  });
  
  const matchingBotanicalRows = tables.value.rows.filter(row => 
    row[botanicalNameKey] === botanicalName
  ).length;
  
  // Get current group name
  const currentGroup = cell._cell.row.getData()?._group || '';
  const groupMatchCount = tables.value.rows.filter(row => row._group === currentGroup).length;
  
  // Create edit menu options using the new function
  editMenuOptions.value = createEditMenuOptions(
    botanicalName,
    matchingBotanicalRows,
    currentGroup,
    groupMatchCount
  );

  cell._cell.table.element.classList.add('table-editing');
  cell._cell.getElement().classList.add('cell-editing');
  isEditing.value = true;

  // Set up input event listener to track changes
  nextTick(() => {
    const inputElement = cell._cell.getElement().querySelector('input')
    if (inputElement) {
      inputElement.addEventListener('input', (e) => {
        editingValue.value = e.target.value
        console.log('applyTo: input value changed', { 
          value: e.target.value,
          cell: editingCell.value
        })
      })

      // Add enter key handler
      inputElement.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          e.preventDefault() // Prevent default to avoid potential double-handling
          inputElement.blur() // Remove focus from input
          cleanupEditingState()
        }
      })
    }
  })
}

const handleCellEditCancelled = ({ e, cell }) => {
  if (isInteractingWithStats.value) return;
  
  try {
    if (lastEditedCell.value?._cell) {
      const element = lastEditedCell.value._cell.getElement();
      if (element) {
        element.classList.remove('cell-editing');
      }
      
      const table = lastEditedCell.value._cell.table;
      if (table?.element) {
        table.element.classList.remove('table-editing');
      }
    }
  } catch (error) {
    console.warn('Error cleaning up cell classes:', error);
  }
  
  isEditingFalseOnMouseOut.value = true;
  isEditing.value = false;
  editingCell.value = null;
  editMenuOptions.value = [];
  frozenStatsPosition.value = null;
  lastEditedCell.value = null;
  
  setTimeout(() => {
    showColumnStats.value = true;
    showRowStats.value = true;
    isEditingFalseOnMouseOut.value = false;
  }, 100);
};

// Add this ref to track the selected bulk edit option
const selectedBulkEditOption = ref(null)


// Modify handleCellEdited to handle both the option selection and application
const handleCellEdited = ({ e, cell }) => {
  console.log('applyTo: cell edited', { 
    cell, 
    selectedOption: selectedBulkEditOption.value,
    editingValue: editingValue.value,
    originalValue: originalCellValue.value
  });
  
  // After cell edit, update the panel state and trigger refresh
  const currentData = cell._cell.table.getData();
  if (panelState.value[props.panel_id]) {
    panelState.value[props.panel_id].output = {
      ...panelState.value[props.panel_id].output,
      tables: {
        ...panelState.value[props.panel_id].output.tables,
        rows: currentData
      }
    }
  }
  // Trigger refresh of diversity status
  dockStore.refreshPanel('DiversityStatus')
};



// watchDebounced(plantscheduleWidth, (newWidth) => {
// watchDebounced(plantscheduleWidth, (newWidth) => {
//   if(tables.value?.columns){
//     tables.value.columns = newWidth < 640
//   }
// }, { immediate: true, debounce: 50 })




const handleRowAdded = (rowData) => {
  console.log('New row added:', rowData);
};


// Use useMagicKeys to detect spacebar press
const { space } = useMagicKeys()

// Hide stats when spacebar is pressed
whenever(space, () => {
  // prevent default
     
  showColumnStats.value = false
  showRowStats.value = false
})

const invert = obj => Object.fromEntries(Object.entries(obj).map(a => a.reverse()))

const inferColumnTypes = async () => {
  if (!tables.value || !tables.value.columns) {
    console.warn('Tables or columns are not available yet');
    return {};
  }

  let oldTitles = JSON.stringify(tables.value.columns.map(column => column.title))
  try {
    const response = await chatLLM(`You are an expert landscape architect, Give the following list of plant schedule table column headings, match them with their canonical labels. You must only respond in json by mapping each given column title as key, and value set to the closest matching canonical value, leave empty if none found. duplicate columns and values are allowed. Do not respond with any other text just speak json. List of canonical values: ['qty', 'botanicalname', 'commonname', 'maturesize', 'maturewidth', 'matureheight', 'potsize', 'spacing', 'density', 'code', 'width', 'height', 'spread', 'proportionpercentage'] ${oldTitles}`)

    // Clean up the response
    const cleanedResponse = response
      .replace(/\n/g, '') // Remove newlines
      .replace(/\\/g, '') // Remove backslashes
      .replace(/^"|"$/g, '') // Remove leading and trailing quotes if present

    return {inferredColumnTitles: JSON.parse(cleanedResponse), columnToCanonical: invert(JSON.parse(cleanedResponse))}  
  } catch (error) {
    console.error('Error inferring column types', error)
    return {} // Return an empty object in case of error
  }
}

const heightValues = ref([])
const widthValues = ref([])

const getMetadata = async () => {
  // Get metadata from panel state or props with fallback to empty object
  const metadata = props.panel?.data?.output?.metadata || 
                  panelState.value[props.panel_id]?.output?.metadata || 
                  {};
  const result = { ...metadata };

  if (!result.title) {
    let commonNames = tables.value?.rows?.map(row => row.col2).filter(Boolean) || [];
    if (commonNames.length > 0) {
      const commonNamesString = JSON.stringify(commonNames.slice(0, 10));
      result.title = await chatLLM(`You are an expert landscape architect. Given the following list of common name plants from planting pallet, think of the most relevant but extremely specific documentation package title or project name. Something sensible, not too much flourish or creativity. You must provide two or three word title and say nothing other than those words in your response (stripped of quotation marks, hyphens). Pallet: ${commonNamesString}`);
    } else {
      result.title = "Plant Schedule";
    }
  }

  // Safely handle column inference
  if (!result.inferredColumnTitles && tables.value?.columns) {
    try {
      let { inferredColumnTitles = {}, columnToCanonical = {} } = await inferColumnTypes() || {};
      result.inferredColumnTitles = inferredColumnTitles;
      result.columnToCanonical = columnToCanonical;
    } catch (error) {
      console.warn('Error inferring column types:', error);
      result.inferredColumnTitles = {};
      result.columnToCanonical = {};
    }
  }

  // Safely identify mature height and width columns with null checks
  if (tables.value?.columns && result.inferredColumnTitles) {
    try {
      // Find height and width keys with safe navigation
      const heightKey = Object.keys(result.inferredColumnTitles || {})
        .find(key => result.inferredColumnTitles?.[key] === 'matureheight');
      const widthKey = Object.keys(result.inferredColumnTitles || {})
        .find(key => result.inferredColumnTitles?.[key] === 'maturewidth');

      // Only proceed if we found valid columns
      if (heightKey) {
        const heightColumn = tables.value.columns
          .find(column => column?.title?.toLowerCase()?.includes(heightKey.toLowerCase()));
        if (heightColumn?.field) {
          heightValues.value = tables.value.rows
            .map(row => row[heightColumn.field])
            .filter(Boolean);
        }
      }

      if (widthKey) {
        const widthColumn = tables.value.columns
          .find(column => column?.title?.toLowerCase()?.includes(widthKey.toLowerCase()));
        if (widthColumn?.field) {
          widthValues.value = tables.value.rows
            .map(row => row[widthColumn.field])
            .filter(Boolean);
        }
      }

      if (heightKey && widthKey) {
        console.log(`Separate height and width columns detected`);
      }
    } catch (error) {
      console.warn('Error processing height/width columns:', error);
      heightValues.value = [];
      widthValues.value = [];
    }
  }

  // Ensure fileMetadata exists
  if (!result.fileMetadata) {
    result.fileMetadata = props?.panel?.data?.fileMetadata || {};
  }

  console.log('Metadata result:', result);
  return result;
};

// Initial data fetch
onMounted(async () => {
  await updateSharedData()
  
  await refreshPanel()
})






// Content type mapping
const contentTypeToExtension = ref({
  'application/pdf': { label: 'PDF', iconLarge: 'vscode-icons:file-type-pdf2', iconSmall: 'ph:file-pdf-fill' },
  'application/msword': { label: 'Word Document', iconLarge: 'vscode-icons:file-type-word', iconSmall: '' },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': { label: 'Word Document', iconLarge: 'vscode-icons:file-type-word', iconSmall: '' },
  'application/vnd.ms-excel': { label: 'Excel Document', iconLarge: 'vscode-icons:file-type-excel', iconSmall: '' },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': { label: 'Excel Document', iconLarge: 'vscode-icons:file-type-powerpoint', iconSmall: '' },
  'application/vnd.ms-powerpoint': { label: 'PowerPoint', iconLarge: 'vscode-icons:file-type-powerpoint', iconSmall: '' },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': { label: 'PowerPoint', iconLarge: 'vscode-icons:file-type-powerpoint', iconSmall: '' },
  'text/plain': { label: 'Text file', iconLarge: 'hugeicons:txt-02', iconSmall: '' },
})

const editing = ref(false)

const handleUpdateTitle = async (newTitle) => {
  metadata.value.title = newTitle
  // Update the panelState
  addPanelState({
    [props.panel_id]: {
      ...panelState.value[props.panel_id],
      title: newTitle,
      output: {
        ...panelState.value[props.panel_id]?.output,
        metadata: {
          ...panelState.value[props.panel_id]?.output?.metadata,
          title: newTitle
        }
      }
    }
  });

  editing.value = false
}


const handleMouseLeaveColumnStats = () => {
  if (isInteractingWithStats.value) return
  
  if (isEditingFalseOnMouseOut.value) {
    isEditing.value = false
    isEditingFalseOnMouseOut.value = false
    showColumnStats.value = false
    showRowStats.value = false
  }
  // Don't hide stats if we're not in editing mode
  if (!isEditing.value) {
    showColumnStats.value = true
    showRowStats.value = true
  }
}

const handleMouseLeaveRowStats = () => {
  if (isEditingFalseOnMouseOut.value) {
    isEditing.value = false
    isEditingFalseOnMouseOut.value = false
    showColumnStats.value = false
    showRowStats.value = false
  }
}

const addRow = () => {
  if (tabulator.value) {
    tabulator.value.addNewRow(); // Add to top of table
  }
};

const handleColumnsUpdate = (newColumns) => {
  // updatePanelColumns(props.panel_id, newColumns);
  // // Ensure the local state is updated as well
  // if (tables.value) {
  //   tables.value.columns = newColumns;
  // }
};

const handleRowsUpdate = (newRows) => {
  // Update the panelState with new rows
  if (panelState.value[props.panel_id]) {
    panelState.value[props.panel_id].output = {
      ...panelState.value[props.panel_id].output,
      tables: {
        ...panelState.value[props.panel_id].output.tables,
        rows: newRows
      }
    }
  }
  // Trigger refresh of diversity status
  dockStore.refreshPanel('DiversityStatus')
};


// Assuming you have access to the project data, if not, you'll need to fetch it
const project = computed(() => props.panel?.data?.project || {})

// Fetch the project image URL
const projectImageUrl = ref('')

const base_url = useRuntimeConfig().public.imagekit_base_url

onMounted(async () => {
  
  if (selectedProject.value) {
    const imageUrl = await getImageForProject(selectedProject.value.title)
    projectImageUrl.value = imageUrl ? `${base_url}${imageUrl}` : ''
  }
})

// Add this after other refs
const { x: mouseX, y: mouseY } = useMouse()

// Add these reactive refs
const statsPosition = reactive({
  x: 0,
  y: 0,
  show: false
})

// Add this new ref to track the frozen position
const frozenStatsPosition = ref(null)

// Modify the handleTableMouseMove function
const handleTableMouseMove = useThrottleFn((event) => {
  if (!plantscheduleRef.value || !showColumnStats.value) return
  
  // If editing, use the frozen position
  if (isEditing.value && frozenStatsPosition.value) {
    statsPosition.x = frozenStatsPosition.value.x
    statsPosition.y = frozenStatsPosition.value.y
    return
  }
  
  const bounds = plantscheduleRef.value.getBoundingClientRect()
  const padding = 20 // Reduced from 40
  const statsWidth = 450
  const statsHeight = 400
  const flipThreshold = 50 // Reduced from 100
  
  // Calculate initial position
  let x = event.clientX - bounds.left + padding
  let y = event.clientY - bounds.top + padding
  
  // Check if we're approaching right edge
  if (x + statsWidth > bounds.width - flipThreshold) {
    x = event.clientX - bounds.left - statsWidth - padding
  }
  
  // Check if we're approaching bottom edge
  if (y + statsHeight > bounds.height - flipThreshold) {
    y = event.clientY - bounds.top - statsHeight - padding
  }
  
  // Ensure we don't go beyond left or top edge with minimal padding
  x = Math.max(padding, x)
  y = Math.max(padding, y)
  
  statsPosition.x = x
  statsPosition.y = y
}, 16)

// Add these refs for edit menu
const editMenuOptions = ref([])
const editingCell = ref(null)

// Modify cleanupEditingState to ensure all highlights are cleared
const cleanupEditingState = () => {
  console.log('applyTo: cleaning up editing state', {
    hasLastEditedCell: !!lastEditedCell.value,
    isEditing: isEditing.value,
    hasEditingCell: !!editingCell.value
  });
  
  try {
    // Clean up editing cell classes
    if (lastEditedCell.value?._cell?.table) {
      const table = lastEditedCell.value._cell.table;
      
      // Clean up all cells in the table
      table.getRows().forEach(row => {
        row.getCells().forEach(cell => {
          const cellElement = cell.getElement();
          if (cellElement) {
            cellElement.classList.remove('would-be-edited');
            cellElement.classList.remove('would-be-edited-species');
            cellElement.classList.remove('would-be-edited-group');
            cellElement.classList.remove('would-be-edited-matching');
            cellElement.classList.remove('hover');
            cellElement.classList.remove('cell-editing');
          }
        });
      });
      
      // Remove table editing class
      table.element.classList.remove('table-editing');
    }
  } catch (error) {
    console.warn('Error cleaning up cell classes:', error);
  }

  // Reset all state variables
  nextTick(() => {
    isEditing.value = false;
    editingCell.value = null;
    editMenuOptions.value = [];
    frozenStatsPosition.value = null;
    isEditingFalseOnMouseOut.value = false;
    lastEditedCell.value = null;
    selectedBulkEditOption.value = null;
    originalCellValue.value = '';
    showColumnStats.value = false;
    showRowStats.value = false;
    isInteractingWithStats.value = false;
    affectedCells.value = [];
    
    console.log('applyTo: cleanup complete', {
      isEditing: isEditing.value,
      hasEditingCell: !!editingCell.value,
      hasSelectedOption: !!selectedBulkEditOption.value
    });
  });
};



// Modify the bulk edit functions to use the correct Tabulator reference
const applyToAllSpecies = (cell) => {
  const speciesName = rowData.value[metadata.value?.columnToCanonical['botanicalname']]
  const newValue = editingValue.value
  const columnField = cell.column.field
  
  console.log('applyTo: starting species bulk edit')
  console.log('applyTo: params', {
    speciesName,
    newValue,
    columnField,
    originalValue: originalCellValue.value,
    botanicalNameKey: metadata.value?.columnToCanonical['botanicalname']
  })
  
  // Find all rows with matching species and update them
  const updatedRows = tables.value.rows.map(row => {
    const willUpdate = row[metadata.value?.columnToCanonical['botanicalname']] === speciesName && 
                      row[columnField] === originalCellValue.value
    
    console.log('applyTo: checking row', {
      rowBotanicalName: row[metadata.value?.columnToCanonical['botanicalname']],
      rowCurrentValue: row[columnField],
      willUpdate,
      row
    })
    
    if (willUpdate) {
      console.log('applyTo: updating row', { 
        from: row[columnField],
        to: newValue,
        rowId: row.id
      })
      return { ...row, [columnField]: newValue }
    }
    return row
  })
  
  console.log('applyTo: updating table data')
  tables.value.rows = updatedRows
  
  // Update the Tabulator instance
  if (cell._cell?.table) {
    console.log('applyTo: applying updates to tabulator')
    cell._cell.table.setData(updatedRows)
  } else {
    console.warn('applyTo: tabulator instance not found')
  }
  
  console.log('applyTo: species bulk edit complete')
  cleanupEditingState()
}

const applyToAllMatching = (cell) => {
  const columnField = cell.column.field
  const newValue = editingValue.value
  
  console.log('applyTo: starting matching bulk edit')
  console.log('applyTo: params', {
    columnField,
    newValue,
    originalValue: originalCellValue.value
  })
  
  // Find all rows with matching value in this column and update them
  const updatedRows = tables.value.rows.map(row => {
    const willUpdate = row[columnField] === originalCellValue.value
    
    console.log('applyTo: checking row', {
      rowCurrentValue: row[columnField],
      willUpdate,
      row
    })
    
    if (willUpdate) {
      console.log('applyTo: updating row', { 
        from: row[columnField],
        to: newValue,
        rowId: row.id
      })
      return { ...row, [columnField]: newValue }
    }
    return row
  })
  
  console.log('applyTo: updating table data')
  tables.value.rows = updatedRows
  
  if (cell._cell?.table) {
    console.log('applyTo: applying updates to tabulator')
    cell._cell.table.setData(updatedRows)
  } else {
    console.warn('applyTo: tabulator instance not found')
  }
  
  console.log('applyTo: matching bulk edit complete')
  cleanupEditingState()
}

const applyToAllInGroup = (cell) => {
  const columnField = cell.column.field
  const newValue = editingValue.value
  const currentGroup = cell._cell.row.getData()?._group || ''
  
  console.log('applyTo: starting group bulk edit')
  console.log('applyTo: params', {
    columnField,
    newValue,
    currentGroup,
    originalValue: originalCellValue.value
  })
  
  // Find all rows in the same group and update them
  const updatedRows = tables.value.rows.map(row => {
    const willUpdate = row._group === currentGroup && 
                      row[columnField] === originalCellValue.value
    
    console.log('applyTo: checking row', {
      rowGroup: row._group,
      rowCurrentValue: row[columnField],
      willUpdate,
      row
    })
    
    if (willUpdate) {
      console.log('applyTo: updating row', { 
        from: row[columnField],
        to: newValue,
        rowId: row.id
      })
      return { ...row, [columnField]: newValue }
    }
    return row
  })
  
  console.log('applyTo: updating table data')
  tables.value.rows = updatedRows
  
  if (cell._cell?.table) {
    console.log('applyTo: applying updates to tabulator')
    cell._cell.table.setData(updatedRows)
  } else {
    console.warn('applyTo: tabulator instance not found')
  }
  
  console.log('applyTo: group bulk edit complete')
  cleanupEditingState()
}

// Update escape key watcher to use the cleanup function
watch(escape, (pressed) => {
  if (pressed) {
    console.log('escape pressed')
    cleanupEditingState()
  }
})

// Add watcher for editingCell value changes
watch(() => editingCell.value?._cell?.value, (newValue) => {
  if (newValue !== undefined) {
    editingValue.value = newValue
    console.log('applyTo: cell value changed', { newValue, editingValue: editingValue.value })
  }
})

// Add this new ref to track if we're interacting with the stats panel
const isInteractingWithStats = ref(false)


// Replace the currentEditValue computed
const currentEditValue = computed(() => {
  if (!editingCell.value?._cell) return ''
  
  try {
    // First try to get the input element
    const element = editingCell.value._cell.getElement()
    if (!element) return editingCell.value._cell.value || ''
    
    const inputElement = element.querySelector('input')
    return inputElement ? inputElement.value : editingCell.value._cell.value || ''
  } catch (e) {
    // Fallback to the cell value if anything fails
    return editingCell.value._cell.value || ''
  }
})

// Add this new ref to track the last edited cell
const lastEditedCell = ref(null)

// Add watcher for selectedBulkEditOption changes
watch(selectedBulkEditOption, (newVal, oldVal) => {
  console.log('applyTo: selectedOption changed', {
    from: oldVal?.type,
    to: newVal?.type,
    params: newVal?.params,
    editingValue: editingValue.value,
    originalValue: originalCellValue.value
  })
})

// Modify handleBulkEditClick to ensure bulk edit completes before cleanup
const handleBulkEditClick = async (option) => {
  console.log('applyTo: option clicked', {
    type: option.type,
    params: option.params,
    editingValue: editingValue.value
  });
  
  selectedBulkEditOption.value = option;
  console.log('applyTo: selectedOption set', selectedBulkEditOption.value);
  
  if (!editingCell.value?._cell) {
    console.warn('applyTo: no editing cell found');
    cleanupEditingState();
    return;
  }

  const finalValue = editingValue.value;
  const columnField = editingCell.value._cell.column.field;
  const table = editingCell.value._cell.table;
  
  console.log('applyTo: starting bulk edit', {
    type: option.type,
    columnField,
    finalValue,
    originalValue: originalCellValue.value,
    currentRows: tables.value.rows.length
  });
  
  try {
    // Get current data from Tabulator to ensure we're working with the latest state
    const currentData = table.getData();
    let updatedRows = [...currentData];
    
    switch (option.type) {
      case 'species':
        // ... species case code ...
        break;
      case 'group':
        // ... group case code ...
        break;
      case 'column-matching':
        // ... column-matching case code ...
        break;
      case 'matching':
        // ... matching case code ...
        break;
    }
    
    // Count how many rows were updated
    const updatedCount = updatedRows.filter((row, index) => 
      row[columnField] !== currentData[index][columnField]
    ).length;
    
    // Update both the local data and the Tabulator instance
    tables.value.rows = updatedRows;
    
    // Update the panel state
    if (panelState.value[props.panel_id]) {
      panelState.value[props.panel_id].output = {
        ...panelState.value[props.panel_id].output,
        tables: {
          ...panelState.value[props.panel_id].output.tables,
          rows: updatedRows
        }
      };
    }
    
    // Update the Tabulator instance and ensure changes are visible
    if (table) {
      // First update the data
      await table.setData(updatedRows);
      
      // Wait for the next tick before redrawing
      await nextTick();
      table.redraw(true);
      
      // Trigger refresh of diversity status
      dockStore.refreshPanel('DiversityStatus');
      
      // Emit update event after successful update
      emit('update:shared-data', {
        botanicalNames: updatedRows.map(row => row[option.params.botanicalNameKey]),
        metadata: metadata.value,
        tool_uid: tool_id.value
      });
      
      console.log('applyTo: table updated successfully');
    }
    
    // Only cleanup after everything is complete
    await nextTick();
    cleanupEditingState();
    
  } catch (error) {
    console.error('applyTo: error during bulk edit', error);
    cleanupEditingState();
  }
};

// Modify createEditMenuOptions to conditionally show species option
const createEditMenuOptions = (botanicalName, matchingBotanicalRows, currentGroup, groupMatchCount) => {
  // Helper function to create the base template
  const createOptionTemplate = ({ label, value, count, bgColor, darkBgColor, countBgColor, labelText }) => {
    const arrow = '<span class="mx-2 flex-shrink-0 text-xs text-muted-foreground">→</span>';
    return `
      <div class="flex items-start min-w-0 w-full text-xs py-2">
        <span class="p-0.5 px-2 ${bgColor} ${darkBgColor} rounded font-normal flex gap-2 flex-shrink-0">
          <div class="relative -top-4 -left-2 text-[9px] opacity-70 z-20 text-primary">${labelText}</div>
          <span class="truncate text-ellipsis overflow-hidden relative max-w-[150px] whitespace-nowrap text-background relative -top-[0.5px]">${value}</span>
          <div class="flex flex-col items-start">
            <div class="inline-flex w-3.5 h-3.5 items-start justify-center text-[10px] rounded-full font-light ${countBgColor} text-background aspect-square flex-shrink-0">
              ${count}
            </div>
          </div>
        </span>
        ${arrow}
        <p class="truncate text-ellipsis overflow-hidden relative w-[100px] text-muted-foreground whitespace-nowrap">"${editingValue.value}"</p>
      </div>
    `;
  };

  const getLabel = (type) => {
    switch (type) {
      case 'species':
        return createOptionTemplate({
          labelText: 'Botanical name',
          value: botanicalName || 'unknown',
          count: matchingBotanicalRows,
          bgColor: 'bg-slate-400',
          darkBgColor: 'dark:bg-yellow-900/30',
          countBgColor: 'bg-slate-500'
        });

      case 'group':
        return createOptionTemplate({
          labelText: 'Group',
          value: currentGroup,
          count: groupMatchCount,
          bgColor: 'bg-blue-400',
          darkBgColor: 'dark:bg-yellow-900/30',
          countBgColor: 'bg-blue-500'
        });

      case 'column-matching':
        const columnMatchCount = tables.value.rows.filter(row => 
          row[editingCell.value._cell.column.field] === originalCellValue.value
        ).length;
        
        return createOptionTemplate({
          labelText: 'Column',
          value: `"${editingCell.value._cell.column.field}"`,
          count: columnMatchCount,
          bgColor: 'bg-emerald-500',
          darkBgColor: 'dark:bg-emerald-900/30',
          countBgColor: 'bg-emerald-600'
        });

      case 'matching':
        const totalMatchCount = tables.value.rows.reduce((count, row) => {
          return count + Object.values(row).filter(value => value === originalCellValue.value).length;
        }, 0);
        
        return createOptionTemplate({
          labelText: 'Value',
          value: 'All occurrences in table',
          count: totalMatchCount,
          bgColor: 'bg-violet-400',
          darkBgColor: 'dark:bg-violet-900/30',
          countBgColor: 'bg-violet-500'
        });

      default:
        return '';
    }
  };

  // Calculate hasMultipleGroups inside the function
  const uniqueGroups = new Set(tables.value.rows.map(row => row._group));
  const hasMultipleGroups = uniqueGroups.size > 1;

  // Create options array
  const options = [];

  // Only add species option if we're NOT editing the botanical name column
  const currentColumnField = editingCell.value._cell.column.field;
  const botanicalNameKey = metadata.value?.columnToCanonical['botanicalname'];
  const isEditingBotanicalName = currentColumnField === botanicalNameKey;

  if (!isEditingBotanicalName) {
    options.push({
      get label() { return getLabel('species'); },
      type: 'species',
      params: { botanicalName, botanicalNameKey }
    });
  }

  if (hasMultipleGroups) {
    options.push({
      get label() { return getLabel('group'); },
      type: 'group',
      params: { currentGroup }
    });
  }

  options.push({
    get label() { return getLabel('column-matching'); },
    type: 'column-matching',
    params: { }
  });

  options.push({
    get label() { return getLabel('matching'); },
    type: 'matching',
    params: { }
  });

  return options;
};

// Add these new refs after other refs
const hoveredEditOption = ref(null)
const affectedCells = ref([])

// Add this new method after other methods
const highlightAffectedCells = (option) => {
  if (!editingCell.value?._cell?.table) return
  
  const table = editingCell.value._cell.table
  const columnField = editingCell.value._cell.column.field
  const originalValue = originalCellValue.value
  
  // Clear previous highlights first
  clearCellHighlights()
  
  // Add table-level class to prevent hover effects
  table.element.classList.add('bulk-edit-preview')
  
  // Find cells that would be affected
  let cells = []
  
  switch (option.type) {
    case 'species':
      const botanicalNameKey = option.params.botanicalNameKey
      const botanicalName = option.params.botanicalName
      
      table.getRows().forEach(row => {
        const cell = row.getCell(columnField)
        if (
          row.getData()[botanicalNameKey] === botanicalName && 
          cell.getValue() === originalValue
        ) {
          cells.push(cell)
          const element = cell.getElement()
          element.classList.add('would-be-edited-species')
          element.classList.add('bulk-edit-highlight')
        }
      })
      break
      
    case 'group':
      const currentGroup = editingCell.value._cell.row.getData()._group
      table.getRows().forEach(row => {
        const cell = row.getCell(columnField)
        if (
          row.getData()._group === currentGroup && 
          cell.getValue() === originalValue
        ) {
          cells.push(cell)
          const element = cell.getElement()
          element.classList.add('would-be-edited-group')
          element.classList.add('bulk-edit-highlight')
        }
      })
      break
      
    case 'column-matching':
      table.getRows().forEach(row => {
        const cell = row.getCell(columnField)
        if (cell.getValue() === originalValue) {
          cells.push(cell)
          const element = cell.getElement()
          element.classList.add('would-be-edited-column')
          element.classList.add('bulk-edit-highlight')
        }
      })
      break
      
    case 'matching':
      table.getRows().forEach(row => {
        row.getCells().forEach(cell => {
          if (cell.getValue() === originalValue) {
            cells.push(cell)
            const element = cell.getElement()
            element.classList.add('would-be-edited-matching')
            element.classList.add('bulk-edit-highlight')
          }
        })
      })
      break
  }
  
  affectedCells.value = cells
}

// Update the clearCellHighlights function
const clearCellHighlights = () => {
  if (!editingCell.value?._cell?.table) return
  
  const table = editingCell.value._cell.table
  
  // Remove table-level class
  table.element.classList.remove('bulk-edit-preview')
  
  table.getRows().forEach(row => {
    row.getCells().forEach(cell => {
      const element = cell.getElement()
      element.classList.remove('would-be-edited')
      element.classList.remove('would-be-edited-species')
      element.classList.remove('would-be-edited-group')
      element.classList.remove('would-be-edited-matching')
      element.classList.remove('would-be-edited-column')
      element.classList.remove('bulk-edit-highlight')
    })
  })
  affectedCells.value = []
}

// Modify the template where the bulk edit buttons are rendered

// Add this method in the script section before the template
const cleanEmptyColumns = () => {
  if (tabulator.value) {
    console.log('Checking for empty columns...');
    const allColumns = tables.value.columns;
    const emptyColumns = allColumns.filter(column => {
      const field = column.field;
      return tables.value.rows.every(row => {
        const value = row[field];
        return value === null || value === undefined || value === "";
      });
    });
    
    console.log('Found empty columns:', emptyColumns.map(col => col.title));
    tabulator.value.hideEmptyColumns();
  }
};

const handleColumnUpdate = ({ column, newDefinition }) => {
  // Update the panel state
  if (panelState.value[props.panel_id]) {
    const currentState = panelState.value[props.panel_id];
    
    // Get the current columns from the correct path
    let currentColumns = currentState.output?.tables?.columns || 
                        currentState.data?.output?.tables?.columns;
    
    if (currentColumns) {
      // Create a new array with the updated column
      const updatedColumns = currentColumns.map(col => {
        if (col.field === column) {
          return { ...col, ...newDefinition };
        }
        return col;
      });

      // Update both possible paths where columns might be stored
      if (currentState.output?.tables) {
        currentState.output.tables = {
          ...currentState.output.tables,
          columns: updatedColumns
        };
      }
      
      if (currentState.data?.output?.tables) {
        currentState.data.output.tables = {
          ...currentState.data.output.tables,
          columns: updatedColumns
        };
      }

      // Force reactivity update
      panelState.value = { ...panelState.value };
      
      // Also update the local tables ref to ensure UI consistency
      if (tables.value) {
        tables.value = {
          ...tables.value,
          columns: updatedColumns
        };
      }
    }
  }
};

const handleColumnsReset = (newColumns) => {
  dockStore.updatePanelColumns(props.panel_id, newColumns);
};

// Instead, create a method to set up the event listeners
const setupTabulatorEvents = () => {
  if (tabulator.value?.table) {
    const tabulatorInstance = tabulator.value.table;
    
    if (typeof tabulatorInstance.on === 'function') {
      tabulatorInstance.on("columnTitleChanged", async function(column) {
        try {
          const field = column.getField();
          const oldTitle = column.getDefinition().title;
          const newTitle = column.getDefinition().title;

          // Emit the title change event
          emit('column-title-changed', { field, oldTitle, newTitle });
          
          // Perform inference on all column titles
          const { inferredColumnTitles = {}, columnToCanonical = {} } = await inferColumnTypes() || {};
          
          // Update metadata with new inferred titles
          metadata.value = {
            ...metadata.value,
            inferredColumnTitles,
            columnToCanonical
          };

          // Update panel state
          if (panelState.value[props.panel_id]) {
            panelState.value[props.panel_id].output = {
              ...panelState.value[props.panel_id].output,
              metadata: metadata.value
            };
          }
          
          // Emit column update
          emit('column-updated', {
            column: field,
            newDefinition: { 
              title: newTitle,
              field: field,
              headerWordWrap: true,
              headerSort: true,
              editor: "input",
              resizable: "header",
              headerVertical: false
            }
          });
          
          emitColumnUpdate();
          dockStore.refreshPanel('DiversityStatus');
        } catch (error) {
          console.error('Error updating column title:', error);
        }
      });
    } else {
      console.warn('Tabulator instance does not have an "on" method');
    }
  } else {
    console.warn('Tabulator instance not fully initialized');
  }
};

// Watch for when the tabulator instance is ready
watch(() => tabulator.value?.table, (newTabulator) => {
  if (newTabulator) {
    setupTabulatorEvents();
  }
}, { immediate: true });

// Also call it during initial setup if needed
onMounted(async () => {
  await updateSharedData();
  await refreshPanel();
  if (tabulator.value?.table) {
    setupTabulatorEvents();
  }
});

// Update emitColumnUpdate to include all column properties
const emitColumnUpdate = () => {
  if (tabulator.value) {
    const updatedColumns = tabulator.value.getColumns().map(col => {
      const def = col.getDefinition();
      return {
        title: def.title,
        field: def.field,
        headerWordWrap: def.headerWordWrap ?? true,
        headerSort: def.headerSort ?? true,
        editor: def.editor ?? "input",
        resizable: def.resizable ?? "header",
        headerVertical: def.headerVertical ?? false,
        visible: col.isVisible()
      };
    });
    emit('update:columns', updatedColumns);
  }
};

const botanicalNames = computed(() => {
  if (!tables.value?.rows || !metadata.value?.inferredColumnTitles) {
    return [];
  }

  return tables.value.rows.map(row => {
    // Try to find the botanical name using the inferred column titles
    const botanicalNameKey = Object.keys(metadata.value.inferredColumnTitles || {})
      .find(key => metadata.value.inferredColumnTitles[key] === 'botanicalname');
    
    // Return the first non-empty value from our fallback chain
    return row[botanicalNameKey] || 
           row['Botanical Name'] || 
           row['Botanical Names'] || 
           row['col1'] || 
           '';
  });
});

const handleRecalculateColumnTitles = async () => {
  if (!tables.value || !tables.value.columns) {
    console.warn('Tables or columns are not available yet');
    return;
  }

  // Get new inferred titles
  const { inferredColumnTitles = {}, columnToCanonical = {} } = await inferColumnTypes() || {};
  
  // Update metadata
  metadata.value = {
    ...metadata.value,
    inferredColumnTitles,
    columnToCanonical
  };

  // Update panel state
  if (panelState.value[props.panel_id]) {
    panelState.value[props.panel_id].output = {
      ...panelState.value[props.panel_id].output,
      metadata: metadata.value
    };
  }

  // Trigger refresh of diversity status
  dockStore.refreshPanel('DiversityStatus');
};

const handleRefreshAll = async () => {
  loading.value = true;
  try {
    // Refresh table data
    tables.value = await getTables();
    
    // Recalculate column titles
    const { inferredColumnTitles = {}, columnToCanonical = {} } = await inferColumnTypes() || {};
    
    // Update metadata
    metadata.value = {
      ...metadata.value,
      inferredColumnTitles,
      columnToCanonical
    };

    // Update panel state
    if (panelState.value[props.panel_id]) {
      panelState.value[props.panel_id].output = {
        ...panelState.value[props.panel_id].output,
        metadata: metadata.value,
        tables: tables.value
      };
    }

    // Get fresh metadata
    metadata.value = await getMetadata();
    
    // Update shared data
    await updateSharedData();
    
    // Refresh diversity status
    dockStore.refreshPanel('DiversityStatus');
    
    // Reset any UI states
    showColumnStats.value = false;
    showRowStats.value = false;
    isEditing.value = false;
  } catch (error) {
    console.error('Error refreshing data:', error);
  } finally {
    loading.value = false;
  }
};

</script>

<template>
  <!-- Update the SVG filter -->
  <svg width="0" height="0" class="absolute">
    <filter id='noise'>
      <feTurbulence 
        type='fractalNoise'
        baseFrequency='2.5'
        numOctaves='4'
        stitchTiles='stitch'/>
      <feColorMatrix type="saturate" values="0"/>
      <feBlend in="SourceGraphic" mode="screen" opacity="0.05"/>
    </filter>
  </svg>

  <div ref="plantscheduleRef"
    class="w-full h-[95%] flex flex-col justify-start items-start overflow-hidden pr-1 sm:pr-4 pt-0 mt-0 align-start mr-[-5px] sm:pl-2 mt-2 bg-repeat-y relative"
  >
    <!-- Background div with noise -->
    <div 
      class="absolute inset-0 w-full h-full pointer-events-none overflow-hidden"
      :style="{ 
        backgroundImage: projectImageUrl ? `
          url(${projectImageUrl}),
          linear-gradient(to bottom,
            transparent 49.9%,
            rgba(0,0,0,0.1) 49.9%,
            rgba(0,0,0,0.1) 50.1%,
            transparent 50.1%
          ),
          url(${projectImageUrl})
        ` : 'none',
        backgroundSize: '100% auto, 100% 2px, 100% auto',
        backgroundPosition: 'top center, center, bottom center',
        backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
        transform: 'none',
        '--mirror-image': `url(${projectImageUrl})`,
        '--mirror-height': '50%',
        '--mirror-position': 'bottom center',
        '--mirror-size': '100% auto',
        '--mirror-transform': 'scaleY(-1)',
        zIndex: '0',
        filter: 'url(#noise)'
      }"
    ></div>

    <!-- Rest of your content with a higher z-index -->
    <div class="relative w-full h-full z-1">
      <div v-if="loading" class="absolute z-10 w-full h-full flex pointer-events-none justify-center">
        <Loader2 class="w-8 h-8 mr-2 animate-spin self-center" />
      </div>

      <div ref="plantscheduleHeader" class="w-full flex flex-col justify-start items-start relative z-10">
        <div class="flex justify-between items-start w-full">
          <div class="flex flex-col items-start">
            <h3 v-if="metadata" class="text-2xl font-bold sm:p-3 w-full bg-background top-[10vh] relative rounded-t-lg w-full ">
              <InlineEdit 
              
                :modelValue="metadata?.title" 
                @update:modelValue="handleUpdateTitle" 
                @update:edit="editing = $event" 
                preview-class="text-muted-foreground text-2xl" 
                input-class="pointer-events-auto text-2xl bg-background" 
                :buttons-width="90" 
                save-icon="lucide:check" 
                cancel-icon="lucide:x" 
                :maxInputWidth="300" 
              />
              
            </h3>
            <!-- <p v-if="metadata?.fileMetadata?.filename"
              class="text-xs font-semibold text-muted-foreground space-x-2 py-1 ml-3">
              <Icon :name="contentTypeToExtension[metadata?.fileMetadata?.content_type]?.iconSmall" class="w-4 h-4 inline-block" />
              <span class="bg-muted p-1 rounded-md">{{ metadata?.fileMetadata?.filename }}</span>
            </p> -->
          </div>
          <div class="flex items-center justify-end gap-2 opacity-75 scale-75 origin-top-right">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" class="p-2 aspect-square w-12 h-12 rounded-full mt-2">
                  <Icon name="lucide:more-vertical" class="w-10 h-10 aspect-square" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent 
                class="rounded-xl ring ring-1 ring-muted-foreground/10 shadow-none pr-3" 
                align="end"
              >
                <DropdownMenuItem 
                  class="m-1 flex items-center w-full px-3 py-2 hover:bg-muted/50 rounded-md"
                  @click="cleanEmptyColumns"
                >
                  <Icon 
                    name="lucide:columns" 
                    class="w-4 h-4 mr-2"
                  />
                  Hide empty columns
                </DropdownMenuItem>
                <!-- <DropdownMenuItem 
                  class="m-1 flex items-center w-full px-3 py-2 hover:bg-muted/50 rounded-md"
                  @click="handleRecalculateColumnTitles"
                >
                  <Icon 
                    name="lucide:refresh-cw" 
                    class="w-4 h-4 mr-2"
                  />
                  Recalculate column titles
                </DropdownMenuItem> -->
                <DropdownMenuItem 
                  class="m-1 flex items-center w-full px-3 py-2 hover:bg-muted/50 rounded-md"
                  @click="handleRefreshAll"
                >
                  <Icon 
                    name="lucide:refresh-cw" 
                    class="w-4 h-4 mr-2"
                  />
                  Sync
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <TooltipProvider :delayDuration="200" :skipDelayDuration="700">
              <Tooltip>
                <TooltipTrigger>
                  <!-- <Button v-if="tables" class="p-2 aspect-square" variant="outline" @click="downloadAs('xlsx')">
                    <Icon name="mdi:google-spreadsheet" class="w-6 h-6 p-0" />
                  </Button> -->
                </TooltipTrigger>
                <TooltipContent
                  class="pointer-events-all bg-primary text-background shadow-xxl !animate-none select-none border-none">
                  <p>Download as XLSX</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </div>

      <ClientOnly>

        
        <div ref="plantscheduleRef" v-if="tables" class="w-full h-full align-start items-start z-10 mt-[10vh]">

          <TabulatorTable v-if="tables.rows" ref="tabulator" @click="handleClick" :rows="tables.rows"
            class="is-bordered is-narrow overflow-hidden" :movableColumns="true" :columns="tables.columns" :options="{
              groupBy: '_group',
              height:'100%',
              // layout: 'fitDataFill',
              selectableRows: true,
              selectableRowsRangeMode: 'click',
              history: true,
              movableColumns: true,
              responsiveLayout: 'collapse'
            }" :metadata="metadata" @mousemove="handleTableMouseMove" @row-mouseover="handleRowMouseOver" @headerMouseOver="handleHeaderMouseOver"
            @cellMouseOver="handleCellMouseOver" @table-height="tableHeight=$event" @table-rect="tableRect=$event"
            @groupMouseMove="handleGroupMouseMove" @cellMouseLeave="handleCellMouseLeave" @cellEditing="handleCellEditing"
            @cellEditCancelled="handleCellEditCancelled" @cellEdited="handleCellEdited" @row-added="handleRowAdded"
            @update:columns="handleColumnsUpdate" @update:rows="handleRowsUpdate" @column-updated="handleColumnUpdate" @columns-reset="handleColumnsReset" />
          <!-- <Button @click="addRow">Add Row</Button> -->









        </div>
        <div v-if="!tables && !loading"
          class="flex flex-col justify-center items-center space-y-2 w-full h-full align-center text-center">
          <Icon name="solar:sad-square-outline" class="w-10 h-10" />
          <div class="text-sm w-[600px]">No data available.<br />Please check your data source.</div>
          <Button variant="outline" @click="">Reload</Button>
        </div>


        <!-- <BorderBeam style="pointer-events: none !important" v-if="includeDataInChat && activePanel === panel_id" borderRadius="10px" :anchor="90" :size="beamSize" /> -->
        <!-- <GodRays class="-mt-2 w-[calc(100%-9px)] h-[calc(100%-12px)] rounded-b-lg pointer-events-none mix-blend-multiply" :colors="['#f97316', '#fd7e14', '#e44c65', '#c026d3', '#a855f7']" v-if="includeDataInChat && activePanel === panel_id && chatDialogOpen"   /> -->
        <!-- <div v-if="showColumnStats" v-motion-fade
          class="absolute column-stats z-[1000] flex flex-row bg-accent mix-blend-multiply rounded-xl pointer-events-none constrainHeightToPopover"
          :style="{
          top: `${colDiv.top}px`,
          left: `${colDiv.left}px`,
          width: `${colDiv.width}px`,
          height: `${colDiv.height}px`
        }"></div> -->
      </ClientOnly>
    </div>
  </div>

  <!-- <div :class="`absolute -bottom-4 left-0 bg-transparent w-full h-[${plantscheduleHeight * .1}px]`">
    <Accordion type="single" collapsible class="no-chevron">
      <AccordionItem value="debug">
        <AccordionTrigger class="max-w-fit hover:no-underline">
          <Icon name="solar:code-bold-duotone" class="w-4 h-4 mx-2 mb-2 bg-background rounded-full" />
          <span class="text-xs mb-1 text-primary/20">{{ panel_id }}</span>
        </AccordionTrigger>
        <AccordionContent>
          <ScrollArea :class="`w-full bg-muted p-10 h-[80vh] overflow-hidden`">
            <pre class="text-xs text-wrap">{{ panel?.data?.output }}</pre>
            <pre class="text-xs text-wrap">{{ tables }}</pre>
          </ScrollArea>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  </div> -->

  <!-- Add this after plantscheduleRef div -->
  <div v-auto-animate="{ duration: 80 }" v-if="((showColumnStats && !isEditing) || (isEditing && editMenuOptions.length)) && !isEditingFalseOnMouseOut" 
       class="absolute z-50 bg-muted/80 stats-panel backdrop-blur-sm rounded-2xl shadow-xxl pointer-events-auto w-[20vw] min-w-[400px]"
       :style="{
         left: `${statsPosition.x}px`,
         top: `${statsPosition.y}px`,
         transition: 'all 0.05s cubic-bezier(0.4, 0, 0.2, 1)'
       }"
       @mouseenter="isInteractingWithStats = true"
       @mouseleave="isInteractingWithStats = false"
       @click.stop>

    <!-- Edit Menu -->
    <div v-if="isEditing && editMenuOptions.length" class="p-4 space-y-3 bulk-edit-options rounded-2xl bg-background shadow-xxl" @click.stop>
      <div class="space-y-2">
        <h3 class="text-sm pl-2 "><Icon name="ic:round-edit-note" class="w-6 h-6 mr-2 relative top-[-1px]" />Bulk edit shortcuts</h3>
        <div class="space-y-2 p-5 rounded-sm">
        <Button 
          v-for="option in editMenuOptions" 
          :key="option.type"
          variant="text"
          class="w-full pl-2 ml-0 py-2 justify-start text-left hover:bg-muted-foreground/10"
          @click.stop="handleBulkEditClick(option)"
          @mouseenter="highlightAffectedCells(option)"
          @mouseleave="clearCellHighlights"
          v-html="option.label">
        </Button>
        </div>
      </div>
    </div>
    
    <!-- Column Stats -->
    <div v-else-if="showColumnStats && columnData" 
         @mouseleave="handleMouseLeaveColumnStats">
      <ColumnStatsPanel
        :botanicalnames="botanicalNames"
        :heightValues="heightValues"
        :widthValues="widthValues"
        :metadata="{...metadata, panel_id}"
        :title="columnTitle"
        :col="columnData"
        :row="rowData"
        :cell="cellData || ''"
      />
    </div>
  </div>

</template>

<style lang="scss">
.tabulator.is-bordered {
  border: none !important;
}



.tabulator-editing .tabulator-cell:not(.cell-editing):not(.would-be-edited) {
  opacity: 0.6 !important;
  pointer-events: none;
}

.would-be-edited {
  opacity: 1 !important;
  background-color: rgba(0, 84, 241, 1) !important; // Tailwind yellow-200 with opacity
  transition: all 0.15s ease-in-out;
  outline: 1px solid rgb(234 179 8) !important; // Subtle yellow border
}

.dark {
  .would-be-edited {
    background-color: rgb(234 179 8 / 0.2) !important; // Darker yellow for dark mode
    outline: 1px solid rgb(234 179 8 / 0.2) !important;
    
    &:hover {
      background-color: rgb(234 179 8 / 0.3) !important;
    }
  }
}

@import '~/assets/css/tabulator_style.css';

.no-chevron svg {
  display: none;
}

.constrainHeightToPopover{
  max-height: var(--radix-hover-card-content-available-height) !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(1) !important;
  }
  to {
    opacity: 1;
    transform: scale(1) !important;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.HoverCardContent {
  animation: fadeIn 80ms ease-out !important;
  border: none !important;
  padding: 0 !important;
}

.HoverCardContent[data-state="closed"] {
  animation: fadeOut 180ms ease-out !important;
}

.cell-editing{
  opacity: 1 !important;
  width: 130px !important;
  box-shadow: 0px 5.8px 1.4px -13px rgba(0, 0, 0, 0.058),
              0px 10.2px 3.3px -13px rgba(0, 0, 0, 0.078),
              0px 13.7px 6.3px -13px rgba(0, 0, 0, 0.09),
              0px 17.1px 11.2px -13px rgba(0, 0, 0, 0.1),
              0px 22.2px 20.9px -13px rgba(0, 0, 0, 0.113),
              0px 39px 50px -13px rgba(0, 0, 0, 0.15) !important;
  box-sizing: content-box !important;
  border-radius: 5px;
  background-color: hsl(var(--background));
  overflow: visible !important;
  pointer-events: all !important;
  transform: scale(1.25);
  transform-origin: center left;
  margin: 0 10px !important;
  z-index: 1001 !important;

  &::after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border: 1px solid hsl(var(--primary));
      border-radius: 5px;
    }
}

.column-stats, .row-stats{
  animation: fadeIn 0.5s ease-out !important;
    // box-shadow: 7.4px 0px 3.2px -8px rgba(0, 0, 0, 0.036),
    //   -7.4px 0px 3.2px -8px rgba(0, 0, 0, 0.036),
    //   17.2px 0px 7.5px -8px rgba(0, 0, 0, 0.052),
    //   -17.2px 0px 7.5px -8px rgba(0, 0, 0, 0.052),
    //   30.9px 0px 13.4px -8px rgba(0, 0, 0, 0.064),
    //   -30.9px 0px 13.4px -8px rgba(0, 0, 0, 0.064),
    //   51.2px 0px 22.3px -8px rgba(0, 0, 0, 0.076),
    //   -51.2px 0px 22.3px -8px rgba(0, 0, 0, 0.076),
    //   84.4px 0px 36.8px -8px rgba(0, 0, 0, 0.088),
    //   -84.4px 0px 36.8px -8px rgba(0, 0, 0, 0.088),
    //   147.5px 0px 64.3px -8px rgba(0, 0, 0, 0.104),
    //   -147.5px 0px 64.3px -8px rgba(0, 0, 0, 0.104),
    //   319px 0px 139px -8px rgba(0, 0, 0, 0.14),
    //   -319px 0px 139px -8px rgba(0, 0, 0, 0.14);
}


div:has(div.HoverCardContent:not(.tabulator-editing)){
  pointer-events: none !important;
}
div:has(div.HoverCardContent.tabulator-editing){
  pointer-events: all !important;
}



.tabulator-cell.hover:not(.cell-editing):not(.would-be-edited) {
  background-color: transparent !important;
  border: none !important;
}

.tabulator-cell.hover:not(.cell-editing):not(.would-be-edited):not(:empty):hover {
  background-color: transparent !important;
  // backdrop-filter: blur(6px);
  // -webkit-backdrop-filter: blur(6px);
}

.tabulator-cell.disable-hover{
  background-color:  unset !important;
  color: unset !important;
  border: none !important;
}

.truncate {
  white-space: normal;
  overflow: visible;
}

.stats-panel {
  animation: fadeIn 80ms ease-out;
  will-change: transform;
  transform: translateZ(0);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.stats-panel {
  &.editing {
    background: hsl(var(--background));
    border: 1px solid hsl(var(--border));
    pointer-events: auto !important;
  }
  
  // Add these new styles
  button, input {
    pointer-events: auto !important;
  }
}

// Ensure the edit menu is always clickable
.stats-panel:has(.edit-menu) {
  pointer-events: auto !important;
}

// Add these styles for the edit menu
.stats-panel {
  button {
    &:hover {
      .bg-yellow-100 {
        @apply bg-yellow-200;
      }
    }
  }
}

.would-be-edited {
  background-color: hsl(48 96% 89% / 0.5) !important; // Light yellow with opacity
  transition: background-color 0.15s ease-in-out;
  
  &:hover {
    background-color: hsl(48 96% 89% / 0.7) !important; // Slightly more opaque on hover
  }
}

.dark {
  .would-be-edited {
    background-color: hsl(48 96% 89% / 0.2) !important; // Darker theme version
    
    &:hover {
      background-color: hsl(48 96% 89% / 0.3) !important;
    }
  }
}

// Update the cell-editing style to ensure it stays on top
.cell-editing {
  opacity: 1 !important;
  width: 130px !important;
  box-shadow: 0px 5.8px 1.4px -13px rgba(0, 0, 0, 0.058),
              0px 10.2px 3.3px -13px rgba(0, 0, 0, 0.078),
              0px 13.7px 6.3px -13px rgba(0, 0, 0, 0.09),
              0px 17.1px 11.2px -13px rgba(0, 0, 0, 0.1),
              0px 22.2px 20.9px -13px rgba(0, 0, 0, 0.113),
              0px 39px 50px -13px rgba(0, 0, 0, 0.15) !important;
  box-sizing: content-box !important;
  border-radius: 5px;
  background-color: hsl(var(--background));
  overflow: visible !important;
  pointer-events: all !important;
  transform: scale(1.25);
  transform-origin: center center;
  // margin: 0 10px !important;
  z-index: 1001 !important;

  &::after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border: 1px solid hsl(var(--primary));
      border-radius: 5px;
    }
}

.would-be-edited-species {
  opacity: 1 !important;
  background-color: rgb(148 163 184 / 1) !important; // slate-400 with opacity
  transition: all 0.15s ease-in-out;
  outline: 1px solid rgb(148 163 184 / 0.3) !important;
  
  &:hover {
    background-color: rgb(148 163 184 / 0.3) !important;
  }
}

.would-be-edited-group {
  opacity: 1 !important;
  background-color: rgb(96 165 250 / 1) !important; // blue-400 with opacity
  transition: all 0.15s ease-in-out;
  outline: 1px solid rgb(96 165 250 / 0.3) !important;
  
  &:hover {
    background-color: rgb(96 165 250 / 0.3) !important;
  }
}

.would-be-edited-matching {
  opacity: 1 !important;
  background-color: rgb(167 139 250 / 1) !important; // violet-400
  transition: all 0.15s ease-in-out;
  outline: 1px solid rgb(167 139 250 / 0.3) !important;
  
  &:hover {
    background-color: rgb(167 139 250 / 0.3) !important;
  }
}

.dark {
  .would-be-edited-species,
  .would-be-edited-group,
  .would-be-edited-matching {
    background-color: rgb(234 179 8 / 0.2) !important; // yellow-900/30
    outline: 1px solid rgb(234 179 8 / 0.2) !important;
    
    &:hover {
      background-color: rgb(234 179 8 / 0.3) !important;
    }
  }
}

// Update the styles section

// Add styles for the edit menu buttons
.stats-panel button {
  width: 100%;
  text-align: left;
  padding: 0.25rem 0.5rem; // Even smaller padding
  font-size: 0.75rem; // text-xs equivalent
  
  // Ensure the button content doesn't wrap
  > div {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 0;
  }
}

.would-be-edited-matching {
  opacity: 1 !important;
  background-color: rgb(167 139 250 / 1) !important; // violet-400
  transition: all 0.15s ease-in-out;
  outline: 1px solid rgb(167 139 250 / 0.3) !important;
  
  &:hover {
    background-color: rgb(167 139 250 / 0.3) !important;
  }
}

.dark {
  .would-be-edited-matching {
    background-color: rgb(167 139 250 / 0.2) !important;
    outline: 1px solid rgb(167 139 250 / 0.2) !important;
    
    &:hover {
      background-color: rgb(167 139 250 / 0.3) !important;
    }
  }
}

// Add new style for column-specific matches
.would-be-edited-column {
  opacity: 1 !important;
  background-color: rgb(52 211 153 / 1) !important; // emerald-400
  transition: all 0.15s ease-in-out;
  outline: 1px solid rgb(52 211 153 / 0.3) !important;
  
  &:hover {
    background-color: rgb(52 211 153 / 0.3) !important;
  }
}

// Keep the violet style for table-wide matches
.would-be-edited-matching {
  opacity: 1 !important;
  background-color: rgb(167 139 250 / 1) !important; // violet-400
  transition: all 0.15s ease-in-out;
  outline: 1px solid rgb(167 139 250 / 0.3) !important;
  
  &:hover {
    background-color: rgb(167 139 250 / 0.3) !important;
  }
}

.dark {
  .would-be-edited-column {
    background-color: rgb(52 211 153 / 0.2) !important;
    outline: 1px solid rgb(52 211 153 / 0.2) !important;
    
    &:hover {
      background-color: rgb(52 211 153 / 0.3) !important;
    }
  }
  
  .would-be-edited-matching {
    background-color: rgb(167 139 250 / 0.2) !important;
    outline: 1px solid rgb(167 139 250 / 0.2) !important;
    
    &:hover {
      background-color: rgb(167 139 250 / 0.3) !important;
    }
  }
}

// Ensure editing states take precedence
.cell-editing,
.would-be-edited,
.would-be-edited-species,
.would-be-edited-group,
.would-be-edited-matching,
.would-be-edited-column {
  &.hover {
    background-color: inherit !important;
    color: inherit !important;
  }
}

// Add these new styles with higher specificity
.tabulator {
  &.bulk-edit-preview {
    .tabulator-cell:not(.bulk-edit-highlight):not(.cell-editing) {
      opacity: 0.4;
      transition: opacity 0.15s ease-in-out;
    }
  }
  
  .tabulator-cell {
    &.bulk-edit-highlight {
      opacity: 1 !important;
      z-index: 2;
      
      &.would-be-edited-species {
        background-color: rgb(148 163 184 / 0.3) !important;
        outline: 1px solid rgb(148 163 184) !important;
      }
      
      &.would-be-edited-group {
        background-color: rgb(96 165 250 / 0.3) !important;
        outline: 1px solid rgb(96 165 250) !important;
      }
      
      &.would-be-edited-column {
        background-color: rgb(52 211 153 / 0.3) !important;
        outline: 1px solid rgb(52 211 153) !important;
      }
      
      &.would-be-edited-matching {
        background-color: rgb(167 139 250 / 0.3) !important;
        outline: 1px solid rgb(167 139 250) !important;
      }
    }
  }
}

// Dark mode styles
.dark .tabulator {
  .tabulator-cell {
    &.bulk-edit-highlight {
      &.would-be-edited-species {
        background-color: rgb(148 163 184 / 0.2) !important;
        outline: 1px solid rgb(148 163 184 / 0.4) !important;
      }
      
      &.would-be-edited-group {
        background-color: rgb(96 165 250 / 0.2) !important;
        outline: 1px solid rgb(96 165 250 / 0.4) !important;
      }
      
      &.would-be-edited-column {
        background-color: rgb(52 211 153 / 0.2) !important;
        outline: 1px solid rgb(52 211 153 / 0.4) !important;
      }
      
      &.would-be-edited-matching {
        background-color: rgb(167 139 250 / 0.2) !important;
        outline: 1px solid rgb(167 139 250 / 0.4) !important;
      }
    }
  }
}

// Override hover effects for highlighted cells
.tabulator-cell.bulk-edit-highlight.hover {
  background-color: inherit !important;
  color: inherit !important;
}

// Update the bulk-edit-preview styles
.tabulator.bulk-edit-preview {
  .tabulator-cell:not(.bulk-edit-highlight):not(.cell-editing) {
    opacity: 0.4;
    transition: opacity 0.15s ease-in-out;
    
    // Disable hover effects during bulk edit preview
    &.hover {
      background-color: inherit !important;
      color: inherit !important;
    }
  }
  
  // Disable hover effects for all cells during bulk edit preview
  .tabulator-cell.hover:not(.cell-editing):not(.bulk-edit-highlight) {
    background-color: inherit !important;
    color: inherit !important;
    border: none !important;
  }
}

// Add these dropdown-specific styles
.dropdown-menu-content {
  animation: fadeIn 80ms ease-out;
}

.dropdown-menu-item {
  transition: background-color 150ms ease;
  
  &:hover {
    background-color: hsl(var(--muted) / 0.5);
  }
}

/* Add this to your existing styles */
.absolute {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: var(--mirror-height);
    background-image: var(--mirror-image);
    background-position: var(--mirror-position);
    background-size: var(--mirror-size);
    transform: var(--mirror-transform);
    z-index: -1;
    filter: url(#noise);
    mix-blend-mode: screen; /* Add this to ensure consistent brightening effect */
  }
}
</style>