import revive_payload_client_b5qzzz8poC from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@azure+cosmos@4.2.0_@azure+identity@4.5.0_@libsql+client@0.5.6_@parcel+watcher@2._mvd66afccog5kdo67zpfno43t4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_aQmBylXsnc from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@azure+cosmos@4.2.0_@azure+identity@4.5.0_@libsql+client@0.5.6_@parcel+watcher@2._mvd66afccog5kdo67zpfno43t4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_OeeJZKY5Ys from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@azure+cosmos@4.2.0_@azure+identity@4.5.0_@libsql+client@0.5.6_@parcel+watcher@2._mvd66afccog5kdo67zpfno43t4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_BWdkbhaPA8 from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.10.2_sass@1.79_fm42gsxd6hdmdqpenvtmqyisn4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import supabase_client_ochTmB7uqX from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_XbAXTndLiC from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@azure+cosmos@4.2.0_@azure+identity@4.5.0_@libsql+client@0.5.6_@parcel+watcher@2._mvd66afccog5kdo67zpfno43t4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6aq3WSbBXJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@azure+cosmos@4.2.0_@azure+identity@4.5.0_@libsql+client@0.5.6_@parcel+watcher@2._mvd66afccog5kdo67zpfno43t4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_oIduYhM0CV from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@azure+cosmos@4.2.0_@azure+identity@4.5.0_@libsql+client@0.5.6_@parcel+watcher@2._mvd66afccog5kdo67zpfno43t4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ff6eA1Ee2w from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@azure+cosmos@4.2.0_@azure+identity@4.5.0_@libsql+client@0.5.6_@parcel+watcher@2._mvd66afccog5kdo67zpfno43t4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_L3gVfo1aMU from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typescript@5.7.3_vue@3.5.8_typescript@5.7.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_2JLjkZvDOk from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@azure+cosmos@4.2.0_@azure+identity@4.5.0_@libsql+client@0.5.6_@parcel+watcher@2._mvd66afccog5kdo67zpfno43t4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w4GC5dkSHQ from "/vercel/path0/node_modules/.pnpm/nuxt-echarts@0.2.3_echarts@5.5.1_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import plugin_ag3FcYHBSB from "/vercel/path0/node_modules/.pnpm/vue3-perfect-scrollbar@2.0.0_vue@3.5.8_typescript@5.7.3_/node_modules/vue3-perfect-scrollbar/nuxt/dist/runtime/plugin.mjs";
import plugin_KsQVqSG7WX from "/vercel/path0/node_modules/.pnpm/nuxt-split-type@0.1.8_magicast@0.3.5_nuxt@3.13.1_@azure+cosmos@4.2.0_@azure+identity@4.5.0_@l_53blwntjpipkqxxmlnwtcopmy4/node_modules/nuxt-split-type/dist/runtime/plugin.mjs";
import gsapPlugin_HlwIIyl85L from "/vercel/path0/.nuxt/gsapPlugin.mjs";
import plugin_SOrYddXlp7 from "/vercel/path0/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_wy0B721ODc from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import anchorscroll_plugin_9RJrmH2QYt from "/vercel/path0/node_modules/.pnpm/nuxt-anchorscroll@1.0.3_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/nuxt-anchorscroll/dist/runtime/anchorscroll-plugin.mjs";
import _001_notivue_client_bE2FHKqHiu from "/vercel/path0/.nuxt/001.notivue.client.mjs";
import motion_RN1b9TbFCk from "/vercel/path0/node_modules/.pnpm/@vueuse+motion@2.2.5_magicast@0.3.5_rollup@4.22.4_vue@3.5.8_typescript@5.7.3__webpack-sources@3.2.3/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import plugin_client_dlry8Kmpr6 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import auth_redirect_5EDtur7iPR from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import cookiebot_plugin_aB7hH5n8Oy from "/vercel/path0/node_modules/.pnpm/@weareheavy+nuxt-cookie-consent@1.0.4_magicast@0.3.5_rollup@4.22.4_webpack-sources@3.2.3/node_modules/@weareheavy/nuxt-cookie-consent/dist/runtime/plugins/cookiebot.plugin.mjs";
import plugin_SGs8DYZR87 from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_typesc_pjdu5k4ztpeywn4obtppcwamyu/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _0_22_drag_select_midNNg2mzb from "/vercel/path0/plugins/0.22.drag-select.ts";
import _0_24_Vue3Lottie_client_nF1VBylm6x from "/vercel/path0/plugins/0.24.Vue3Lottie.client.ts";
import _0_94_plyr_client_b751RLv0Mr from "/vercel/path0/plugins/0.94.plyr.client.ts";
import _0_95_scattergl_client_YbTedD7arR from "/vercel/path0/plugins/0.95.scattergl.client.ts";
import _0_95_vercel_analytics_client_AHihIkYZm2 from "/vercel/path0/plugins/0.95.vercel-analytics.client.ts";
import _010_sentry_client_qtk3FqHvvX from "/vercel/path0/plugins/010.sentry.client.ts";
import _011_vue_code_layout_client_YsRSp6uV1S from "/vercel/path0/plugins/011.vue-code-layout.client.ts";
import _012_vue_photo_preview_client_xt8Txlkm6Q from "/vercel/path0/plugins/012.vue-photo-preview.client.ts";
import _020_fit2box_client_7dQ8xfNgoy from "/vercel/path0/plugins/020.fit2box.client.ts";
import _021_vueuse_gesture_5lK55OQwFQ from "/vercel/path0/plugins/021.vueuse.gesture.ts";
import _023_json_viewer_client_CwNwUoGvLp from "/vercel/path0/plugins/023.json-viewer.client.ts";
import _030_uplot_client_sOHiDendNY from "/vercel/path0/plugins/030.uplot.client.ts";
import _040_auto_animate_client_kSN6cGeXRu from "/vercel/path0/plugins/040.auto-animate.client.ts";
import _070_uppy_client_yepT13vxoE from "/vercel/path0/plugins/070.uppy.client.ts";
import _080_error_handler_pzY4GmDTGp from "/vercel/path0/plugins/080.error-handler.ts";
import _091_vgrid_client_lex8Ugz66y from "/vercel/path0/plugins/091.vgrid.client.ts";
import _093_useLicense_client_ZxTFj52SQu from "/vercel/path0/plugins/093.useLicense.client.ts";
import authNavGuard_client_5VQHzXtSXe from "/vercel/path0/plugins/authNavGuard.client.ts";
export default [
  revive_payload_client_b5qzzz8poC,
  unhead_aQmBylXsnc,
  router_OeeJZKY5Ys,
  _0_siteConfig_BWdkbhaPA8,
  supabase_client_ochTmB7uqX,
  payload_client_XbAXTndLiC,
  navigation_repaint_client_6aq3WSbBXJ,
  check_outdated_build_client_oIduYhM0CV,
  chunk_reload_client_ff6eA1Ee2w,
  plugin_vue3_L3gVfo1aMU,
  components_plugin_KR1HBZs4kY,
  prefetch_client_2JLjkZvDOk,
  plugin_w4GC5dkSHQ,
  plugin_ag3FcYHBSB,
  plugin_KsQVqSG7WX,
  gsapPlugin_HlwIIyl85L,
  plugin_SOrYddXlp7,
  plugin_wy0B721ODc,
  anchorscroll_plugin_9RJrmH2QYt,
  _001_notivue_client_bE2FHKqHiu,
  motion_RN1b9TbFCk,
  plugin_client_dlry8Kmpr6,
  auth_redirect_5EDtur7iPR,
  cookiebot_plugin_aB7hH5n8Oy,
  plugin_SGs8DYZR87,
  _0_22_drag_select_midNNg2mzb,
  _0_24_Vue3Lottie_client_nF1VBylm6x,
  _0_94_plyr_client_b751RLv0Mr,
  _0_95_scattergl_client_YbTedD7arR,
  _0_95_vercel_analytics_client_AHihIkYZm2,
  _010_sentry_client_qtk3FqHvvX,
  _011_vue_code_layout_client_YsRSp6uV1S,
  _012_vue_photo_preview_client_xt8Txlkm6Q,
  _020_fit2box_client_7dQ8xfNgoy,
  _021_vueuse_gesture_5lK55OQwFQ,
  _023_json_viewer_client_CwNwUoGvLp,
  _030_uplot_client_sOHiDendNY,
  _040_auto_animate_client_kSN6cGeXRu,
  _070_uppy_client_yepT13vxoE,
  _080_error_handler_pzY4GmDTGp,
  _091_vgrid_client_lex8Ugz66y,
  _093_useLicense_client_ZxTFj52SQu,
  authNavGuard_client_5VQHzXtSXe
]