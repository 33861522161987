import { defineStore } from 'pinia'
import {menuItems} from './menuItems'
import { useWindowSize } from '@vueuse/core'
import GenericAlertDialog from '@/components/GenericAlertDialog.vue'
import { useScrollbarWidth } from '@/lib/composables/useScrollbarWidth'

export interface PlantDropEvent {
  type: 'plant-dropped'
  payload: {
    species: string
    panelId: string
    toolId: string
    plant: {
      species: string
      photo: string
      count?: number
      [key: string]: any
    }
    x: number
    y: number
  }
}

export type StoreEvent = PlantDropEvent // Add other event types here

interface CostPercentiles {
  p0: number
  p5: number
  p25: number
  p50: number
  p75: number
  p95: number
  p100: number
}

interface TreeCosts {
  [key: string]: {
    [costType: string]: CostPercentiles
  }
}

interface AlertConfig {
  title: string
  description?: string
  html?: string
  confirmText?: string
  cancelText?: string | null
  destructive?: boolean
  inputRequired?: boolean
  inputPlaceholder?: string
  onMount?: () => void
  component?: Component
  props?: Record<string, any>
  listeners?: Record<string, (...args: any[]) => void>
  width?: string
  onAction?: (action: 'confirm' | 'cancel', inputText?: string) => any
}

interface BottomDrawerConfig {
  title: string
  hideCloseButton?: boolean
  description?: string
  component?: Component
  props?: Record<string, any>
  listeners?: Record<string, Function>
  actions?: Array<{
    label: string
    action: string
    variant?: string
    data?: any
  }>
  onAction?: (action: string, data?: any) => void
}

// First, let's define proper interfaces for our context types
interface FileUploadContext {
  fromOtherComponent?: boolean;
  owner_id?: string;
  owner_type?: string;
  collection_id?: string;
  use_cache: boolean;
  notFromProjectDock?: boolean;
}

export const useUiStore = defineStore('useUiStore', () => {
    
    
  const { height: windowHeight, width: windowWidth } = useWindowSize()
  const { width: scrollbarWidth } = useScrollbarWidth()
    const firstTime = ref(true)
    const bottomSheetOpen = ref(false)
    const checkingOut = ref(false)
    const prev = ref(true)
    const next = ref(false)
    const dialog = ref(false)
    const selectedInterval = ref('year')
    const invitedUsers = ref([])
    const plans = ref([])
    const selectedPlan = ref(null)
    const stripeClientSecret = ref('')
    const menu = ref(menuItems)
    const projectImages = ref([])
    const uploadingStatus = ref('')
    const bottomSheetDynamicComponent = ref<Component | null>(null)
    const sideSheetDynamicComponent = ref(null)
    const firstUpload = ref(true)
    const uploadConsent = ref(false)
    const fileUploadContext = ref<FileUploadContext>({
        use_cache: false
    })
    const reactiveRoute = ref(null)
    const componentSearchOpen = ref(false)
    const bottomDrawerHeight = ref(0)
    const sideDrawerOpen = ref(false)
    const chatDialogOpen = ref(false)
    const chatDialogPinned = ref(false)
    const showAlert = ref(false)
    const alertDialogDynamicComponent = ref(null)
    const alertConfig = ref(null)
    const alertResolver = ref(null)
    const onboarding = ref({
      general: { open: true },
      wellspring: { open: true },
      wellspringRegistration: { open: true },
    });
    const wellspringJoinCurrentSlide = ref(0)
    const shineEffect = ref(false)
    const hideFileUploadOnNewTab = ref(true)
    const reviewDrawerOpen = ref(false)
    const reviewDrawerDynamicComponent = ref(null)
    const reviewSideDrawerMode = ref(false)
    const commandDialogOpen = ref(false)
    const bottomDrawerConfig = ref(null)
    const bottomDrawerResolver = ref(null)
    const bottomDrawerConfirmEnabled = ref(false)  // Initialize as false
  const banners = ref<Banner[]>([]);
  const dismissedBannerIds = ref<string[]>([]);
  const isDesktop = ref(false)
  const viewMode = ref<'auto' | 'desktop' | 'mobile'>('auto')

  const visibleBanners = computed(() =>
    banners.value.filter(banner => banner.show && !dismissedBannerIds.value.includes(banner.id))
  )

  const plantsInDock = ref([])
  const preventDragOver = ref(false)

  const mfaPreferences = ref({
    hasDeliberatelyDisabled: false,
    lastUpdated: null as number | null
  })

  const eventListeners = ref(new Map<StoreEvent['type'], Set<(event: StoreEvent) => void>>())

  const emitEvent = (event: StoreEvent) => {
    console.log('Store emitting event:', event.type)
    const listeners = eventListeners.value.get(event.type)
    console.log('Found listeners:', listeners?.size || 0)
    if (listeners) {
      listeners.forEach(callback => {
        console.log('Calling listener')
        callback(event)
      })
    }
  }

  const addEventListener = (eventType: StoreEvent['type'], callback: (event: StoreEvent) => void) => {
    console.log('Adding event listener for:', eventType)
    if (!eventListeners.value.has(eventType)) {
      console.log('Creating new listener set')
      eventListeners.value.set(eventType, new Set())
    }
    eventListeners.value.get(eventType)?.add(callback)
    console.log('Current listeners:', eventListeners.value.get(eventType)?.size)
  }

  const removeEventListener = (eventType: StoreEvent['type'], callback: (event: StoreEvent) => void) => {
    eventListeners.value.get(eventType)?.delete(callback)
  }

  const setFirstTime = (s: boolean) => {
    firstTime.value = s
  }

  const setAlertDialogComponent = (component) => {
    alertDialogDynamicComponent.value = component;
  };

  const toggleAlert = (value?: boolean) => {
    showAlert.value = value !== undefined ? value : !showAlert.value;
  };

  const setAlertConfig = (config: AlertConfig, usePromise = false) => {
    console.log('Setting alert config:', config);
    alertConfig.value = config
    alertDialogDynamicComponent.value = markRaw(GenericAlertDialog)
    showAlert.value = true
    console.log('Alert should be visible now. showAlert:', showAlert.value);
    
    if (usePromise) {
      return new Promise((resolve) => {
        alertResolver.value = resolve
      });
    }
  }

  const handleAlertAction = (action: 'confirm' | 'cancel', inputText?: string) => {
    console.log('Handling alert action:', action, inputText);
    if (alertConfig.value && alertConfig.value.onAction) {
      const result = alertConfig.value.onAction(action, inputText);
      if (alertResolver.value) {
        alertResolver.value(result);
        alertResolver.value = null;
      }
    }
    closeAlert();
  }

  const closeAlert = () => {
    console.log('Closing alert');
    showAlert.value = false
    alertDialogDynamicComponent.value = null
    alertConfig.value = null
    if (alertResolver.value) {
      alertResolver.value(null)
      alertResolver.value = null
    }
  }

  const setBottomSheetViewMode = (mode: 'auto' | 'desktop' | 'mobile') => {
    viewMode.value = mode
  }

  const toggleBottomSheet = (s?: boolean, mode?: 'auto' | 'desktop' | 'mobile') => {
    viewMode.value = mode || 'auto'
    
    if (s === undefined || s === null) {
      bottomSheetOpen.value = !bottomSheetOpen.value
    } else {
      bottomSheetOpen.value = s
    }
  }
  const toggleSideSheet = (s: boolean) => {
      if (s === undefined || s === null ) {
          sideDrawerOpen.value = !sideDrawerOpen.value
          return
      } else {
          sideDrawerOpen.value = s
      }
  }

  
  const toggleReviewDrawer = (s?: boolean) => {
      if (s === undefined || s === null ) {
          reviewDrawerOpen.value = !reviewDrawerOpen.value
          return
      } else {
          reviewDrawerOpen.value = s
      }
  }

  const toggleReviewSideDrawerMode = (s: boolean) => {
      reviewSideDrawerMode.value = s
  }


  const toggleDialog = (s) => {
      if (s === undefined || s === null ) {
          dialog.value = s || !dialog.value
          return
      } else {
          dialog.value = s
      }
  }
  const setCheckingOut = (s: boolean) => {
      checkingOut.value = s
  }

  const triggerPrev = () => {
      prev.value = !prev.value
  }
  
  const triggerNext = () => {
      next.value = !next.value
  }

  const setselectedInterval = (f: string) => {
      selectedInterval.value = f
  }

  const setInvitedUsers = (u: any) => {
      invitedUsers.value = u
  }

  const setPlans = (p: any) => {
      plans.value = p
  }

  const setSelectedPlan = (p: any) => {
      selectedPlan.value = { ...p };
  }

  const setStripeClientSecret = (s: string) => {
      stripeClientSecret.value = s
  }

  const setMenu = (m: any) => {
      menu.value = m
  }

  const setProjectImages = (p: any) => {
      projectImages.value = p
  }

  const setUploadingStatus = (s: string) => {
      uploadingStatus.value = s
  }

  const setBottomSheetDynamicComponent = (component: Component | null) => {
    console.log('Setting bottom sheet component:', component?.name);
    bottomSheetDynamicComponent.value = component ? markRaw(component) : null;
  }

  const setSideSheetDynamicComponent = (component: any) => {
      sideSheetDynamicComponent.value = component
  }

  const setReviewDrawerDynamicComponent = (component: any) => {
      reviewDrawerDynamicComponent.value = component
  }

  const setFirstUpload = (s: boolean) => {
      firstUpload.value = s
  }
  const setUploadConsent = (s: boolean) => {
      uploadConsent.value = s
  }
  const setFileUploadContext = (context: Partial<FileUploadContext>) => {
    const newContext = {
      use_cache: false,
      ...fileUploadContext.value,
      ...context
    }
    
    fileUploadContext.value = newContext
  }

  const setReactiveRoute = (r: any) => {
      reactiveRoute.value = markRaw(r)
  }

  const toggleComponentSearch = (s: boolean) => {
      componentSearchOpen.value = s
  }

  const setBottomDrawerHeight = (h: number) => {
      bottomDrawerHeight.value = h
  }

  const toggleSideDrawer = (s: boolean) => {
      sideDrawerOpen.value = s
  }

  const toggleChatDialog = (s: boolean = !chatDialogOpen.value) => {
      chatDialogOpen.value = s;
  }

  const togglePinChatDialog = (s: boolean = !chatDialogPinned.value) => {
      chatDialogPinned.value = s
  }

  const toggleOnboard = (type: string, open: boolean) => {
      onboarding.value[type].open = open
  }

  const setWellspringJoinCurrentSlide = (s: number) => {
      wellspringJoinCurrentSlide.value = s
  }

  const triggerShineEffect = () => {
      shineEffect.value = !shineEffect.value
  }
  
  const setHideFileUploadOnNewTab = (s: boolean = !hideFileUploadOnNewTab.value) => {
      hideFileUploadOnNewTab.value = s
  }

  const toggleCommandDialog = (s: boolean) => {
      commandDialogOpen.value = s
  }
  
  const addBanner = (banner: Omit<Banner, 'show'>) => {
      if (!dismissedBannerIds.value.includes(banner.id)) {
          banners.value.push({ ...banner, show: true });
      }
  }
  
  const toggleBanner = (id: string, state?: boolean) => {
      const banner = banners.value.find(b => b.id === id);
      if (banner) {
          banner.show = state !== undefined ? state : !banner.show;
      }
  }

  const removeBanner = (id: string) => {
      const index = banners.value.findIndex(b => b.id === id);
      if (index !== -1) {
          banners.value.splice(index, 1);
          dismissedBannerIds.value.push(id);
      }
  }

  const setBottomDrawerConfig = (config: BottomDrawerConfig | null, usePromise = false) => {
    // Clear previous state first
    bottomSheetDynamicComponent.value = null;
    useConfigurableBottomDrawer.value = false;
    bottomDrawerConfig.value = null;
    
    // Then set new config if provided
    if (config) {
      bottomDrawerConfig.value = config;
      useConfigurableBottomDrawer.value = true;
      bottomSheetOpen.value = true;
      
      if (usePromise) {
        return new Promise((resolve) => {
          bottomDrawerResolver.value = resolve;
        });
      }
    }
  }

  const setBottomDrawerConfirmEnabled = (enabled: boolean) => {
      bottomDrawerConfirmEnabled.value = enabled
  }

  const handleBottomDrawerAction = (action: string, data?: any) => {
      if (bottomDrawerConfig.value && bottomDrawerConfig.value.onAction) {
          const result = bottomDrawerConfig.value.onAction(action, data);
          if (bottomDrawerResolver.value) {
              bottomDrawerResolver.value(result);
              bottomDrawerResolver.value = null;
          }
      }
      closeBottomDrawer();
  }

  const closeBottomDrawer = () => {
      bottomSheetOpen.value = false;
      bottomSheetDynamicComponent.value = null;
      bottomDrawerConfig.value = null;
      useConfigurableBottomDrawer.value = false;
      
      fileUploadContext.value = {
        ...fileUploadContext.value,
        use_cache: false
      };
      
      if (bottomDrawerResolver.value) {
        bottomDrawerResolver.value(null);
        bottomDrawerResolver.value = null;
      }
  }

  const useConfigurableBottomDrawer = ref(false)

  const addPlantToDock = (plant: any) => {
      const existingPlantIndex = plantsInDock.value.findIndex(p => p.species === plant.species)
      
      if (existingPlantIndex !== -1) {
          // If plant already exists, increment its count
          if (!plantsInDock.value[existingPlantIndex].count) {
              plantsInDock.value[existingPlantIndex].count = 2
          } else {
              plantsInDock.value[existingPlantIndex].count++
          }
      } else {
          // If it's a new plant, add it with count 1
          plantsInDock.value.push({ ...plant, count: 1 })
      }
  } 

  const removePlantFromDock = (plant: any) => {
      const existingPlantIndex = plantsInDock.value.findIndex(p => p.species === plant.species)
      
      if (existingPlantIndex !== -1) {
          if (plantsInDock.value[existingPlantIndex].count > 1) {
              // If there are multiple, decrease count
              plantsInDock.value[existingPlantIndex].count--
          } else {
              // If it's the last one, remove it
              plantsInDock.value = plantsInDock.value.filter(p => p.species !== plant.species)
          }
      }
  }
  const clearPlantsInDock = () => {
      plantsInDock.value = []
  }
  const updatePlantInDock = (plant: any) => {
      const index = plantsInDock.value.findIndex(p => p.id === plant.id)
      if (index !== -1) {
          plantsInDock.value[index] = plant
      }
  }
  const setPreventDragOver = (s: boolean) => {
      preventDragOver.value = s
  }

  const setMfaPreferences = (prefs: Partial<typeof mfaPreferences.value>) => {
    mfaPreferences.value = { 
      ...mfaPreferences.value, 
      ...prefs,
      lastUpdated: Date.now() 
    }
  }

  // Add state for tree costs
  const customTreeCosts = ref<TreeCosts>({})

  // Add method to update cost percentiles
  const updateCostPercentile = (
    volumeKey: string,
    costType: string,
    percentileKey: keyof CostPercentiles,
    value: number
  ) => {
    if (!customTreeCosts.value[volumeKey]) {
      customTreeCosts.value[volumeKey] = {}
    }
    if (!customTreeCosts.value[volumeKey][costType]) {
      customTreeCosts.value[volumeKey][costType] = {
        p0: 0, p5: 0, p25: 0, p50: 0, p75: 0, p95: 0, p100: 0
      }
    }
    customTreeCosts.value[volumeKey][costType][percentileKey] = value
  }

  // Add method to get custom cost or default
  const getCostPercentiles = (
    volumeKey: string,
    costType: string,
    defaultCosts: CostPercentiles
  ): CostPercentiles => {
    return customTreeCosts.value[volumeKey]?.[costType] || defaultCosts
  }

  return {
        setFirstTime,
        firstTime,
      windowWidth,
      windowHeight,
      scrollbarWidth,
      bottomSheetOpen,
      toggleBottomSheet,
      dialog,
      toggleDialog,
      checkingOut,
      setCheckingOut,
      triggerPrev,
      prev,
      triggerNext,
      next,
      setselectedInterval,
      selectedInterval,
      invitedUsers,
      setInvitedUsers,
      plans,
      setPlans,
      setSelectedPlan,
      selectedPlan,
      stripeClientSecret,
      setStripeClientSecret,
      menu,
      setMenu,
      projectImages,
      setProjectImages,
      uploadingStatus,
      setUploadingStatus,
      bottomSheetDynamicComponent,
      setBottomSheetDynamicComponent,
      firstUpload,
      setFirstUpload,
      uploadConsent,
      setUploadConsent,
      fileUploadContext,
      setFileUploadContext,
      reactiveRoute,
      setReactiveRoute,
      componentSearchOpen,
      toggleComponentSearch,
      bottomDrawerHeight,
      setBottomDrawerHeight,
      sideDrawerOpen,
      toggleSideDrawer,
      chatDialogOpen,
      toggleChatDialog,
      chatDialogPinned,
      togglePinChatDialog,
      showAlert,
      setAlertDialogComponent,
      alertDialogDynamicComponent,
      toggleAlert,
      alertConfig,
      setAlertConfig,
      handleAlertAction,
      closeAlert,
      sideSheetDynamicComponent,
      setSideSheetDynamicComponent,
      toggleSideSheet,
      toggleOnboard,
      onboarding,
      setWellspringJoinCurrentSlide,
        wellspringJoinCurrentSlide,
        shineEffect,
        triggerShineEffect,
        hideFileUploadOnNewTab,
        setHideFileUploadOnNewTab,
        reviewDrawerOpen,
        toggleReviewDrawer,
        reviewDrawerDynamicComponent,
        setReviewDrawerDynamicComponent,
        commandDialogOpen,
        toggleCommandDialog,
        bottomDrawerConfig,
        setBottomDrawerConfig,
        handleBottomDrawerAction,
        closeBottomDrawer,
        useConfigurableBottomDrawer,
        bottomDrawerConfirmEnabled,
        setBottomDrawerConfirmEnabled,
        banners,
        addBanner,
        toggleBanner,
        removeBanner,
        dismissedBannerIds,
        visibleBanners,
        reviewSideDrawerMode,
        toggleReviewSideDrawerMode,
        isDesktop,
        viewMode,
        setBottomSheetViewMode,
        plantsInDock,
        addPlantToDock,
        removePlantFromDock,
        clearPlantsInDock,
        updatePlantInDock,
        preventDragOver,
        setPreventDragOver,
        mfaPreferences,
        setMfaPreferences,
        addEventListener,
        removeEventListener,
        emitEvent,
        customTreeCosts,
        updateCostPercentile,
        getCostPercentiles
  };

}, {
    persist: {
    pick: ['uploadConsent', 'bottomSheetOpen', 'plans', 'invitedUsers', 'dismissedBannerIds', 'mfaPreferences', 'customTreeCosts', 'plantsInDock'],
}})