<script setup lang="ts">
import { useParallax } from '@vueuse/core'
import { useBreakpoints } from '@vueuse/core'

const [blur] = defineModel('blur', {
  type: Boolean,
  default: true
})

const { src, title, tilt, roll, width, height, parallax = true } = defineProps({
  src: {
    type: [String, Object],
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  tilt: {
    type: Number,
    required: true,
  },
  roll: {
    type: Number,
    required: true,
  },
  width: {
    type: [String, Number],
    default: '500px'
  },
  height: {
    type: [String, Number],
    default: '500px'
  },
  parallax: {
    type: Boolean,
    default: true
  }
})

const base_url = useRuntimeConfig().public.imagekit_base_url

const target = ref(null)
const { roll: pRoll, tilt: pTilt } = useParallax(target)
const showFallback = ref(false)

const rotateCoeff = ref(30)
const translateCoeff = ref(15)

const breakpoints = useBreakpoints({
  md: 768 // matches Tailwind's default md breakpoint
})
const isMobile = computed(() => !breakpoints.greater('md').value)

const imageSrc = computed(() => {
  if (typeof src === 'string') {
    return `${base_url}${src}`
  }
  return `${base_url}${src.path || src.url}`
})

const cardStyle = computed(() => ({
  transform: parallax 
    ? `rotateY(${pTilt.value * rotateCoeff.value}deg)
       translateX(${pTilt.value * translateCoeff.value}px)
       ${isMobile.value ? `rotateX(${pRoll.value * rotateCoeff.value}deg)
       translateY(${pRoll.value * translateCoeff.value}px)` : ''}
       scale(1.33)`
    : 'scale(1.33)',
}))

// Array of pastel background classes
const pastelBackgrounds = [
  'bg-rose-200',
  'bg-pink-200',
  'bg-fuchsia-200',
  'bg-purple-200',
  'bg-violet-200',
  'bg-indigo-200',
  'bg-blue-200',
  'bg-sky-200',
  'bg-cyan-200',
  'bg-teal-200',
  'bg-emerald-200',
  'bg-green-200',
  'bg-lime-200',
  'bg-yellow-200',
  'bg-amber-200',
  'bg-orange-200',
  'bg-red-200',
]

// Generate a random pastel background class
const randomPastelBackground = computed(() => {
  const randomIndex = Math.floor(Math.random() * pastelBackgrounds.length)
  return pastelBackgrounds[randomIndex]
})

const handleImageError = (e: Event) => {
  const target = e.target as HTMLImageElement;
  target.style.display = 'none';
  showFallback.value = true;
}

</script>

<template>
  <div ref="target" class="masked transition-all duration-150 ease-in-out" :class="$attrs.class">
    <div :style="{ perspective: '100px', ...cardStyle }">
      <div id="card">
        <div id="layer0" 
          class="hover:scale-[0.95] transition-all duration-100 ease-out aspect-square"
          :class="{ [randomPastelBackground]: showFallback }"
        >
          <img 
            :src="imageSrc"
            class="select-none transition-all duration-150 ease-out aspect-square sm:blur-[3px] saturate-[0.5]"
            :class="{              
              '!blur-none': blur==false,
              '!saturate-150': blur==false,
            }"
            :width="width"
            :height="height"
            :alt="title"
            loading="lazy"
            @error="handleImageError" 
          />
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="width === '500px'"
    class="pointer-events-none select-none absolute top-0 flex w-full justify-center items-center align-center aspect-square scale-[0.9] sm:scale-[0.8] md:scale-[0.9] lg:scale-[0.8] lg:-mt-0 lg:scale-[0.9]">
    <CircleTicker :title="title" :text="title" />
  </div>
</template>

<style>
.masked {
  mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0Z'/%3e%3c/svg%3e");
  mask-repeat: no-repeat;
  mask-size: contain;
}

/* Add this to prevent alt text from showing */
img {
  text-indent: -9999px;
  color: transparent;
}
</style>
