import * as cheerio from 'cheerio';
import { useValidationStore } from '@/stores/validationStore';
import { useProjectStore } from '@/stores/projectStore';
import { storeToRefs } from 'pinia';
import { useSearch } from '@/composables/useSearch';
import type { 
  NameStatus, 
  Validation, 
  AvailabilityValidation, 
  AvailabilityResult, 
  ECSearchResponse 
} from '@/types/validation';

// Add missing type for eppoCache
interface NuxtAppWithCache extends ReturnType<typeof useNuxtApp> {
  eppoCache: Record<string, any>;
}

// Add type definitions at the top of the file
interface NameStatusMatch {
  original: string;
  matched: string | null;
  source: 'tnrs' | 'gbif' | null;
}

interface TNRSResult {
  Name_submitted: string;
  Name_matched: string;
  Overall_score: number;
}

export const useValidation = () => {
    const project = useProjectStore();
    const { selectedProject } = storeToRefs(project);
    const validationStore = useValidationStore();
    const { allValidations } = storeToRefs(validationStore);
    const validationLoadingState = ref<"idle" | "pending" | "error">("idle");

    const validationIcons = {
        nameStatus: 'solar:tag-bold',
        biosecurityStatus: 'ph:bug-fill',
        availabilityStatus: 'ph:shopping-cart-fill',
        complianceStatus: 'solar:check-circle-linear',
        diversityStatus: 'bi:globe-americas'
    }

    const { fetchBiosecurityData, fetchSerper } = useSearch();

    const nameStatus: Ref<NameStatus> = ref({
        exact: [] as NameStatusMatch[],
        inexact: [] as NameStatusMatch[],
        invalid: [] as NameStatusMatch[]
    });

    const securityStatus = ref({
        host: [] as { species: string; source: string }[],
        pests: [] as { species: string; source: string }[],
    });

    const nuxtApp = useNuxtApp() as NuxtAppWithCache;

    // Create a cache object if it doesn't exist
    if (!nuxtApp.eppoCache) {
        nuxtApp.eppoCache = {};
    }

    // Generic function to fetch Eppo data based on type
    const findEppoData = async (type: 'hosts' | 'pests', eppocode: string) => {
        const cacheKey = `${type}:${eppocode}`;

        // Check if the data is already in the cache
        if (nuxtApp.eppoCache[cacheKey]) {
            return nuxtApp.eppoCache[cacheKey];
        }

        console.log(`/api/proxy/eppo/${type}`);
        const proxyResponse = await $fetch(`/api/proxy/eppo/${type}`, {
            method: 'POST',
            body: { eppocode },
            responseType: 'text',
        });
        const $ = cheerio.load(proxyResponse);
        const result = $('table#dttable tbody tr')
            .map((_, row) => ({
                name: $(row).find('td:nth-of-type(2) a').text().trim(),
                type: $(row).find('td:nth-of-type(3)').text().trim(),
                source: 'eppo' as const,
            }))
            .get();

        // Store the result in the cache
        nuxtApp.eppoCache[cacheKey] = result;

        return result;
    };

    const validateSpeciesNames = async (namesToCheck: string[], gbif_names: string[], project_id: string) => {
        validationLoadingState.value = "pending";
        
        try {
            // Clear previous results first
            nameStatus.value = {
                exact: [],
                inexact: [],
                invalid: []
            };

            // Use Sets to ensure uniqueness and normalize case
            const uniqueNames = [...new Set(namesToCheck.filter(Boolean).map(n => n.toLowerCase()))]
            const uniqueGbifNames = [...new Set(gbif_names.filter(Boolean).map(n => n.toLowerCase()))]
            
            // Create Sets to track which names have been added to each category
            const exactSet = new Set<string>()
            const inexactSet = new Set<string>()
            const invalidSet = new Set<string>()
            
            // First try GBIF matching
            for (let name of uniqueNames) {
              if (name === 'undefined') continue
              
              const lowercaseName = name
              const originalName = namesToCheck.find(n => n.toLowerCase() === lowercaseName)
              if (!originalName) continue
              
              // Skip if we've already processed this name in any category
              if (exactSet.has(lowercaseName) || inexactSet.has(lowercaseName) || invalidSet.has(lowercaseName)) {
                continue
              }

              // Try exact GBIF match first
              const gbifMatch = uniqueGbifNames.find(gbif_name => 
                gbif_name === lowercaseName
              )

              if (gbifMatch) {
                const matchedName = gbif_names.find(n => n.toLowerCase() === gbifMatch)
                if (matchedName && !exactSet.has(lowercaseName)) {
                  exactSet.add(lowercaseName)
                  nameStatus.value.exact.push({ 
                    original: originalName, 
                    matched: matchedName,
                    source: 'gbif'
                  })
                  continue
                }
              }

              // Try partial GBIF match
              const partialGbifMatch = uniqueGbifNames.find(gbif_name => 
                gbif_name.includes(lowercaseName)
              )
              if (partialGbifMatch) {
                const matchedName = gbif_names.find(n => n.toLowerCase() === partialGbifMatch)
                if (matchedName && !inexactSet.has(lowercaseName)) {
                  inexactSet.add(lowercaseName)
                  nameStatus.value.inexact.push({ 
                    original: originalName, 
                    matched: matchedName,
                    source: 'gbif'
                  })
                  continue
                }
              }

              // If no GBIF match, mark as temporarily invalid
              if (!exactSet.has(lowercaseName) && !inexactSet.has(lowercaseName)) {
                invalidSet.add(lowercaseName)
              }
            }

            // Get remaining invalid names that need TNRS validation
            const remainingInvalid = [...invalidSet]
            if (remainingInvalid.length > 0) {
              // Get TNRS results for remaining invalid names
              const { result: tnrsResults } = await fetchTnrs(remainingInvalid)
              
              // Create a map for quick lookups
              const tnrsResultMap = new Map<string, TNRSResult>(
                (tnrsResults as TNRSResult[])?.map(r => [r.Name_submitted?.toLowerCase() || '', r]) || []
              )
              
              // Create a Set to track original names that have been processed
              const processedOriginalNames = new Set<string>()
              
              // Process TNRS results
              for (let name of remainingInvalid) {
                // Skip if we've already processed this name in exact or inexact sets
                if (exactSet.has(name) || inexactSet.has(name)) continue
                
                const originalName = namesToCheck.find(n => n.toLowerCase() === name)
                if (!originalName) continue

                // Skip if we've already processed this original name
                if (processedOriginalNames.has(originalName.toLowerCase())) continue
                
                const tnrsMatch = tnrsResultMap.get(name)
                
                if (tnrsMatch?.Overall_score && tnrsMatch.Overall_score >= 0.9 && tnrsMatch.Name_matched) {
                  // High confidence TNRS match - remove from invalid and add to exact
                  invalidSet.delete(name)
                  if (!exactSet.has(name)) {
                    exactSet.add(name)
                    processedOriginalNames.add(originalName.toLowerCase())
                    nameStatus.value.exact.push({ 
                      original: originalName, 
                      matched: tnrsMatch.Name_matched,
                      source: 'tnrs'
                    })
                  }
                } else if (tnrsMatch?.Overall_score && tnrsMatch.Overall_score >= 0.5 && tnrsMatch.Name_matched) {
                  // Partial TNRS match - remove from invalid and add to inexact
                  invalidSet.delete(name)
                  if (!inexactSet.has(name)) {
                    inexactSet.add(name)
                    processedOriginalNames.add(originalName.toLowerCase())
                    nameStatus.value.inexact.push({ 
                      original: originalName, 
                      matched: tnrsMatch.Name_matched,
                      source: 'tnrs'
                    })
                  }
                } else {
                  // Keep as invalid if not already in invalid set
                  if (!invalidSet.has(name)) {
                    processedOriginalNames.add(originalName.toLowerCase())
                    nameStatus.value.invalid.push({ 
                      original: originalName, 
                      matched: null,
                      source: null
                    })
                  }
                }
              }
            }

            const validation: Validation = {
              name: 'nameStatus',
              result: nameStatus.value,
              show: nameStatus.value.invalid.length !== 0 || nameStatus.value.inexact.length !== 0
            }

            validationStore.addValidation(validation, project_id)

            validationLoadingState.value = "idle"
            return nameStatus.value
            
        } catch (error) {
            console.error("Error in validateSpeciesNames:", error)
            validationLoadingState.value = "error"
            throw error
        }
    }

    const validateAndRefreshBiosecurity = async (
      speciesNames: string[],
      panel_id?: string,
      country?: string,
      libtype?: string,
      codelang: string = 'en'
    ) => {
      validationLoadingState.value = "pending";
      console.log('Starting biosecurity validation for species:', speciesNames);
      const { fetchOpenverse, fetchSerper } = useSearch();
      const dockStore = useDockStore();
      const { panelState } = storeToRefs(dockStore);
      const client = useSupabaseClient();

      try {
        // Check cache for existing validations
        const { data: cachedValidations, error: cacheError } = await client
          .from('validations_biosecurity')
          .select('*')
          .in('species_name', unref(speciesNames))
          .gt('expires_at', new Date().toISOString());

        console.log('Cached validations:', cachedValidations);
        if (cacheError) {
          console.error("Error fetching cached validations:", cacheError);
        }

        const cachedSpecies = new Set(cachedValidations?.map(v => v.species_name) || []);
        const speciesToFetch = speciesNames.filter(name => !cachedSpecies.has(name));

        let validationResults: Record<string, any> = {};

        // Process cached results
        cachedValidations?.forEach(validation => {
          validationResults[validation.species_name] = validation.result;
        });

        if (speciesToFetch.length > 0) {
          const filters: string[] = [];
          if (country) filters.push(`country = ${country}`);
          if (libtype) filters.push(`libtype = ${libtype}`);

          // First get name validation results to get proper species names
          const nameResults = allValidations.value[selectedProject.value?.id]?.nameStatus;
          const validatedNames = new Set(getSpeciesNames(null, null, null, null, nameResults?.result));

          // Normalize names for comparison
          const normalizedValidNames = new Set(
            Array.from(validatedNames).map(name => name.toLowerCase().trim())
          );

          const searchResults = await fetchBiosecurityData(speciesToFetch, filters);
          console.log('Search results:', searchResults);

          const upsertPromises = speciesToFetch.map(async (name, index) => {
            const eppoHits = searchResults.eppo?.[index]?.hits || [];
            const padilData = searchResults.padil?.[index]?.data || [];

            let threatData = {
              isBiosecurityThreat: false,
              hosts: [] as { name: string; type: string; source: 'eppo' | 'padil' }[],
              pests: [] as { name: string; type: string; source: 'eppo' | 'padil' }[],
            };

            if (eppoHits.length > 0 || padilData.length > 0) {
              // Process EPPO data
              await Promise.all(
                eppoHits.map(async (species: any) => {
                  // Direct pest relationships for plants
                  if (species?.libtype?.includes("plant") && species?.codelang?.includes(codelang)) {
                    const pests = await findEppoData("pests", species.eppocode);
                    if (Array.isArray(pests)) {
                      threatData.pests.push(...pests.map(pest => ({ 
                        ...pest, 
                        source: 'eppo' as const,
                        relationshipType: 'direct'
                      })));
                    }
                  }

                  // Indirect relationships through pests
                  if (species?.libtype?.includes("animal") && species?.codelang?.includes(codelang)) {
                    const hosts = await findEppoData("hosts", species.eppocode);
                    if (Array.isArray(hosts)) {
                      // Only keep hosts that match our validated species names
                      const relevantHosts = hosts.filter(host => {
                        if (!host?.name) return false;
                        const normalizedHostName = host.name.toLowerCase().trim();
                        return normalizedValidNames.has(normalizedHostName);
                      });

                      if (relevantHosts.length > 0) {
                        threatData.hosts.push(...relevantHosts.map(host => ({
                          ...host,
                          source: 'eppo' as const,
                          relationshipType: 'indirect'
                        })));
                      }
                    }
                  }
                })
              );

              // Process PADIL data
              padilData.forEach((pest: any) => {
                if (pest?.scientificName && pest?.taxonomyLvl1) {
                  threatData.hosts.push({
                    name: pest.scientificName,
                    type: pest.taxonomyLvl1,
                    source: 'padil',
                  });
                }
              });

              threatData.isBiosecurityThreat = threatData.hosts.length > 0 || threatData.pests.length > 0;
            }

            validationResults[name] = threatData;

            // Modify the upsert operation
            return client
              .from('validations_biosecurity')
              .upsert(
                {
                  species_name: name,
                  api: 'eppo_padil',
                  result: threatData,
                  expires_at: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString() // Cache for 7 days
                },
                {
                  onConflict: 'species_name,api',
                  ignoreDuplicates: false
                }
              );
          });

          // Wait for all upsert operations to complete
          await Promise.all(upsertPromises);
        }

        // Fetch images for species with biosecurity threats
        const threatEntries = Object.entries(validationResults)
          .filter(([_, data]) => data.isBiosecurityThreat);

        const mappedEntries = await Promise.all(threatEntries.map(async ([species, data]) => {
          // Get up to 5 pests, prioritizing Major hosts first
          const majorHostPests = data.pests?.filter((pest: any) => pest.type === 'Major host') || [];
          const otherPests = data.pests?.filter((pest: any) => pest.type !== 'Major host') || [];
          const allPests = [...majorHostPests, ...otherPests];
          
          // Deduplicate pests by name
          const uniquePests = allPests.reduce((acc, pest) => {
            const pestName = pest.name?.replace(/\s*\([^)]*\)\s*/g, '');
            if (pestName && !acc.some(p => p.name?.replace(/\s*\([^)]*\)\s*/g, '') === pestName)) {
              acc.push(pest);
            }
            return acc;
          }, [] as any[]);

          const selectedPests = uniquePests.slice(0, 5);
          
          let images = [];
          let displayPests = [];
          
          // Fetch images for selected pests
          if (selectedPests.length > 0) {
            const imagePromises = selectedPests.map(async (pest: any) => {
              if (pest?.name) {
                const pestName = pest.name.replace(/\s*\([^)]*\)\s*/g, '');
                try {
                  const response = await $fetch('/api/serp/serper', {
                    method: 'POST',
                    body: { q: pestName }
                  });
                  if (response?.[0]?.imageUrl) {
                    return {
                      pest,
                      imageUrl: response[0].imageUrl
                    };
                  }
                } catch (error) {
                  console.error(`Error fetching image for pest ${pestName}:`, error);
                }
              }
              return null;
            });
            
            const fetchedResults = await Promise.all(imagePromises);
            
            // Create displayPests array with images, filtering out null results and missing images
            displayPests = fetchedResults
              .filter(result => result && result.imageUrl)
              .map(result => ({
                ...result.pest,
                imageUrl: result.imageUrl
              }));
            
            // Keep the images array for backward compatibility
            images = displayPests.map(p => p.imageUrl).filter(Boolean);
          }

          return [species, {
            ...data,
            hosts: data.hosts ?? [],
            pests: data.pests ?? [],
            images: images.slice(0, 5), // Limit to 5 unique images
            displayPests, // Add the new displayPests array
            scheduleChanged: false
          }];
        }));

        validationResults = Object.fromEntries(mappedEntries);

        validationLoadingState.value = "idle";
        console.log('validationResults', validationResults)
        return {
          result: validationResults,
          name: 'biosecurityStatus',
          show: Object.values(validationResults).some(result => result.isBiosecurityThreat)
        };
      } catch (error) {
        console.error("Error validating species against biosecurity data:", error);
        validationLoadingState.value = "error";
        return {
          result: {},
          name: 'biosecurityStatus',
          show: false
        };
      }
    };

    // Add this helper function to get all valid species names
    const getAllValidSpeciesNames = (nameStatus: NameStatus): string[] => {
      const validNames = new Set<string>();
      
      // Add exact matches first
      nameStatus.exact.forEach(match => {
        validNames.add(match.matched || match.original);
      });
      
      // Add inexact matches with good confidence (from TNRS)
      nameStatus.inexact.forEach(match => {
        if (match.source === 'tnrs' && match.matched) {
          validNames.add(match.matched);
        }
      });
      
      return Array.from(validNames);
    }

    // Update the getSpeciesNames function to include an optional nameStatus parameter
    const getSpeciesNames = (
        data?: string[],
        panelState?: any,
        panel_id?: string,
        availablePanels?: any[],
        nameStatus?: NameStatus
    ): string[] => {
        // If nameStatus is provided, return all valid names
        if (nameStatus) {
          return getAllValidSpeciesNames(nameStatus);
        }
        
        // Otherwise use existing logic for raw names
        if (data) {
            return data;
        }
        let output = panelState?.[panel_id]?.output || panelState?.[panel_id]?.data?.output;
        let rows = output?.tables?.rows || [];
        let metadata = toRaw(panelState?.[panel_id]?.output?.metadata || panelState?.[panel_id]?.data?.output?.metadata || availablePanels?.find(p => p.panel_id === panel_id)?.metadata);
        let mapped = rows.map(r => {
            return r[metadata?.columnToCanonical?.botanicalname];
        });
        return mapped || [];
    }

    const fetchTnrs = async (invalidNames: string[]) => {
      // Create a cache key that includes all unique names
      const uniqueNames = [...new Set(invalidNames.map(n => n.toLowerCase()))].sort()
      const cacheKey = `tnrs-${uniqueNames.join(',')}`
      
      const { data: tnrsData, error: tnrsError } = await useAsyncData(
        cacheKey,
        () => $fetch('/api/bio/tnrs', {
          method: 'POST',
          body: { searchQuery: uniqueNames }
        }),
        {
          getCachedData: (key) => {
            return useNuxtApp().payload.data[key] || null
          }
        }
      )

      console.log('TNRS data:', tnrsData.value)
      
      if (tnrsError.value) {
        console.error('Error fetching TNRS data:', tnrsError.value)
      }

      return { 
        result: toRaw(tnrsData.value), 
        error: toRaw(tnrsError.value) 
      }
    }

    const validateAvailability = async (
        speciesNames: string[],
        project_id: string,
        useCache: boolean = true
    ) => {
        validationLoadingState.value = "pending";

        try {
            // Prepare search requests for EC
            const searches = speciesNames.map(name => ({
                plantName: name
            }));

            const cacheKey = `ec-search-${speciesNames.join(',')}`;
            const nuxtApp = useNuxtApp();

            // First try to get from Nuxt's payload cache
            let cachedData = nuxtApp.payload?.data?.[cacheKey];
            
            if (!useCache) {
                // Clear Nuxt's payload cache if we don't want to use cache
                if (nuxtApp.payload?.data) {
                    delete nuxtApp.payload.data[cacheKey];
                }
                cachedData = null;
            }

            // If no cached data, fetch from API
            const { data: response } = await useAsyncData(
                cacheKey,
                () => $fetch('/api/ec/search', {
                    method: 'POST',
                    body: {
                        searches,
                        useCache,
                        // 7 days
                        maxCacheAge: 604800,
                        limit: 100
                    }
                }),
                {
                    getCachedData: (key) => cachedData,
                    immediate: true,
                    server: false,
                    default: () => ({ success: false, results: [] })
                }
            );

            let validationResults: Record<string, AvailabilityResult> = {};

            if (response.value?.success) {
                // Process each search result
                response.value.results.forEach((searchResult: ECSearchResponse, index: number) => {
                    const speciesName = speciesNames[index];
                    validationResults[speciesName] = {
                        isAvailable: searchResult.success && searchResult.results.length > 0,
                        source: 'evergreen_connect',
                        suppliers: searchResult.results,
                        lastChecked: new Date().toISOString()
                    };
                });
            }

            validationLoadingState.value = "idle";
            
            const validation: AvailabilityValidation = {
                name: 'availabilityStatus',
                result: validationResults,
                show: Object.values(validationResults).some(result => !result.isAvailable)
            };

            validationStore.addValidation(validation, project_id);
            return validation;

        } catch (error) {
            console.error("Error validating species availability:", error);
            validationLoadingState.value = "error";
            return {
                name: 'availabilityStatus',
                result: {},
                show: false
            };
        }
    };

    return {
        validateSpeciesNames,
        nameStatus,
        validationLoadingState,
        validateAndRefreshBiosecurity,
        validateAvailability,
        findEppoData,
        getSpeciesNames,
        validationIcons,
        fetchTnrs
    }
}
