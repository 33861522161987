<script setup lang="ts">
import 'vue3-photo-preview/dist/index.css';
import PanelComponent from './PanelComponent.vue';
import { useDockStore } from '@/stores/dockStore';


import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'


import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';
import hashSum from 'hash-sum';
import { titleCase } from "title-case";
import { useLazyAsyncData } from '#app'
import { useThrottleFn } from '@vueuse/core'

dayjs.extend(relativeTime);
dayjs.extend(calendar);

const INATURALIST_RATE_LIMIT = 1000;
let lastINatRequest = 0;

const throttledFetch = useThrottleFn(
  (url: string, options: any) => $fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      'User-Agent': 'Superseeded/1.0 (hi@superseeded.ai)'
    }
  }),
  1000
)

const props = defineProps({
  panel: { type: Object, required: true },
  height: { type: [Number, String], required: false },
  panel_id: { type: String, required: true },
});

const emit = defineEmits(['error', 'update:shared-data']);

const dockStore = useDockStore();
const { panelState, availablePanels } = storeToRefs(dockStore);

const { tools } = useTools();

// Find the Gallery tool configuration
const galleryTool = tools.find(tool => tool.id === 'gallery');

// Define the connection configuration within the component
const connectionConfig = {
  schedule: {
    metadataKeys: ['botanicalNames'],
    action: async (metadata: any) => {
      if (metadata.botanicalNames) {
        const photos = await fetchPhotosBySpeciesName(metadata.botanicalNames);
        return photos;
      }
      return [];
    }
  },
  map: {
    metadataKeys: ['mapCenter', 'mapZoom', 'taxon'],
    action: async (metadata: any[]) => {
      if (metadata.taxon) {
        console.log('Fetching photos by taxon:', metadata.taxon);
        const photos = await fetchPhotosByTaxon(metadata.taxon); // Pass entire taxon array
        return photos;
      }
      return [];
    }
  }
  // Add more connections as needed
};

// Function to get connected panels' data
const getConnectedPanelsData = () => {
  const connections = panelState.value[props.panel_id]?.data?.connections || [];
  console.log('connections', connections)
  return connections.map(connectedPanelId => {
    const connectedPanel = panelState.value[connectedPanelId];
    if (!connectedPanel) return null;
    console.log('connectedPanel', connectedPanel)
    const connectedTool = tools.find(tool => tool.uid === connectedPanel.data?.tool_uid || connectedPanel.tool_uid);
    console.log('connectedTool', connectedTool?.label)
    if (!connectedTool || !galleryTool.connectionsAllowed.includes(connectedTool.id)) return null;

    const config = connectionConfig[connectedTool.id];
    if (!config) return null;

    const relevantMetadata = config.metadataKeys.reduce((acc, key) => {
      acc[key] = connectedPanel?.data?.sharedData?.[key] || connectedPanel?.sharedData?.[key];
      return acc;
    }, {});
    console.log('relevantMetadata', relevantMetadata)
    return {
      panelId: connectedPanelId,
      toolId: connectedTool.id,
      metadata: relevantMetadata,
      action: () => config.action(relevantMetadata)
    };
  }).filter(Boolean);
};

// Compute connected panels' data
const connectedPanelsData = computed(() => getConnectedPanelsData());
console.log('connectedPanelsData', connectedPanelsData.value)
const cacheKey = computed(() => `gallery-photos-${props.panel_id}-${hashSum(connectedPanelsData.value)}`);

const { data: photos, refresh: refreshPhotos } = useLazyAsyncData(
  cacheKey.value,
  async () => {
    const photoPromises = connectedPanelsData.value.map(panel => panel.action());
    console.log('photoPromises', photoPromises);
    const photoResults = await Promise.all(photoPromises);
    console.log('photoResults', photoResults);
    return photoResults.flat();
  },
  {
    watch: [connectedPanelsData],
    getCachedData: (key) => {
      return useNuxtApp().payload.data[key] || null;
    }
  }
);

onMounted(() => {
  photos.value = [];
});

// Compute image list
const imgList = computed(() => photos.value || []);

const selectedImage = ref(null);

// Example of updating shared data
const updateGalleryData = () => {
  emit('update:shared-data', {
    selectedImage: selectedImage.value,
    // Add any other data you want to share
  });
};

// Function to fetch photos from iNaturalist
async function fetchPhotosByTaxon(taxa: any[]) {
  // Create a mapping from taxon_id to color
  const taxonIdToColor = new Map(
    taxa
      .filter(t => t.visible && t.taxon_id)
      .map(t => [t.taxon_id, t.color])
  );

  const taxonIds = Array.from(taxonIdToColor.keys()).join(',');

  if (!taxonIds) {
    console.warn('No valid taxon IDs provided.');
    return [];
  }

  console.log('Taxon IDs:', taxonIds);
  
  const url = `https://api.inaturalist.org/v1/observations?photos=true&photo_license=cc-by,cc-by-sa,cc-by-nd,cc0&taxon_id=${encodeURIComponent(taxonIds)}&per_page=200`;

  try {
    const response = await throttledFetch(url, {})
    console.log('API Response:', response);

    // Filter results to include only observations with specified taxon_ids
    const filteredResults = response.results.filter(obs => taxonIdToColor.has(obs.taxon?.id));

    if (filteredResults.length !== response.results.length) {
      console.warn('Some observations do not match the specified taxon IDs.');
    }

    // Map each observation to include the correct color based on taxon_id
    return filteredResults.filter((o:any) => !o?.license_code?.includes('nc')).map(obs => ({
      // ...obs,
      image_url: obs.photos[0]?.url_square?.replace('square', 'medium') || obs.photos[0]?.url?.replace('square', 'medium'),
      attribution: generateAttribution({
        title: obs.photos[0].title || '',
        author: obs.user.name || obs.user.login,
        species: obs.taxon?.name || 'Unknown Species',
        preferred_common_name: obs.taxon?.preferred_common_name || 'N/A',
        observer: obs.user.login,
        observed_on: obs.observed_on_string,
        license_code: obs.license_code,
        image_url: `https://ik.imagekit.io/8qxqs9cznn/${obs.photos[0]?.url_square?.replace('square', 'medium') || obs.photos[0]?.url?.replace('square', 'medium')}`,
        location: obs.location || 'Unknown Location',
        place_guess: obs.place_guess || 'N/A',
        quality_grade: obs.quality_grade || 'N/A',
        uri: obs.uri || '#',
        additional_photos: obs.photos.slice(1),
        taxon_id: obs.taxon?.id || 'N/A',
      }),
      location: obs.location,
      place_guess: obs.place_guess,
      quality_grade: obs.quality_grade,
      uri: obs.uri,
      additional_photos: obs.photos.slice(1),
      color: taxonIdToColor.get(obs.taxon?.id),
      species: obs.taxon?.name,
      preferred_common_name: obs.taxon?.preferred_common_name,
      observer: obs.user.login,
      observed_on_string: obs.observed_on_string,
      license_code: obs.license_code,
      taxon_id: obs.taxon?.id,
    }));
  } catch (error) {
    console.error('Error fetching photos:', error);
    return [];
  }
}

// Function to fetch photos by species name
async function fetchPhotosBySpeciesName(speciesNames: string[]) {
  console.log('fetching photosBySpeciesName', speciesNames)
  if (!speciesNames || speciesNames.length === 0) {
    console.warn('No valid species names provided.');
    return [];
  }

  console.log('Fetching photos for species:', speciesNames);
  
  const fetchSpecies = async (speciesName: string) => {
    const { data } = await useLazyAsyncData(
      `species-${speciesName}`,
      () => throttledFetch('https://api.inaturalist.org/v1/observations', {
        params: {
          photos: true,
          photo_license: 'cc-by,cc-by-sa,cc-by-nd,cc0',
          taxon_name: speciesName,
          per_page: 200,
          verifiable: true,
          photo_licensed: true,
          order: 'votes', 
          order_by: 'desc',
          hrank: 'species',
          iconic_taxa: 'Plantae',
          per_page: 5
        },
        headers: {
          'User-Agent': 'Superseeded/1.0 (hi@superseeded.ai)'
        }
      }),
      {
        getCachedData: (key) => {
          return useNuxtApp().payload.data[key] || null;
        }
      }
    )
    return data.value
  }

  try {
    const responses = await Promise.all(speciesNames.map(fetchSpecies));
    
    let results = responses.flatMap(response => 
      response.results
       .slice(0, 5)
        .filter((o: any) => !o?.license_code?.includes('nc') && o?.taxon?.iconic_taxon_name == 'Plantae')
        .map((obs: any) => ({
          image_url: obs.photos[0]?.url_square?.replace('square', 'medium') || obs.photos[0]?.url?.replace('square', 'medium'),
          attribution: generateAttribution({
            title: obs.photos[0].title || '',
            author: obs.user.name || obs.user.login,
            species: obs.taxon?.name || 'Unknown Species',
            preferred_common_name: obs.taxon?.preferred_common_name || 'N/A',
            observer: obs.user.login,
            observed_on: obs.observed_on_string,
            license_code: obs.license_code,
            image_url: `https://ik.imagekit.io/8qxqs9cznn/${obs.photos[0]?.url_square?.replace('square', 'medium') || obs.photos[0]?.url?.replace('square', 'medium')}`,
            location: obs.location || 'Unknown Location',
            place_guess: obs.place_guess || 'N/A',
            quality_grade: obs.quality_grade || 'N/A',
            uri: obs.uri || '#',
            additional_photos: obs.photos.slice(1),
            taxon_id: obs.taxon?.id || 'N/A',
          }),
          location: obs.location,
          place_guess: obs.place_guess,
          quality_grade: obs.quality_grade,
          uri: obs.uri,
          additional_photos: obs.photos.slice(1),
          species: obs.taxon?.name,
          preferred_common_name: obs.taxon?.preferred_common_name,
          observer: obs.user.login,
          observed_on_string: obs.observed_on_string,
          license_code: obs.license_code,
          taxon_id: obs.taxon?.id,
        }))
    );

    console.log('photosBySpeciesName filtered results', results)
    return results

  } catch (error) {
    console.error('Error fetching photos:', error);
    return [];
  }
}

// Watch for changes in connected panels' data and refresh photos
watch(connectedPanelsData, () => {
  refreshPhotos();
}, { deep: true });


const generateAttribution = (img) => {
  const {
    title,
    author,
    species,
    preferred_common_name,
    observer,
    observed_on,
    license_code,
    image_url,
    location,
    place_guess,
    quality_grade,
    uri,
    additional_photos,
    taxon_id,
  } = img;

  let attribution = '<div class="text-xs w-full whitespace-normal">';

  // Title
  attribution += `<span class='font-bold'>${title || species} (${preferred_common_name})</span><br/>`;
  
  // Author
  if (author) {
    attribution += `Author: ${author}<br/>`;
  } else if (observer) {
    attribution += `observer: ${observer}<br/>`;
  }

  // Observer
  attribution += `Observed on ${dayjs(observed_on).format('MMMM D, YYYY')}<br/>`;

  if (place_guess) {
    attribution += `${place_guess} (estimated)<br/>`;
  }

  // URI
  attribution += `source: <NuxtLink to="${uri}" target="_blank" rel="noopener noreferrer" class="underline">iNaturalist</NuxtLink>`;

  // License with Image
  let licenseUrl, licenseImage;
  if (license_code === 'cc0') {
    licenseUrl = 'https://creativecommons.org/publicdomain/zero/1.0/';
    licenseImage = 'https://licensebuttons.net/l/zero/1.0/88x31.png';
  } else {
    licenseUrl = `https://creativecommons.org/licenses/${license_code?.replace('cc-', '')}/4.0/`;
    licenseImage = `https://licensebuttons.net/l/${license_code?.replace('cc-', '')}/4.0/88x31.png`;
  }

  attribution += `
    <a href="${licenseUrl}" target="_blank" rel="noopener noreferrer">
      <img src="${licenseImage}" alt="Creative Commons License" style="vertical-align:middle;" />
    </a>
  `;

  attribution += '</div>';

  return attribution;
};



const allowedConnections = computed(() => {
  const currentTool = tools.find(tool => tool.uid === panelState.value[props.panel_id]?.data?.tool_uid)
  return currentTool?.connectionsAllowed || []
})

const connectedPanels = ref({})

const panelToolsStatus = computed(() => {
  return allowedConnections.value.reduce((acc, connection) => {
    const availablePanel = availablePanels.value.find(panel => {
      const panelTool = tools.find(t => t.uid === panel.tool_uid)
      return panelTool?.id === connection
    });
    
    acc[connection] = {
      isAvailable: !!availablePanel,
      isDisabled: !availablePanel,
    };
    return acc;
  }, {});
});

watch(panelToolsStatus, () => {
  console.log('panelToolsStatus', panelToolsStatus.value)
}, { deep: true })

const openContextMenu = (connection: string) => {
  const availablePanel = availablePanels.value.find(panel => {
    const panelTool = tools.find(t => t.uid === panel.tool_uid)
    return panelTool?.id === connection
  })
}

</script>

<template>
  <PanelComponent :panel="props.panel" :height="props.height" :panel_id="props.panel_id" toolLabel="Gallery"
    dataKey="url">
    <template #default="{ data, tool_id, updateState }">
      <div class="overflow-hidden w-full h-full p-0 m-0 rounded-md p-2 pl-4">
        <!-- <p v-if="connectedPanelsData.length > 0" class="text-md tracking-tight font-bold w-full h-fit py-2">
          <Icon name="lucide:link" class="w-4 h-4 inline-block mr-2 -mt-1" /> Images from {{
          titleCase(connectedPanelsData.map(c => c.toolId).join(' & ')) }}
        </p> -->

        <!-- <ToggleGroup type="single" class="flex justify-start space-x-2 mb-4">
          <VTooltip v-for="connection in allowedConnections" :key="connection" :content="`Images from ${connection}`">
            <ToggleGroupItem 
              :value="connection"
              class="flex items-center space-x-1 px-3 py-2" 
              variant="icon"
              
            >
              <Icon :name="tools.find(t => t.id === connection)?.icon" class="w-4 h-4" />
            </ToggleGroupItem>
          </VTooltip>
        </ToggleGroup> -->
        {{  }}
        <ScrollArea class="w-full h-full">
          <div :key="imgList.join('')" class="flex flex-wrap justify-evenly align-center items-center w-full h-full">
            <photo-provider :should-transition="true">
              <photo-consumer v-for="img in imgList" :key="img.image_url" :intro="img.image_url" :src="img.image_url"
                :download-name="img.species">
                <div :style="{ backgroundColor: img.color }"
                  class="z-[1] relative top-8 left-5 rounded-full w-fit h-4 block -mr-4 -mt-4 p-0 px-1 flex items-center justify-center text-xs text-white">
                  {{ img.species.split(' ')[0].slice(0, 3)?.toUpperCase() +' '+img.species.split(' ')[1].slice(0,
                  3).toLowerCase() }}
                </div>
                <VTooltip :content="{__html: img.attribution}">
                  <img v-if="img" :src="`https://ik.imagekit.io/8qxqs9cznn/${img.image_url}`" :alt="img.species"
                    class="w-32 h-32 object-cover m-1 cursor-pointer masked" @click="selectedImage = img" />
                </VTooltip>
              </photo-consumer>
            </photo-provider>
          </div>
        </ScrollArea>
      </div>
    </template>
  </PanelComponent>
</template>

<style scoped>
.masked {
  mask-image: url("data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0Z'/%3e%3c/svg%3e");
  mask-repeat: no-repeat;
  mask-size: 100%;
}

.tabs-container {
  @apply w-full;
}
</style>