<script setup lang="ts">
import { ref } from 'vue'

interface FileAnalysis {
  id: string;
  running: boolean;
  logs?: {
    started?: string;
    llamaparse?: {
      jobId: string;
      status: string;
      updated_at: string;
    };
  };
  file_id: string;
  output?: any;
  finished_at?: string;
}

interface FileData {
  url: string;
  filename: string;
  content_type: string;
  file_id: string;
  size: number;
  s3_key: string;
}

// Add error type
interface ProcessingError extends Error {
  message: string;
}

const processing = ref(false)
const status = ref('')
const result = ref<any>(null)

const client = useSupabaseClient()

// Create a composable to expose the processing state and methods
const useLlamaParser = () => {
  const startProcessing = async (fileData: FileData) => {
    processing.value = true
    status.value = 'Starting...'
    result.value = null
    
    let analysisRecord: FileAnalysis | null = null

    try {
      // Create initial fileanalysis record first
      const { data: record, error: insertError } = await client
        .from('fileanalysis')
        .insert({
          running: true,
          logs: {
            started: new Date().toISOString()
          },
          file_id: fileData.file_id,
          tool_uid: 'llamaindex'
        })
        .select()
        .single()

      if (insertError) throw insertError
      analysisRecord = record

      // Ensure we have all required fields
      if (!fileData.url || !fileData.filename || !fileData.content_type) {
        throw new Error('Missing required file data')
      }

      // Then start the analysis with only the required fields matching lindex.post.ts
      console.log('fileData', fileData)
      return
      const response = await $fetch('/api/ai/lindex-cloudflare', {
        method: 'POST',
        responseType: 'stream',
        headers: useRequestHeaders(['cookie']),
        body: {
          url: fileData.url,
          filename: fileData.filename,
          content_type: fileData.content_type,
          size: fileData.size,
          s3_key: fileData.s3_key
        }
      }) as Response;

      // Set up stream reader for updates
      const reader = response.body!
        .pipeThrough(new TextDecoderStream())
        .getReader();
      let buffer = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        
        buffer += value;
        const lines = buffer.split('\n');
        buffer = lines.pop() || '';

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const content = line.slice(6);
              let data;
              
              try {
                // Try to parse as JSON first
                data = JSON.parse(content);
              } catch {
                // If not JSON, treat as plain text status message
                data = {
                  status: 'processing',
                  message: content
                };
              }
              
              if (data.status === 'error') {
                await client
                  .from('fileanalysis')
                  .update({ 
                    running: false,
                    finished_at: new Date().toISOString(),
                    logs: {
                      ...analysisRecord?.logs,
                      llamaparse: {
                        status: 'error',
                        error: data.error,
                        updated_at: new Date().toISOString()
                      }
                    }
                  } as FileAnalysis)
                  .eq('id', analysisRecord?.id);

                throw new Error(data.error);
              }
              
              if (data.status === 'completed') {
                result.value = data.data;
                processing.value = false;

                await client
                  .from('fileanalysis')
                  .update({ 
                    running: false,
                    finished_at: new Date().toISOString(),
                    output: data.data,
                    logs: {
                      ...analysisRecord?.logs,
                      llamaparse: {
                        status: 'completed',
                        updated_at: new Date().toISOString()
                      }
                    }
                  } as FileAnalysis)
                  .eq('id', analysisRecord?.id);
              }
              
              // Update status and logs
              status.value = data.message || content;
              if (data) {
                await client
                  .from('fileanalysis')
                  .update({ 
                    logs: {
                      ...analysisRecord?.logs,
                      llamaparse: {
                        status: data.status || 'processing',
                        message: data.message || content,
                        updated_at: new Date().toISOString()
                      }
                    }
                  } as FileAnalysis)
                  .eq('id', analysisRecord?.id);
              }
              
            } catch (parseError) {
              console.warn('Failed to parse line:', line);
              continue;
            }
          }
        }
      }

    } catch (error: unknown) {
      const err = error as ProcessingError;
      console.error('Error with file data:', fileData);
      console.error('Error:', err);
      status.value = err.message || 'An unexpected error occurred';
      processing.value = false;

      if (analysisRecord?.id) {
        await client
          .from('fileanalysis')
          .update({ 
            running: false,
            finished_at: new Date().toISOString(),
            logs: {
              ...analysisRecord.logs,
              llamaparse: {
                status: 'error',
                error: err.message || 'An unexpected error occurred',
                updated_at: new Date().toISOString()
              }
            }
          } as FileAnalysis)
          .eq('id', analysisRecord.id)
      }
      throw err; // Re-throw to handle in the component
    }
  }

  // Update subscription to use both IDs
  const subscribeToAnalysis = (fileId: string, onUpdate: (data: any) => void) => {
    return client
      .channel(`fileanalysis:${fileId}`)
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'fileanalysis',
        filter: `file_id=eq.${fileId}`
      }, payload => {
        onUpdate(payload.new)
      })
      .subscribe()
  }

  return {
    processing,
    status,
    result,
    startProcessing,
    subscribeToAnalysis
  }
}

// Helper function to format logs for display
const formatLogMessage = (message: string | string[]): string => {
  if (Array.isArray(message)) {
    return message.join('\n')
  }
  return message
}

// Expose the composable
defineExpose({
  useLlamaParser
})
</script>

<template>
  <div class="hidden">
    <!-- Hidden component that maintains the connection -->
  </div>
</template> 