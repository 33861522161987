import { defineStore } from 'pinia'

interface Team {
  id: string
  metadata?: {
    name: string
    [key: string]: any
  }
  [key: string]: any
}

export const useUserStore = defineStore(
  "UserStore",
  () => {
    const roles = ref(["admin", "basic", "partner"]);
    const role = ref("");
    const teams = ref<Team[]>([]);
    const receiveNewsletter = ref(true);
    const selectedTeam = ref<Team>({} as Team);
    const subscriptionStatus = ref('');
    const plan = ref<Record<string, any> | null>(null);
    const user = ref({});
    const searchToken = ref('');

    const setRole = (new_role: string) => {
      role.value = new_role;
    };

    const setTeams = (new_teams: Team[]) => {
      teams.value = new_teams;
    };

    const setSelectedTeam = (new_team: Team) => {
      selectedTeam.value = new_team;
      searchToken.value = new_team?.metadata?.meilisearch_token || '';
    };

    const setReceiveNewsletter = (status: boolean) => {
      receiveNewsletter.value = status;
    };
      
    const setSubscriptionStatus = (status: string) => {
      subscriptionStatus.value = status;
    };

    const setPlan = (new_plan: Record<string, any>) => {
      plan.value = plan.value ? { ...plan.value, ...new_plan } : new_plan;
    };

    const setUser = (new_user: object) => {
      user.value = new_user
    }

    const setSearchToken = (token: string) => {
      searchToken.value = token;
    }

    return {
      roles,
      role,
      setRole,
      teams,
      setTeams,
      selectedTeam,
      setSelectedTeam,
      receiveNewsletter,
      setReceiveNewsletter,
      setSubscriptionStatus,
      subscriptionStatus,
      plan,
      setPlan,
      setUser,
      user,
      searchToken,
      setSearchToken
    };
  },
  {
    persist: false
  }
);
