import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/vue';
import RemoteSources from "@uppy/remote-sources";
import Tus from '@uppy/tus';
import { useUiStore } from '@/stores/uiStore.js';

let uppyInstance: Uppy | null = null;

export default defineNuxtPlugin((nuxtApp) => {
  const pinia = nuxtApp.$pinia;
  const uppyStore = useUppyStore(pinia);
  const uiStore = useUiStore();
  const { fileUploadContext } = storeToRefs(uiStore);
  const { setFileUploadSuccess, setFilesAdded, setUppy, resetUploads } = uppyStore;

  // Clean up existing instance if it exists
  if (uppyInstance) {
    uppyInstance.close();
    uppyInstance = null;
  }

  uppyInstance = new Uppy({
    debug: true,
    autoProceed: true,
    onBeforeUnload: true, // Warn before closing tab with uploads
  })
    .use(Tus, {
      endpoint: 'https://secure.superseeded.ai/files',
      retryDelays: [0, 1000, 3000, 5000],
      chunkSize: 50 * 1024 * 1024, // 50MB chunks
      allowedMetaFields: ['filename', 'filetype', 'type', 'name', 'size'],
      onBeforeRequest(req: any) {
        const file = req.file;
        if (!file) return;
        
        // Set proper metadata for tusd
        const meta = {
          filename: file.name,
          type: file.type,
          size: file.size.toString()
        };
        
        // Set Upload-Metadata header in tusd format
        const metaPairs = Object.entries(meta)
          .filter(([_, value]) => value != null)
          .map(([key, value]) => `${key} ${btoa(String(value))}`);
        
        req.setHeader('Upload-Metadata', metaPairs.join(','));
      },
      onAfterResponse(req: any, res: any) {
        // Get the file URL from the Location header
        const location = res.getHeader('Location');
        if (location) {
          const fileId = location.split('/').pop();
          req.fileUrl = `https://secure.superseeded.ai/files/${fileId}`;
        }
      },
      onError: function (err: Error) {
        console.error("Tus error:", err);
      },
    })
    .use(RemoteSources, {
      companionUrl: "https://secure.superseeded.ai/companion",
      companionCookiesRule: 'include',
      sources: [
        "Dropbox",
        "GoogleDrive",
        "GooglePhotos",
        "OneDrive",
        "Box",
        "Url",
      ],
    });

  // Add cleanup handlers
  uppyInstance.on('cancel-all', () => {
    resetUploads();
  });

  // Handle manual reset
  const reset = () => {
    if (uppyInstance) {
      uppyInstance.cancelAll();
      resetUploads();
    }
  };

  uppyInstance.on('upload-success', (file, response) => {
    if (!file) return;
    
    // Get the file URL from the response
    const location = response.uploadURL;
    const fileId = location.split('/').pop();
    const fileUrl = `https://secure.superseeded.ai/files/${fileId}`;
    
    // Include collection_id from fileUploadContext if available
    const metadata = {
      ...file.meta,
      url: fileUrl,
      collection_id: fileUploadContext.value?.collection_id
    };
    
    setFileUploadSuccess({
      file: {
        ...file,
        id: fileId,
        meta: metadata
      },
      response: {
        ...response,
        uploadURL: fileUrl,
        metadata
      }
    });
  });

  uppyInstance.on('files-added', (files) => {
    setFilesAdded(files);
  });

  uppyInstance.on("file-added", (file) => {
    if (!file || !uppyInstance) return;
    
    console.log('file-added', file);
    uppyInstance.setFileMeta(file.id, {
      filename: file.name,
      type: file.type,
      size: file.size
    });
  });

  // Cleanup on page unload
  if (process.client) {
    window.addEventListener('beforeunload', () => {
      if (uppyInstance) {
        uppyInstance.cancelAll();
      }
    });
  }

  setUppy(uppyInstance);
  nuxtApp.vueApp.component("Dashboard", Dashboard);
  nuxtApp.provide('uppy', uppyInstance);
  
  // Expose reset method
  return {
    provide: {
      uppyReset: reset
    }
  }
});