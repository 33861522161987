<script setup lang="ts">
import { type HTMLAttributes, computed, ref } from 'vue'
import {
  ScrollAreaCorner,
  ScrollAreaRoot,
  type ScrollAreaRootProps,
  ScrollAreaViewport,
} from 'radix-vue'
import { useScroll } from '@vueuse/core'
import ScrollBar from './ScrollBar.vue'
import { cn } from '@/lib/utils'

const props = defineProps<ScrollAreaRootProps & { class?: HTMLAttributes['class'] }>()

// Create ref for the viewport element
const viewportRef = ref<HTMLElement | null>(null)

// Use the useScroll composable
const { x, y, isScrolling, arrivedState } = useScroll(viewportRef, {
  throttle: 10,
  behavior: 'smooth'
})

// Expose scroll information via defineExpose
defineExpose({
  scroll: {
    x,
    y,
    isScrolling,
    arrivedState
  }
})

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  return delegated
})
</script>

<template>
  <ScrollAreaRoot v-bind="delegatedProps" :class="cn('relative overflow-hidden', props.class)">
    <ScrollAreaViewport 
      ref="viewportRef"
      class="h-full w-full rounded-[inherit]"
    >
      <slot />
    </ScrollAreaViewport>
    <ScrollBar />
    <ScrollAreaCorner />
  </ScrollAreaRoot>
</template>
