<template>
    <header ref="headerRef" :class="[
        'flex items-center xs:gap-0 sm:gap-4 sm:px-4 md:px-6 pl-0 sm:pl-1 pr-2 z-50 header-root border-b border-muted bg-background/50 backdrop-blur-xl',
        {
          'h-16': !isTeamRoute,
          'pt-1 !h-14': isTeamRoute
        }
    ]">
        <slot name="menu" />
        
        <div class="flex-1" />
        <Button variant="ghost" size="icon" @click="colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'" class="hidden md:block">
            <Icon v-if="colorMode.value === 'dark'" 
            name="material-symbols:light-mode" size="24"/>
            <Icon v-if="colorMode.value === 'light'" name="ph:moon" size="20"/>
        </Button>
        
        <slot name="dropdownmenu" />
    </header>
</template>

<script setup lang="ts">
import { useFixedHeader } from 'vue-use-fixed-header';
import { Button } from '@/components/ui/button'
import { useUiStore } from '@/stores/uiStore'
const colorMode = useColorMode()
const { toggleBottomSheet } = useUiStore()

const route = useRoute()

// Add computed property to check if we're on a team route
const isTeamRoute = computed(() => {
  return route.params.team || route.path.includes('/team/')
})
</script>


