<template>
  <div v-bind="$attrs">
    <Dialog @update:open="(state) => {toggleDialog(state)}">
      <DialogTrigger as-child :class="[{'p-0 m-0 rounded-xl': !margins}]">
        <slot name="trigger"></slot>
      </DialogTrigger>
      <DialogContent
        :class="[
          'dialog-shadow mt-0 pt-0 !border-0 gap-0 rounded-lg transition-all duration-180',
          {'p-0 m-0': !margins},
          width === 'full' ? 'w-[90vw] sm:w-[85vw] md:w-[80vw] lg:w-[75vw]' : `w-[${width}]`
        ]"
      >
        <DialogTitle :class="[{'p-0 m-0': !margins, 'hidden': !title}]">
          <slot name="title"></slot>
        </DialogTitle>
        <DialogDescription>
          <slot name="description"></slot>
        </DialogDescription>
        <slot name="body"></slot>
        <DialogFooter v-if="footer" :class="[{'p-0 m-0': !margins}]">
          <slot name="footer"></slot>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </div>
</template>

<script lang="ts" setup>
const uiStore = useUiStore()
const { toggleDialog } = uiStore

defineOptions({
  inheritAttrs: false
})

const { width, margins, title, footer } = defineProps({
  width: {
    type: String,
    default: '425px'
  },
  margins: {
    type: Boolean,
    default: false
  },
  title: {
    type: Boolean,
    default: true
  },
  footer: {
    type: Boolean,
    default: true
  }
})
</script>

<style>
.dialog-shadow {
  box-shadow:
    0px 11.8px 4px -16px rgba(0, 0, 0, 0.105),
    0px 20.9px 9.7px -16px rgba(0, 0, 0, 0.141),
    0px 28.1px 18.3px -16px rgba(0, 0, 0, 0.163),
    0px 35.1px 32.6px -16px rgba(0, 0, 0, 0.18),
    0px 45.5px 61px -16px rgba(0, 0, 0, 0.203),
    0px 80px 146px -16px rgba(0, 0, 0, 0.27);
}
</style>
