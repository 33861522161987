<script setup lang="ts">
const { search } = useMeiliSearch('gbif')
import { useGeolocation } from '@vueuse/core'
import { Loader2 } from 'lucide-vue-next'
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion'
import { useAsyncData } from '#app'
import type { NameStatus, NameStatusMatch } from '@/types/validation'
import { Skeleton } from '@/components/ui/skeleton'
import { isEqual } from 'lodash-es'

const { coords } = useGeolocation()

const dockStore = useDockStore()
const { activePanel, panelState, availablePanels } = storeToRefs(dockStore)
const projectStore = useProjectStore()
const { selectedProject } = storeToRefs(projectStore)

const { validateSpeciesNames, nameStatus: nameStatusResults, validationLoadingState, getSpeciesNames, fetchTnrs } = useValidation()
const { fetchSpeciesDataIndividually, results, fetchStatus: status } = useSearch()

const {data, metadata, cell, panel_id, project_id, full} = defineProps<{
  data?: string[],
  metadata?: any,
  cell?: any,
  project_id?: string,
  panel_id?: string,
  full?: boolean
}>()

const emit = defineEmits(['results'])

const showHide = ref({
  exact: true,
  inexact: true,
  invalid: true
})

const speciesNames = ref<string[]>([])

const tnrsData = ref<any[]>([])
const tnrsError = ref(null)

const refresh = async () => {
  try {
    const uniqueSpeciesNames = [...new Set(speciesNames.value)]
    
    // Create cache keys
    const gbifCacheKey = `gbif-search-${uniqueSpeciesNames.sort().join(',')}`
    const tnrsCacheKey = `tnrs-${uniqueSpeciesNames.sort().join(',')}`
    
    // Check if we have both cached results
    const nuxtApp = useNuxtApp()
    const cachedGbif = nuxtApp.payload?.data?.[gbifCacheKey]
    const cachedTnrs = nuxtApp.payload?.data?.[tnrsCacheKey]
    
    // Fetch GBIF data
    const names = await fetchSpeciesDataIndividually(uniqueSpeciesNames)
    if (!names?.length) {
      console.log('No species data returned')
      return
    }
    
    // Validate names
    const nameResults = await validateSpeciesNames(
      names.map(n => String(n?.species || n)),
      uniqueSpeciesNames.map(n => String(n)),
      selectedProject.value?.id || ''
    )
    
    if (nameResults) {
      emit('results', nameResults)
      
      showHide.value.exact = nameResults?.exact?.length > 0
      showHide.value.inexact = nameResults?.inexact?.length > 0
      showHide.value.invalid = nameResults?.invalid?.length > 0
    }
  } catch (error) {
    console.error('Error in refresh:', error)
  }
}

const getTnrsResult = (name: string) => {
  return tnrsData.value?.find(d => d.Name_submitted === name)
  
  
} 

onMounted(async () => {
  try {
    speciesNames.value = getSpeciesNames(data, panelState.value, panel_id, availablePanels.value)
    if (speciesNames.value?.length) {
      await refresh()
    }
  } catch (error) {
    console.error('Error during initial load:', error)
  }
})

watch([
  () => speciesNames.value,
  () => panelState.value?.[panel_id]?.output?.tables?.rows
], async ([newSpecies, newRows], [oldSpecies, oldRows]) => {
  if (newSpecies?.length && !isEqual(newSpecies, oldSpecies)) {
    await refresh()
  }
}, { deep: true })

const colorMode = useColorMode()

const nameStatus = ref<NameStatus>({
  exact: [],
  inexact: [],
  invalid: []
})

// Add loading states for each section
const nameValidationLoadingState = ref<"idle" | "pending" | "error">("idle")

// Add refresh functions for each section
const refreshNameValidation = async () => {
  nameValidationLoadingState.value = "pending"
  try {
    await refresh()
  } catch (error) {
    console.error('Error refreshing name validation:', error)
  } finally {
    nameValidationLoadingState.value = "idle"
  }
}

// Add cleanup on component unmount
onUnmounted(() => {
  nameStatus.value = {
    exact: [],
    inexact: [],
    invalid: []
  }
  speciesNames.value = []
})

</script>

<template>
  <div>
    <!-- Loading State -->
    <div v-if="status === 'pending' || validationLoadingState === 'pending'" class="flex flex-col gap-4 p-4">
      <!-- Header Skeleton -->
      <div class="flex items-center gap-2">
        <Skeleton class="w-5 h-5 rounded-full" />
        <Skeleton class="w-[180px] h-[16px] rounded-full" />
      </div>

      <!-- GBIF Logo Skeleton -->
      <div class="mt-2">
        <Skeleton class="w-[100px] h-[24px] rounded-md" />
      </div>

      <!-- Results Skeleton -->
      <div class="flex flex-col gap-6 mt-4">
        <!-- Exact Matches -->
        <div class="space-y-2">
          <Skeleton class="w-[120px] h-[20px] rounded-full" />
          <div class="flex flex-wrap gap-2">
            <Skeleton v-for="i in 3" :key="i" class="w-[140px] h-[24px] rounded-full" />
          </div>
        </div>

        <!-- Partial Matches -->
        <div class="space-y-2">
          <Skeleton class="w-[140px] h-[20px] rounded-full" />
          <div class="flex flex-col gap-2">
            <div v-for="i in 2" :key="i" class="flex items-center gap-2">
              <Skeleton class="w-[160px] h-[24px] rounded-full" />
              <Skeleton class="w-[120px] h-[16px] rounded-full" />
            </div>
          </div>
        </div>

        <!-- Invalid Matches -->
        <div class="space-y-2">
          <Skeleton class="w-[100px] h-[20px] rounded-full" />
          <div class="flex flex-col gap-2">
            <div class="flex items-center gap-2">
              <Skeleton class="w-[140px] h-[24px] rounded-full" />
              <Skeleton class="w-[100px] h-[16px] rounded-full" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Actual Content -->
    <div  v-else-if="results?.length > 0" class="flex flex-col items-start mb-2">
      <!-- Name Validation Section Title -->
      <div v-if="full" class="flex justify-between items-start pb-6 ml-2">
        <div>
          <h3 
            class="text-md font-[600] mb-2 group hover:cursor-pointer relative"
            @click="refreshNameValidation"
          >
            <Icon 
              v-if="nameValidationLoadingState === 'pending'" 
              name="lucide:loader-2" 
              class="w-4 h-4 animate-spin absolute opacity-100 -translate-x-2 transition-all duration-200 mr-2"
            />
            <Icon 
              v-else
              name="lucide:refresh-cw" 
              class="w-4 h-4 absolute opacity-0 group-hover:opacity-100 -translate-x-6 group-hover:translate-x-0 transition-all duration-200 text-muted-foreground mr-2 top-1"
            />
            <div class="relative group-hover:translate-x-6 transition-all duration-200">
              Species Names
            </div>
          </h3>
          <p class="text-sm text-muted-foreground max-w-2xl">
            Validates plant names against GBIF's taxonomic backbone and TNRS to ensure accuracy and standardization.
          </p>
        </div>
      </div>

      <!-- Status Icons and Summary -->
      <div class="flex flex-cols items-center gap-1 ml-0">
        <Loader2 class="w-4 h-4 animate-spin" v-if="status === 'pending'" />
        <div class="mr-1">
          <Icon 
            v-if="status === 'idle'"
            class="w-5 h-5" 
            :name="nameStatusResults?.invalid?.length || nameStatusResults?.inexact?.length 
              ? 'lets-icons:done-all-round-duotone' 
              : 'lets-icons:done-all-round-light'"
          />
        </div>
        <div>
          <p v-if="status === 'pending'" class="text-sm text-muted-foreground">
            Validating species names against GBIF
          </p>
          <p v-if="status === 'idle'" class="text-xs text-muted-foreground">
            Species name check: <span class="font-semibold">
              {{ nameStatusResults?.exact?.length || 0 }} exact
              {{ nameStatusResults?.inexact?.length ? ` ${nameStatusResults.inexact.length} partial` : '' }}
              {{ nameStatusResults?.invalid?.length ? ` and ${nameStatusResults.invalid.length} to review` : '' }}
            </span>
          </p>
        </div>
      </div>

      <div class="relative mt-2 left-0">
        <p class="text-xs text-muted-foreground flex items-center gap-2">
          Sources: 
          <!-- GBIF Logo -->
          <img 
            v-if="colorMode.value == 'dark'" 
            src="~/public/gbif-dot-org-white-logo.svg"
            class="w-18 h-6 inline-block brightness-[0.8]" 
            alt="GBIF Logo"
          />
          <img 
            v-else 
            src="~/public/gbif-dot-org-green-logo.svg" 
            class="w-18 h-6 inline-block" 
            alt="GBIF Logo"
          />
          
          <!-- TNRS Logo -->
          <img 
            v-if="nameStatusResults?.exact?.some(n => n.source === 'tnrs') || 
                  nameStatusResults?.inexact?.some(n => n.source === 'tnrs') || 
                  nameStatusResults?.invalid?.some(n => n.source === 'tnrs')" 
            src="/bien.png" 
            class="w-8 h-auto inline-block" 
            alt="TNRS Logo"
          />
        </p>
      </div>

      <div class="flex flex-col gap-2 w-full mt-5">
        <Accordion type="multiple" :default-value="['partial-match', 'invalid']" class="space-y-1">
          <AccordionItem v-for="(names, type) in { 
            'exact-match': [...new Set(nameStatusResults?.exact?.map(n => JSON.stringify(n)))].map(n => JSON.parse(n)), 
            'partial-match': [...new Set(nameStatusResults?.inexact?.map(n => JSON.stringify(n)))].map(n => JSON.parse(n)), 
            'to review': [...new Set(nameStatusResults?.invalid?.map(n => JSON.stringify(n)))].map(n => JSON.parse(n))
          }" :key="type" :value="type" class="rounded-sm accordion-item">
            <AccordionTrigger v-if="names?.length" class="px-3 py-2 text-sm hover:no-underline !bg-background/80 rounded-md">
              <h3 class="font-semibold flex flex-row items-center gap-1">
                {{ type.replace('-', ' ').replace(/\b\w/g, l => l.toUpperCase()) }}
                <Badge
                  class="select-none bg-muted-foreground/20 hover:text-background text-muted-foreground text-xs py-0 px-1 font-normal whitespace-nowrap">
                  {{ names.length }}</Badge>
              </h3>
            </AccordionTrigger>
            <AccordionContent class="px-3 mt-2 py-1 border-b-0" v-if="names?.length">
              <div v-if="type === 'exact-match'" class="flex flex-row flex-wrap gap-1">
                <Badge v-for="item in names" :key="`${item.original}-${item.matched}-${item.source}`"
                  variant="default"
                  :class="[
                    'select-none text-xs py-0 px-2 font-normal whitespace-nowrap dark:bg-muted-foreground/10 dark:text-muted-foreground',
                    { 'font-bold border-0 border-none shadow-sm': item.matched === cell }
                  ]">
                  {{ item.original }}
                  <span v-if="item.source" class="ml-1 text-[0.8em] opacity-50">({{ item.source }})</span>
                </Badge>
              </div>
              <div v-else class="flex flex-col gap-1">
                <div v-for="item in names" :key="`${item.original}-${item.matched}-${item.source}`" 
                     :class="['flex items-center gap-2']">
                  <Badge 
                    :variant="type === 'partial-match' ? 'secondary' : 'destructive'"
                    class="select-none text-xs py-0 px-2 font-normal whitespace-nowrap" 
                    :class="{ 'bg-primary text-background': item.matched === cell }">
                    {{ item.original }}
                    <span v-if="item.source" class="ml-1 text-[0.8em] opacity-50">({{ item.source }})</span>
                  </Badge>
                  <span class="text-xs border-0 border-transparent text-muted-foreground whitespace-nowrap truncate" 
                        :class="{ 'bg-background border-0 border-destructive': item.matched === cell }">
                    ({{ item.matched || 'Not found' }})
                  </span>
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  </div>
</template>
<style scoped>
.accordion-content {
  padding: 0.25rem 0.75rem;
}
:deep(.accordion-item) {
  border-bottom: none;
}

:deep(.accordion-item > div) {
  border-bottom: none;
}

:deep(.accordion-item > div > button) {
  border-bottom: none;
}

/* Add these new styles */
.group:hover .relative {
  transform: translateX(24px);
}

.group:hover .absolute {
  transform: translateX(0);
  opacity: 1;
}

</style>

