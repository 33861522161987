import { useGeolocation } from '@vueuse/core'
import type { Ref } from 'vue'

interface SearchOptions {
  limit?: number;
  distinct?: string;
  sort?: string[];
}

interface BiosecurityResults {
  eppo: Array<{ hits: any[] }>;
  padil: Array<{ data: any[] }>;
}

export function useSearch() {
  const authStore = useAuthStore();
  const { setOpenverseAccessToken } = authStore;
  const { openverseAccessToken } = storeToRefs(authStore);

  const { search } = useMeiliSearch('gbif')
  const { search: searchEPPO } = useMeiliSearch('eppo')
  const meilisearch = useMeiliSearchRef()
  const { coords } = useGeolocation()

  const results: Ref<BiosecurityResults | any> = ref(null)
  const fetchStatus = ref<'idle' | 'pending' | 'error'>('idle')

  const fetchSpeciesDataIndividually = async (
    names: string[], 
    options: SearchOptions = {}
  ): Promise<Array<{species: string}>> => {
    const { 
      limit = 1, 
      distinct = 'species'
    } = options

    fetchStatus.value = 'pending'
    results.value = []

    try {
      const processedNames = new Set()
      
      // Filter and clean names before processing
      const validNames = names
        .filter(Boolean) // Remove null/undefined
        .map(name => name.trim()) // Clean whitespace
        .filter(name => name !== '') // Remove empty strings
      
      // Create cache key with cleaned names
      const uniqueNames = [...new Set(validNames.map(n => n.toLowerCase()))]
      const cacheKey = `gbif-search-${uniqueNames.sort().join(',')}`
      
      // Try to get from Nuxt's payload cache first
      const nuxtApp = useNuxtApp()
      let cachedData = nuxtApp.payload?.data?.[cacheKey]
      
      if (cachedData) {
        console.log('Using cached GBIF data')
        results.value = cachedData
        fetchStatus.value = 'idle'
        return cachedData
      }

      // Fetch data for each unique name
      const searchPromises = uniqueNames.map(async name => {
        if (!name || processedNames.has(name)) return null
        processedNames.add(name)

        const { data: speciesData } = await useAsyncData(
          `gbif-${name}`,
          () => search(name, { limit }),
          {
            getCachedData: (key) => useNuxtApp().payload.data[key] || null
          }
        )

        return speciesData.value?.hits || []
      })

      const searchResults = await Promise.all(searchPromises)
      
      // Process and deduplicate results
      const seenSpecies = new Set()
      results.value = searchResults
        .flat()
        .filter(Boolean)
        .filter(hit => {
          if (!hit?.species) return false
          const key = hit.species.toLowerCase()
          if (seenSpecies.has(key)) return false
          seenSpecies.add(key)
          return true
        })

      // Cache the results
      if (nuxtApp.payload?.data) {
        nuxtApp.payload.data[cacheKey] = results.value
      }

      fetchStatus.value = 'idle'
      return results.value

    } catch (error) {
      console.error('Error fetching species data:', error)
      fetchStatus.value = 'error'
      throw error
    }
  }

  const fetchSpeciesDataFederated = async (data: string[]) => {
    fetchStatus.value = 'pending'
    const { data: speciesData } = await useAsyncData(
      `botanical-names-${data.join('-')}`,
      async () => {
        return await meilisearch.multiSearch({
          federation: { limit: 1 },
          queries: data.map(d => ({ 
            indexUid: 'gbif', 
            q: d
          }))
        })
      },
      {
        getCachedData: (key) => {
          const nuxtApp = useNuxtApp()
          return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
        },
      }
    )
    results.value = speciesData.value?.hits || []

    fetchStatus.value = 'idle'
    return results.value
  }

  const fetchBiosecurityData = async (
    data: string[],
    filters: string[] = ['libtype=plant']
  ): Promise<BiosecurityResults> => {
    fetchStatus.value = "pending";

    const filtersArray = Array.isArray(filters) ? filters : [filters];

    const [eppoData, padilData] = await Promise.all([
      useAsyncData(
        `eppo-${data.join('-')}-${filtersArray.join('-')}`,
        async () => {
          const searchPromises = data.map(query => searchEPPO(query, {
            filter: filtersArray.join(' AND '),
            limit: 100
          }));
          const searchResults = await Promise.all(searchPromises);
          return searchResults.flat();
        },
        {
          getCachedData: (key) => {
            const nuxtApp = useNuxtApp();
            return nuxtApp.payload.data[key] || nuxtApp.static.data[key];
          },
        }
      ),
      useAsyncData(
        `padil-${data.join('-')}`,
        async () => {
          const searchPromises = data.map(query => $fetch(`/api/bio/padil`, {
            method: 'POST',
            body: { searchQuery: query }
          }));
          const searchResults = await Promise.all(searchPromises);
          return searchResults.flat();
        },
        {
          getCachedData: (key) => {
            const nuxtApp = useNuxtApp();
            return nuxtApp.payload.data[key] || nuxtApp.static.data[key];
          },
        }
      )
    ]);

    results.value = { eppo: eppoData.data.value, padil: padilData.data.value };
    console.log('padilData', padilData.data.value)
    fetchStatus.value = "idle";
    return results.value;
  };


  const fetchSerper = async (speciesNames: string[]) => {
    const { data, refresh } = await useAsyncData(
      `serper-images-${speciesNames.join('-')}`,
      async () => {
        const requests = speciesNames.map((speciesName) =>
          $fetch('/api/serp/serper', {
            method: 'POST',
            body: { q: speciesName },
          })
        )
        const responses = await Promise.all(requests)
        return responses.flatMap((response) => (response as { results: any[] }))
      }
    )

    return {
      serperResults: data?.value,
      refreshSerper: refresh,
    }
  };

  const fetchOpenverse = async (speciesNames: string[]) => {
    const headers = useRequestHeaders(['cookie'])
    if (
      !openverseAccessToken.value?.key ||
      openverseAccessToken.value?.expiry < Date.now()
    ) {
      const ov_token = await $fetch("/api/auth/openverse", {
        method: "POST",
        headers,
      })
      setOpenverseAccessToken(ov_token.key, ov_token.expiry)
    }
    
    const apiKey = openverseAccessToken.value?.key;
    
    const openverseResults = ref<any[]>([]);

    const { data: openverseData, refresh } = await useAsyncData(
      `openverse-images-${speciesNames.join('-')}`,
      async () => {
        const requests = speciesNames.map((speciesName) =>
          $fetch(
            `https://api.openverse.org/v1/images/?q=${speciesName}&license=by,by-nd,by-sa,cc0,pdm`,
            {
              headers: {
                // Authorization: `Bearer ${apiKey}`,
                Authorization: `Bearer uVJzZqDjsrjuhqu6hYDzULxw7lx2e1`,
              },
            }
          )
        );
        const responses = await Promise.all(requests);
        return responses.flatMap(
          (response) => (response as { results: any[] }).results
        );
      }
    );

    openverseResults.value = openverseData.value || [];

    return {
      openverseResults,
      refreshOpenverse: refresh,
    };
  }

  return {
    results,
    fetchStatus,
    fetchSpeciesDataIndividually,
    fetchSpeciesDataFederated,
    fetchBiosecurityData,
    fetchSerper,
    fetchOpenverse
  }
}
