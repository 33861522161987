<script setup lang="ts">
import { TooltipRoot, type TooltipRootEmits, type TooltipRootProps, useForwardPropsEmits } from 'radix-vue'

interface TooltipEmits extends TooltipRootEmits {
  'update:open': (value: boolean) => void
}

const props = defineProps<TooltipRootProps>()
const emits = defineEmits<TooltipEmits>()

const forwarded = useForwardPropsEmits(props, emits)
</script>

<template>
  <TooltipRoot v-bind="forwarded">
    <slot />
  </TooltipRoot>
</template>
