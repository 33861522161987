<template>
  <div
  class="cursor-grab"
    :class="[$attrs.class, 
      cn(
        'squircle-15 relative mx-auto min-h-fit w-full cursor-pointer overflow-hidden p-3',
        'transition-all duration-200 ease-in-out hover:scale-[103%]',
        'dark:bg-white dark:shadow-lg',
        'bg-gray-900 bg-opacity-80 backdrop-blur-md border border-background/10 shadow-inner'
      )]"
    @click="onNotificationClick"
  >
    <div class="flex flex-row items-start">
      <div class="flex mr-3 items-center justify-start">
        <Icon :name="currentIcon" class="w-5 h-5 max-w-5 max-h-5 mt-1" :class="`text-${color}-500`" />
      </div>
      <div class="flex flex-col overflow-hidden">
        <div
          class="flex flex-row items-center whitespace-pre font-medium text-white dark:text-gray-900"
        >
          <span class="text-sm tracking-tight select-none">{{ item?.title }}</span>
          <span class="mx-1 select-none" :class="`text-${color}-500`">·</span>
          
          <span class="text-xs select-none opacity-75 font-[300] select-none" :class="`text-${color}-500`">{{ formattedTime }}</span>
        </div>
        <p class="text-sm font-normal text-white/60 dark:text-gray-700 select-none">{{ item?.message }}</p>
      </div>
    </div>
    <button
      v-if="item?.dismissible"
      @click.stop="dismiss"
      class="absolute top-2 right-2 text-gray-300 hover:text-white dark:text-gray-700 dark:hover:text-gray-900"
    >
      <Icon name="solar:close-circle-linear" class="w-4 h-4" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { cn } from "~/lib/utils";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNotivue } from 'notivue';
import { useIntervalFn } from '@vueuse/core';

dayjs.extend(relativeTime);

useSquircleObserver()

interface Item {
  title: string;
  message: string;
  dismissible?: boolean;
  onClick?: () => void;
  onDismiss?: () => void;
  promise?: Promise<any>;
  timeout?: number;
  icon?: string;
}

interface NotificationProps {
  item?: Item;
  time?: string | Date;
  icon?: string;
  color?: string;
  timeout?: number;
}

const props = withDefaults(defineProps<NotificationProps>(), {
  icon: 'solar:check-circle-linear',
  color: 'green',
  time: () => new Date()
});

const { dismiss } = useNotivue();

const formattedTime = computed(() => {
  // If time is a string and isn't a valid date format
  if (typeof props.time === 'string' && !dayjs(props.time).isValid()) {
    return props.time;
  }
  return dayjs(props.time).fromNow();
});

// Remove the interval update since we don't want to format static strings
const updateInterval = useIntervalFn(() => {
  // Only update if the time is actually a valid date
  if (typeof props.time !== 'string' || dayjs(props.time).isValid()) {
    formattedTime.value = dayjs(props.time).fromNow();
  }
}, 60000);

const onNotificationClick = () => {
  if (props.item.onClick) {
    props.item.onClick();
  }
};

const handleDismiss = () => {
  dismiss(props.item);
  if (props.item.onDismiss) {
    props.item.onDismiss();
  }
};

const currentIcon = ref(props.icon);

// Update the watcher to use the ref
watch(() => props?.item?.promise, (promise) => {
  if (promise && promise instanceof Promise) {
    // Set initial loading state
    currentIcon.value = 'line-md:loading-loop';
    
    promise.then(() => {
      // Update icon for success
      currentIcon.value = 'line-md:uploading-loop';
    }).catch((error) => {
      dismiss(props.item);
      const message = error?.response?._data || error?.message || 'An error occurred';
      useNotivue().notify({
        title: 'Error',
        message,
        icon: props.item?.icon || props?.icon || 'carbon:error-outline',
        color: 'red',
        timeout: props.item?.timeout || props?.timeout || 5000
      });
    });
  } else {
    // Reset to default icon when no promise
    currentIcon.value = props.icon;
  }
}, { immediate: true });
</script>
