<script setup lang="ts">
import { theme } from "#tailwind-config";
import { useMagicKeys, whenever } from '@vueuse/core';

/*
// ----------------------------------------------------------------------------
<template>
  <ClientOnly>
    <TabulatorTable :columns="tableColumns()" :rows="tableData" />
  </ClientOnly>
</template>
---------------------------------------------------------------------------- */

import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { TypeTabulator } from '@/types/TabulatorTableType';

// ----------------------------------------------------------------------------
// [ Props ]
type Props = {
  columns: any[];
  rows: any[];
  rowCountHeight?: number;
  option?: any;
  metadata?: any; // Added metadata prop
};
const props = withDefaults(defineProps<Props>(), {
  rowCountHeight: 0,
  option: () => TypeTabulator.Option(),
});
// [ emit ]

const emit = defineEmits<{
  'row-click': [data: any];
  'row-dbl-click': [data: any];
  'headerMouseOver': [data: any];
  'cellMouseOver': [data: any];
  'row-mouseover': [{
    event: MouseEvent,
    rowData: any,
    boundingBox: {
      x: number,
      y: number,
      width: number,
      height: number,
      top: number,
      right: number,
      bottom: number,
      left: number
    }
  }];
  init: [flag: boolean];
  tabulator: [tabulator: any];
  'table-rebuild': [func: () => void];
  'table-redraw': [func: () => void];
  'table-refresh': [func: () => void];
  'table-height': [height: number];
  'table-rect': [rect: any];
  'groupMouseMove': [data: any];
  'cellMouseLeave': [data: any];
  'cellEditing': [data: any];
  'cellEditCancelled': [data: any];
  'cellEdited': [data: any];
  'row-moved': [rowData: any];
  'column-title-changed': [{ field: string, oldTitle: string, newTitle: string }];
  'row-added': [rowData: any];
  'group-deleted': [{ groupValue: string, deletedRows: number }];
  'group-restored': [groupValue: string];
  'row-deleted': [rowData: any];
  'update:columns': [columns: any[]];
  'update:rows': [rows: any[]];
  'column-updated': [{ column: any, newDefinition: any }];
  'columns-reset': [newColumns: any[]];
  'column-added': [newColumn: any];
  'column-deleted': [deletedColumn: any];
}>();

const { escape } = useMagicKeys();

// Add this new function to emit column updates
const emitColumnUpdate = () => {
  if (tabulator.value) {
    const updatedColumns = tabulator.value.getColumns().map(col => ({
      title: col.getDefinition().title,
      field: col.getField(),
      visible: col.isVisible(),
      // Add other relevant column properties
    }));
    emit('update:columns', updatedColumns);
  }
};



// [ reactive ]
const table = ref<HTMLElement | null>(null); // reference to your table element
const tabulator = ref<any>(null); // variable to hold your table

const data = computed(() => {
  return [...props.rows];
});


const triggerDownload = (options: any) => {
  const { filename, fileType, sheetName } = options;
  if (tabulator.value) {
    let extension = fileType === "xlsx" ? "xlsx" : "csv";
    if (fileType === "xlsx" && typeof XLSX === "undefined") {
      console.error("SheetJS library is not loaded. Falling back to CSV.");
      fileType = "csv";
      extension = "csv";
    }
    tabulator.value.download(fileType, filename || `data.${extension}`);
  }
};
// Expose the method to parent components



watch(
  () => props.rows,
  () => {
    if (tabulator.value !== null) {
      tabulator.value.replaceData(data.value as any);
      hideEmptyColumns();
    }
  },
  { deep: true }
);

// watch(
//   () => storeNac.lang,
//   () => {
//     // console.log('watch  > prop.lang', tabulator.value);
//     tabulator.value.setLocale(storeNac.lang);
//     // console.log('watch  > prop.lang', tabulator.value.redraw);
//     tabulator.value.redraw(true);
//   }
// );


// Tabulator.extendModule('localize', 'langs', TypeTabulator.Option().langs);
const isInit = ref(false);

watch(isInit, (value) => {
  // console.log('table isinit', value);
  emit('init', value);
});

watch(
  () => props.columns,
  (value) => {
    tabulator.value.setColumns(props.columns);
  }
);


const deleteRow = {
  title: "",
  formatter: function (cell, formatterParams, onRendered) {
    return `<img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='%23888888' d='m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z'/%3E%3C/svg%3E"  class="w-4 h-4" />`
  },
  width: 40,
  maxWidth: 40,
  resizable: false,
  print: false,
  headerSort: false,
  clipboard: false,
  hozAlign: "center",
  cssClass: "delete-row-icon",
  cellClick: function (e, cell) {
    console.log('cell click', e, cell)
    const row = cell.getRow();
    const rowData = row.getData();
    row.delete();
    emit('row-deleted', rowData);
  }
}



const initTabulator = () => {
  // console.log('inittable');
  if (table.value === null) return;
  let height = 0;
  if (props.rowCountHeight !== 0) {
    height = props.rowCountHeight * 39 + 85 + 2;
  }

  // Format the columns and data
  const formattedData = formatForTabulator({
    rows: data.value,
    columns: props.columns.map(col => ({
      ...col,
      field: col.field || col.title,
      visible: col.visible !== false,
    }))
  });

  if (!formattedData) return;

  const option = {
    ...props.option,
    height: height === 0 ? '' : height,
    layout: "fitColumns",
    responsiveLayout: "hide",
    resizableColumnFit: true,
    columns: [
      ...formattedData.columns,
      deleteRow
    ],
    data: formattedData.rows,
    reactiveData: true,
    groupBy: props.option.groupBy || '_group',
    dataTree: true,
    dataTreeStartExpanded: true,
    movableRows: true,
    columnDefaults: {
      resizable: true,
      minWidth: 100,
      responsive: 0,
      widthGrow: 1
    },
    groupHeader: function(value, count, data, group) {
      return `<div class="group-header w-full opacity-50">
                <span>${value} <div class="inline-block text-[0.8em] inline-flex items-center justify-center aspect-square w-4 h-4 rounded-full relative -top-[1px]" style="padding: 2px; background-color: hsl(var(--muted-foreground)); color: hsl(var(--background))">${count}</div></span>
                <span class="delete-group" data-group="${value}"><img style="pointer-events: none;" src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23888888' stroke-linecap='round' stroke-width='1.5' d='M9.17 4a3.001 3.001 0 0 1 5.66 0m5.67 2h-17m15.333 2.5l-.46 6.9c-.177 2.654-.265 3.981-1.13 4.79s-2.196.81-4.856.81h-.774c-2.66 0-3.991 0-4.856-.81c-.865-.809-.954-2.136-1.13-4.79l-.46-6.9M9.5 11l.5 5m4.5-5l-.5 5'/%3E%3C/svg%3E" /></span>
              </div>`;
    },
  };

  tabulator.value = new Tabulator(table.value, option);
  tabulator.value.on('rowClick', (e: any, row: any) => {
    emit('row-click', row._row.data);
    e.stopPropagation();
  });
  tabulator.value.on('rowDblClick', (e: any, row: any) => {
    emit('row-dbl-click', row._row.data);
    e.stopPropagation();
  });
  tabulator.value.on('tableBuilding', () => (isInit.value = false));
  tabulator.value.on('tableBuilt', () => {
    isInit.value = true;
    emitTableHeight(); // Emit the table height after the table is built
    emitTableRect();
    hideEmptyColumns();
  });
  tabulator.value.on('tableDestroyed', () => (isInit.value = false));

  tabulator.value.on('headerMouseOver', (e:any, column: any) => {
    emit('headerMouseOver', {e, column})
  })
  
  tabulator.value.on('cellMouseOver', function(e:any, cell: any) {
    emit('cellMouseOver', { e, cell })
    const colElement = cell._cell.column.getElement();
    const boundingBox = colElement.getBoundingClientRect();
    // console.log('cell mouse over', boundingBox)
    emit('cellMouseOver', { event: e, cell: cell, boundingBox: boundingBox })
  })

  tabulator.value.on("rowMouseOver", function(e: any, row: any) {
    const rowElement = row.getElement();
    const boundingBox = rowElement.getBoundingClientRect();

    emit('row-mouseover', { 
      event: e, 
      rowData: row._row.data,
      boundingBox: {
        x: boundingBox.x,
        y: boundingBox.y,
        width: boundingBox.width,
        height: boundingBox.height,
        top: boundingBox.top-80,
        right: boundingBox.right,
        bottom: boundingBox.bottom,
        left: boundingBox.left
      }
    });
  });
  
  tabulator.value.on("groupMouseMove", function(e: any, group: any){
    emit('groupMouseMove', { e, group })
  });

  tabulator.value.on('cellMouseLeave', function(e: any, cell: any) {
    emit('cellMouseLeave', { e, cell })
  })
  tabulator.value.on("cellEditing", function(cell: any){
    emit('cellEditing', { cell })
  });
  tabulator.value.on("cellEditCancelled", function(cell: any){
    emit('cellEditCancelled', { cell })
  });
  tabulator.value.on("cellEdited", function(cell: any){
    emit('cellEdited', { cell })
  });

  tabulator.value.on("rowMoved", function(row){
    emit('row-moved', row.getData());
  });

  // Propagate the table to the parent control
  emit('tabulator', tabulator.value);
  
  const reBuild = () => {
    if (tabulator.value === null || isInit.value === false) return;
    tabulator.value.destroy();
    tabulator.value = null;
    initTabulator();
  };

  emit('table-rebuild', reBuild);

  const redraw = () => {
    if (tabulator.value === null || isInit.value === false) return;
    tabulator.value.redraw(true);
  };
  emit('table-redraw', redraw);

  const refresh = async () => {
    if (tabulator.value === null || isInit.value === false) return;
    await tabulator.value.replaceData(data.value as any);
  };
  emit('table-refresh', refresh);

  // Add event listener for column title editing
tabulator.value.on("columnTitleChanged", function(column){
  emit('column-title-changed', { 
    field: column.getField(),
    oldTitle: column.getDefinition().title,
    newTitle: column.getDefinition().title
  });
  
  // Emit column update event
  emit('column-updated', {
    column: column.getField(),
    newDefinition: { title: column.getDefinition().title }
  });
  
  // Update the store through emitted event
  emitColumnUpdate();
});


  // Add a new function to emit column updates
  const emitColumnUpdate = () => {
    if (tabulator.value) {
      const updatedColumns = tabulator.value.getColumns().map(col => ({
        title: col.getDefinition().title,
        field: col.getField(),
        visible: col.isVisible(),
        // Add other relevant column properties
      }));
      emit('update:columns', updatedColumns);
    }
  };

  // Modify the existing data changed handler
  tabulator.value.on("dataChanged", function(data){
    emit('update:rows', data);
  });

  // Add event listener for row added
  tabulator.value.on("rowAdded", function(row){
    emit('row-added', row.getData());
  });

  // Set up undo functionality
  const { ctrl_z } = useMagicKeys()
  let deletedGroups: { groupValue: string, rows: any[] }[] = []

  whenever(ctrl_z, () => {
    if (deletedGroups.length > 0) {
      // Restore the last deleted group
      const lastDeletedGroup = deletedGroups.pop()!;
      if (tabulator.value && lastDeletedGroup) {
        lastDeletedGroup.rows.forEach(rowData => {
          tabulator.value.addRow(rowData);
        });

        // Optionally, emit an event to inform the parent component
        emit('group-restored', lastDeletedGroup.groupValue);
      }
    } else {
      console.log("No more groups to restore");
      // Optionally, you can emit an event or show a notification to the user
      // emit('no-more-groups-to-restore');
    }
  });

  // Add this function to clear deletedGroups when necessary
  const clearDeletedGroups = () => {
    deletedGroups = [];
  };

  // Call clearDeletedGroups when the table data is reset or new data is loaded
  watch(() => props.rows, () => {
    clearDeletedGroups();
  });

  // Modify the group deletion logic to ensure we're not adding undefined groups
  tabulator.value.on("groupClick", function(e, group) {
    if (e.target.classList.contains('delete-group')) {
      e.stopPropagation(); // Prevent group toggle
      const groupValue = e.target.getAttribute('data-group');
      
      // Get all rows in the group
      const rowsToDelete = group.getRows();
      
      if (rowsToDelete.length > 0) {
        // Store the group data before deletion
        deletedGroups.push({
          groupValue,
          rows: rowsToDelete.map(row => row.getData())
        });
        
        // Delete each row
        rowsToDelete.forEach(row => row.delete());
        
        // Optionally, emit an event to inform the parent component
        emit('group-deleted', { groupValue, deletedRows: rowsToDelete.length });

        // Hide empty columns after group deletion
        hideEmptyColumns();
      }
    }
  });

  // Add this new event listener
  tabulator.value.on("dataLoaded", hideEmptyColumns);
  tabulator.value.on("dataChanged", function (data) {
    emit('update:rows', data);
  });

  // Add these new watchers and event handlers
  watch(() => tabulator.value?.getColumns(), (newColumns) => {
    if (newColumns) {
      emit('update:columns', newColumns.map(col => ({
        title: col.getDefinition().title,
        field: col.getField(),
        visible: col.isVisible(),
        // Add other relevant column properties
      })));
    }
  }, { deep: true });

  tabulator.value.on("dataChanged", function(data){
    emit('update:rows', data);
  });

};

const triggerTabulatorFunction = (functionName: string, ...args: any[]) => {
  if (tabulator.value && typeof tabulator.value[functionName] === 'function') {
    return tabulator.value[functionName](...args);
  }
  console.warn(`Function ${functionName} not found on Tabulator instance`);
};

// Expose the method to parent components


const emitTableHeight = () => {
  if (table.value) {
    const height = table.value.offsetHeight;
    emit('table-height', height);
  }
};

const emitTableRect = () => {
  if (table.value) {
    const rect = {
      left: table.value.offsetLeft,
      right: table.value.offsetLeft + table.value.offsetWidth,
      top: table.value.offsetTop,
      bottom: table.value.offsetTop + table.value.offsetHeight
    };
    emit('table-rect', rect);
  }
};

onMounted(() => {
  initTabulator();
  emitTableHeight(); // Emit the table height on mount
});
onUnmounted(() => {
  tabulator.value.destroy();
  tabulator.value = null;
});

const addNewRow = (rowData: any = {}, position: boolean | number = false) => {
  if (tabulator.value) {
    const newRow = tabulator.value.addRow(rowData, position);
    emit('row-added', newRow.getData());
    return newRow;
  }
  console.warn("Tabulator instance not initialized");
};

const hideEmptyColumns = () => {
  if (tabulator.value) {
    const columns = tabulator.value.getColumns();
    const data = tabulator.value.getData();
    const totalRows = data.length;
    const emptyThreshold = 0.8; // 80% threshold
    const hiddenColumns = new Set<string>();

    // First pass: identify which columns should be hidden
    columns.forEach((column: { getField: () => string }) => {
      const field = column.getField();
      // Check if field is defined before proceeding
      if (field !== undefined) {
        const emptyCount = data.reduce((count: number, row: Record<string, any>) => {
          const value = row[field];
          return (value === null || value === undefined || value === "") ? count + 1 : count;
        }, 0);
        
        const emptyRatio = emptyCount / totalRows;
        
        if (emptyRatio >= emptyThreshold) {
          hiddenColumns.add(field);
          try {
            tabulator.value.hideColumn(field);
          } catch (error) {
            console.warn(`Failed to hide column: ${field}`, error);
          }
        } else {
          // Only show the column if it wasn't initially set to be hidden
          const columnDef = props.columns.find(col => col.field === field || col.title === field);
          if (columnDef && columnDef.visible !== false) {
            try {
              tabulator.value.showColumn(field);
            } catch (error) {
              console.warn(`Failed to show column: ${field}`, error);
            }
          }
        }
      }
    });

    // Second pass: delete empty groups
    const groups = tabulator.value.getGroups();
    if (groups) {
      interface DeletedGroup {
        groupValue: string;
        deletedRows: number;
      }
      const groupsToDelete: DeletedGroup[] = [];
      
      groups.forEach((group: { getRows: () => any[]; getData: () => Record<string, any> }) => {
        const groupRows = group.getRows();
        const hasVisibleData = groupRows.some((row: { getData: () => Record<string, any> }) => {
          const rowData = row.getData();
          return Object.entries(rowData).some(([field, value]) => {
            return !hiddenColumns.has(field) && 
                   value !== null && 
                   value !== undefined && 
                   value !== "";
          });
        });

        if (!hasVisibleData && groupRows.length > 0) {
          // Store group info before deletion
          const groupData = group.getData();
          const groupValue = groupData._group;
          const deletedRows = groupRows.length;
          
          // Delete all rows in the empty group
          groupRows.forEach((row: { delete: () => void }) => row.delete());
          
          // Add to groups to delete list
          groupsToDelete.push({ groupValue, deletedRows });
        }
      });

      // Emit group-deleted event for each deleted group
      groupsToDelete.forEach(({ groupValue, deletedRows }) => {
        emit('group-deleted', { groupValue, deletedRows });
      });

      // Update the data in the panel state
      const updatedData = tabulator.value.getData();
      emit('update:rows', updatedData);
    }

    // Trigger layout update
    tabulator.value.redraw(true);
  }
};

const setTableEditing = (enabled: boolean) => {
  if (tabulator.value?.table) {
    tabulator.value.table.getColumns().forEach(column => {
      column.updateDefinition({ editable: enabled })
    })
  } 
}

// Watch for changes in the rows data
watch(() => props.rows, () => {
  if (tabulator.value !== null) {
    tabulator.value.replaceData(data.value as any);
    hideEmptyColumns();
  }
}, { deep: true });

// Add new methods to handle column operations
const updateColumn = async (column: any, newDefinition: any) => {
  if (tabulator.value) {
    try {
      await tabulator.value.updateColumnDefinition(column, newDefinition);
      emit('column-updated', { column, newDefinition });
      emitColumnUpdate(); // Emit updated columns array
    } catch (error) {
      console.error('Error updating column:', error);
    }
  }
};

const resetColumns = async (newColumns: any[]) => {
  if (tabulator.value) {
    try {
      await tabulator.value.setColumns(newColumns);
      emit('columns-reset', newColumns);
      emitColumnUpdate(); // Emit updated columns array
    } catch (error) {
      console.error('Error resetting columns:', error);
    }
  }
};

const addColumn = async (columnDefinition: any, before = false, position?: string) => {
  if (tabulator.value) {
    try {
      const newColumn = await tabulator.value.addColumn(columnDefinition, before, position);
      emit('column-added', newColumn);
      emitColumnUpdate(); // Emit updated columns array
    } catch (error) {
      console.error('Error adding column:', error);
    }
  }
};

const deleteColumn = async (column: any) => {
  if (tabulator.value) {
    try {
      await tabulator.value.deleteColumn(column);
      emit('column-deleted', column);
      emitColumnUpdate(); // Emit updated columns array
    } catch (error) {
      console.error('Error deleting column:', error);
    }
  }
};

// Add interfaces for our data structures
interface TableRow {
  [key: string]: any;
  id?: string;
  _group?: string;
}

interface TableColumn {
  title: string;
  field: string;
  headerWordWrap?: boolean;
  headerSort?: boolean;
  editor?: string;
  resizable?: string;
  headerVertical?: boolean;
  formatter?: (cell: any) => string;
  editorParams?: {
    values: any[];
  };
  widthShrink?: number;
  minWidth?: number;
  responsive?: number;
  widthGrow?: number;
}

interface ParsedTables {
  rows: TableRow[];
  columns: TableColumn[];
}

// Add this helper function to generate a consistent color for a value
const getColorForValue = (value: string, index: number): string => {
  const colors = [
    'bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-300',
    'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-300',
    'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-300',
    'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-300',
    'bg-purple-100 text-purple-800 dark:bg-purple-900/30 dark:text-purple-300',
    'bg-pink-100 text-pink-800 dark:bg-pink-900/30 dark:text-pink-300',
    'bg-indigo-100 text-indigo-800 dark:bg-indigo-900/30 dark:text-indigo-300',
  ];
  return colors[index % colors.length];
};

const formatForTabulator = (parsedTables: any): ParsedTables | null => {
  if (!parsedTables) {
    console.log('could not get tabulator data');
    return null;
  }

  console.log('formatting for tabulator');

  // Check if parsedTables is already in {rows: [], columns: []} format
  if (Array.isArray(parsedTables.rows) && Array.isArray(parsedTables.columns)) {
    // First analyze the data to find columns with low cardinality and numeric columns
    const columnStats = new Map<string, { 
      uniqueValues: number; 
      values: any[]; 
      isNumeric: boolean;
      max?: number;
      min?: number;
    }>();
    
    parsedTables.columns.forEach((col: TableColumn) => {
      const field = col.field;
      const values = parsedTables.rows.map((row: TableRow) => row[field])
        .filter(v => v !== null && v !== undefined && v !== "");
      const uniqueValues = new Set(values).size;
      
      // Check if column is numeric
      const isNumeric = values.every(v => !isNaN(Number(v)));
      let max, min;
      if (isNumeric) {
        max = Math.max(...values.map(v => Number(v)));
        min = Math.min(...values.map(v => Number(v)));
      }
      
      columnStats.set(field, {
        uniqueValues,
        values: [...new Set(values)],
        isNumeric,
        max,
        min
      });
    });

    // Create column definitions with appropriate formatters
    const columns = parsedTables.columns.map((col: TableColumn) => {
      const stats = columnStats.get(col.field);
      const baseColumn: TableColumn = {
        title: col.title || col.field,
        field: col.field,
        headerWordWrap: true,
        headerSort: true,
        editor: "input",
        resizable: "header",
        headerVertical: false,
        vertAlign: "middle",
        minWidth: 100,
        responsive: 0,
        widthGrow: col.field === 'botanical_name' ? 2 : 1,
      };

      // Handle different column types
      switch (true) {
        // Botanical name column
        case col.field === 'botanical_name' || col.field === 'Botanical Name':
          return {
            ...baseColumn,
            formatter: function(cell: any): string {
              const botanicalName = cell.getValue();
              if (!botanicalName) return "";
              const rowData = cell.getRow().getData();
              const commonName = rowData.common_name || rowData['Common Name'] || '';
              return `<div class="flex items-center">
                       <span class="inline-flex flex-col px-2 py-1 rounded-md text-xs font-medium bg-slate-100 text-slate-800 dark:bg-slate-800 dark:text-slate-300 border border-slate-300/50 dark:border-slate-600/50">
                         <span>${botanicalName}</span>
                         ${commonName ? `<span class="text-slate-500 dark:text-slate-400 font-normal">${commonName}</span>` : ''}
                       </span>
                     </div>`;
            }
          };
        
        // Numeric columns
        case stats?.isNumeric:
          return {
            ...baseColumn,
            formatter: function(cell: any): string {
              const value = cell.getValue();
              if (value === null || value === undefined || value === "") return "";
              const numValue = Number(value);
              const max = stats.max || 0;
              const percentage = ((numValue - (stats.min || 0)) / (max - (stats.min || 0))) * 100;
              return `<div class="relative w-full h-full flex items-center px-2">
                       <div class="absolute left-0 top-0 bottom-0 bg-primary/10 dark:bg-primary/20" 
                            style="width: ${percentage}%; transition: width 0.2s ease;"></div>
                       <span class="relative z-10 tabulator-cell-value">
                         ${numValue.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                       </span>
                     </div>`;
            }
          };
        
        // Low cardinality columns (2-5 unique values)
        case stats && stats.uniqueValues >= 2 && stats.uniqueValues <= 5:
          const valueColorMap = new Map(
            stats.values.map((value: any, index: number) => [value, getColorForValue(String(value), index)])
          );
          return {
            ...baseColumn,
            formatter: function(cell: any): string {
              const value = cell.getValue();
              if (value === null || value === undefined || value === "") return "";
              const colorClass = valueColorMap.get(value) || 'bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-gray-300';
              return `<span class="px-2.5 py-0.5 rounded-full text-xs font-medium ${colorClass}">${value}</span>`;
            },
            editorParams: {
              values: stats.values
            }
          };
        
        // Default text formatter
        default:
          return {
            ...baseColumn,
            formatter: function(cell: any): string {
              const value = cell.getValue();
              return value?.toString() || '';
            }
          };
      }
    });

    return { columns, rows: parsedTables.rows };
  }

  // Handle the fallback format as before
  const allColumns = new Set<string>();
  const rows: TableRow[] = [];

  parsedTables.forEach((table: any, tableIndex: number) => {
    table.columns.forEach((col: string) => allColumns.add(col));
    table.rows.forEach((row: any[], rowIndex: number) => {
      const rowData: TableRow = {
        id: `${tableIndex}-${rowIndex}`,
        _group: table.group_id || 'Plants'
      };
      table.columns.forEach((col: string, colIndex: number) => {
        rowData[col] = row[colIndex];
      });
      rows.push(rowData);
    });
  });

  // Analyze the data for the fallback format
  const columnStats = new Map<string, { uniqueValues: number; values: any[] }>();
  allColumns.forEach((col: string) => {
    const uniqueValues = new Set(rows.map(row => row[col])).size;
    const values = [...new Set(rows.map(row => row[col]))];
    columnStats.set(col, {
      uniqueValues,
      values: values.filter(v => v !== null && v !== undefined && v !== "")
    });
  });

  // Create column definitions with appropriate formatters
  const columns: TableColumn[] = Array.from(allColumns).map(col => {
    const stats = columnStats.get(col);
    const baseColumn: TableColumn = {
      title: col,
      field: col,
      headerWordWrap: true,
      headerSort: true,
      editor: "input",
      resizable: "header",
      headerVertical: false,
      vertAlign: "middle",
      minWidth: 100,
      responsive: 0
    };

    // If column has low cardinality (2-5 unique values), use badge formatter
    if (stats && stats.uniqueValues >= 2 && stats.uniqueValues <= 5) {
      const valueColorMap = new Map(
        stats.values.map((value: any, index: number) => [value, getColorForValue(String(value), index)])
      );

      return {
        ...baseColumn,
        formatter: function(cell: any): string {
          const value = cell.getValue();
          if (value === null || value === undefined || value === "") return "";
          
          const colorClass = valueColorMap.get(value) || 'bg-gray-100 text-gray-800 dark:bg-gray-900/30 dark:text-gray-300';
          return `<span class="px-2.5 py-0.5 rounded-full text-xs font-medium ${colorClass}">${value}</span>`;
        },
        editorParams: {
          values: stats.values
        }
      };
    }

    // For other columns, use default number/text formatter
    return {
      ...baseColumn,
      formatter: function(cell: any): string {
        const value = cell.getValue();
        if (typeof value === 'number') {
          return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
        }
        return value?.toString() || '';
      }
    };
  });

  return { columns, rows };
};

defineExpose({
  emitColumnUpdate,
  triggerTabulatorFunction,
  addNewRow,
  triggerDownload,
  hideEmptyColumns,
  setTableEditing,
  updateColumn,
  resetColumns,
  addColumn,
  deleteColumn,
});

whenever(escape, () => {
  if (tabulator.value?.element.classList.contains('bulk-edit-preview')) {
    tabulator.value.element.classList.remove('bulk-edit-preview');
    // Reset all cells that were marked for editing
    const cells = tabulator.value.element.querySelectorAll('.would-be-edited');
    cells.forEach(cell => {
      cell.classList.remove('would-be-edited');
      cell.style.opacity = '1';
    });
    // Reset all rows
    const rows = tabulator.value.element.querySelectorAll('.tabulator-row');
    rows.forEach(row => {
      row.style.opacity = '1';
    });
  }
});

</script>
<template>
  <div ref="table" class="w-full h-full my-0 py-0"></div>
</template>

<style lang="scss">

$headerBackgroundColor: hsl(var(--primary)) !important;

.tabulator-cell {
    font-size: 11px;
    color: hsl(var(--primary));
    position: relative;
    overflow: visible !important;
    background: transparent !important;

    // Style for badge spans
    span.rounded-full {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      transition: all 0.2s ease;
      font-weight: 500;
      line-height: 1;
      min-width: fit-content;
      padding: 0.35em 0.7em;
      background-clip: padding-box;
      
      // Add border styles based on background color
      &.bg-blue-100 { border: 1px solid rgb(37 99 235 / 0.2); }
      &.bg-green-100 { border: 1px solid rgb(22 163 74 / 0.2); }
      &.bg-red-100 { border: 1px solid rgb(220 38 38 / 0.2); }
      &.bg-yellow-100 { border: 1px solid rgb(202 138 4 / 0.2); }
      &.bg-purple-100 { border: 1px solid rgb(147 51 234 / 0.2); }
      &.bg-pink-100 { border: 1px solid rgb(219 39 119 / 0.2); }
      &.bg-indigo-100 { border: 1px solid rgb(79 70 229 / 0.2); }
      &.bg-gray-100 { border: 1px solid rgb(75 85 99 / 0.2); }

      // Dark mode border styles
      .dark & {
        &.dark\:bg-blue-900\/30 { border: 1px solid rgb(37 99 235 / 0.3); }
        &.dark\:bg-green-900\/30 { border: 1px solid rgb(22 163 74 / 0.3); }
        &.dark\:bg-red-900\/30 { border: 1px solid rgb(220 38 38 / 0.3); }
        &.dark\:bg-yellow-900\/30 { border: 1px solid rgb(202 138 4 / 0.3); }
        &.dark\:bg-purple-900\/30 { border: 1px solid rgb(147 51 234 / 0.3); }
        &.dark\:bg-pink-900\/30 { border: 1px solid rgb(219 39 119 / 0.3); }
        &.dark\:bg-indigo-900\/30 { border: 1px solid rgb(79 70 229 / 0.3); }
        &.dark\:bg-gray-900\/30 { border: 1px solid rgb(75 85 99 / 0.3); }
      }
    }

    // Styles for numeric bars
    .tabulator-cell-value {
      position: relative;
      z-index: 2;
    }

    // Bar background
    .bg-primary\/10 {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: hsl(var(--primary) / 0.1);
      transition: width 0.2s ease;
    }

    .dark & .bg-primary\/20 {
      background-color: hsl(var(--primary) / 0.2);
    }
}

// .tabulator * {
//     &::-webkit-scrollbar-track {
//         border-radius: 4px;
//         background: #fff;
//     }

//     &::-webkit-scrollbar {
//         width: 14px;
//         background: transparent;
//     }

//     &::-webkit-scrollbar-thumb {
//         border-radius: 4px;
//         // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//         // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//         background: #212121; 
//     }

//     .tabulator-table {
//         background: transparent;
//         color: #fff;
//     }
// }

.group-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.delete-group {
  cursor: pointer;
  padding: 0 5px;
}


div[role="rowgroup"] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.delete-row-icon {
  border: none !important;
  opacity: 0;
}

.tabulator-row.tabulator-selected {
  background-color: transparent !important;
}

.tabulator {
    .tabulator-header {
      background-color: hsl(var(--background)) !important;
      border-bottom: 1px solid hsl(var(--border));

      .tabulator-col {
        background-color: hsl(var(--background)) !important;
      }
    }

    // Reset styles when not in bulk edit mode
    &:not(.bulk-edit-preview) {
      .tabulator-row {
        opacity: 1 !important;
        transition: opacity 0.2s ease;
      }
      
      .tabulator-cell {
        opacity: 1 !important;
        transition: opacity 0.2s ease;
        
        &.would-be-edited {
          background-color: transparent !important;
        }
      }
    }
}

</style>