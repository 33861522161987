import { defineStore } from 'pinia'

interface UploadSuccess {
  file: {
    id: string;
    meta: {
      url: string;
      collection_id?: string;
      [key: string]: any;
    };
    [key: string]: any;
  };
  response: {
    uploadURL: string;
    metadata: {
      collection_id?: string;
      [key: string]: any;
    };
    [key: string]: any;
  };
}

export const useUppyStore = defineStore(
  "UppyStore",
  () => {
    const filesAdded = ref([]);
    const uploadSuccess = ref<UploadSuccess>({} as UploadSuccess);
    const uploadError = ref({});
    const uppy = ref(null);
    const removeFileRequested = ref(false);
    const syncing = ref(false)

    const setFileUploadSuccess = (s: UploadSuccess) => {
      uploadSuccess.value = s;
    };

    const setFileUploadError = (e: any) => {
      uploadError.value = e;
    };

    const setFilesAdded = (files: any) => {
      filesAdded.value = files;
    };
    const setUppy = (uppy: any) => {
      uppy.value = uppy;
    };

    const setRemoveFileRequested = (val: boolean) => {
      removeFileRequested.value = val;
    };

    const setSyncing = (val: boolean) => {
      syncing.value = val;
    };

    const resetUploads = () => {
      filesAdded.value = [];
      uploadSuccess.value = {} as UploadSuccess;
      uploadError.value = {};
      removeFileRequested.value = false;
      syncing.value = false;
      
      // Reset Uppy instance if it exists
      if (uppy.value) {
        uppy.value.reset();
      }
    };

    return {
      filesAdded,
      setFilesAdded,
      uploadSuccess,
      setFileUploadSuccess,
      uploadError,
      setFileUploadError,
      uppy,
      setUppy,
      removeFileRequested,
      setRemoveFileRequested,
      syncing,
      setSyncing,
      resetUploads,
    };
  },
  {
    persist: {
      paths: [
        "filesAdded",
        "uploadSuccess",
        "uploadError",
        "removeFileRequested",
      ],
    },
  }
);