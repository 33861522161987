export default defineNuxtRouteMiddleware((to) => {
  const userStore = useUserStore()
  const { user, plan } = storeToRefs(userStore)

  // Add debugging logs
  console.log('Route params:', to.params)
  console.log('Current path:', to.path)
  console.log('User state:', {
    user: user.value,
    plan: plan.value,
    subscriptionStatus: user.value?.app_metadata?.subscription_status,
    planName: plan.value?.product?.name
  })

  // Only apply this middleware to team routes
  if (to.params.type !== 'team') {
    console.log('Not a team route, skipping middleware')
    return
  }

  // Type guard to ensure user and plan are properly typed
  if (!user.value || !plan.value) {
    console.log('User or plan not loaded, redirecting')
    return navigateTo('/login')
  }

  // Check subscription status first
  const validSubscriptionStatus = ['active', 'trialing', 'past_due'].includes(
    user.value.app_metadata?.subscription_status ?? ''
  )

  // Only consider Teams plan if subscription is valid
  const planName = validSubscriptionStatus ? plan.value.product?.name : 'Basic'
  const hasTeamsPlan = planName === 'Teams'

  const hasTeamAccess = hasTeamsPlan && validSubscriptionStatus

  console.log('Team access check:', {
    hasTeamAccess,
    hasTeamsPlan,
    validSubscriptionStatus,
    subscriptionStatus: user.value.app_metadata?.subscription_status,
    planName,
    actualPlanName: plan.value.product?.name
  })

  if (!hasTeamAccess) {
    console.log('Access denied, redirecting to user route')
    return navigateTo({
      path: `/user/${to.params.team}`,
      query: to.query
    })
  }

  console.log('Access granted')
})
