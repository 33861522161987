<script setup lang="ts">
const uiStore = useUiStore()
const { reviewDrawerOpen, reviewDrawerDynamicComponent, reviewSideDrawerMode } = storeToRefs(uiStore)
const { toggleReviewSideDrawerMode } = uiStore

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  DrawerOverlay
} from '@/components/ui/drawer'





</script>

<template>
    <ClientOnly>
        <Drawer :should-scale-background="false" direction="right" :modal="false" v-model:open="reviewDrawerOpen"
            :defaultOpen="false" :closeOnEscape="false">
            <DrawerOverlay style="opacity: 0 !important;" class="pointer-events-none shadow-none" />

            <DrawerContent :disableOutsidePointerEvents="false" :trapFocus="false"
                class="h-full bg-transparent shadow-none">
                <component ref="drawer" v-if="reviewDrawerDynamicComponent" :is="reviewDrawerDynamicComponent" />
                    
            </DrawerContent>
            
        </Drawer>
    </ClientOnly>
</template>

<style>
div[role="dialog"] {
  margin: 0;
}

div[data-state="open"][vaul-drawer-visible="true"][vaul-overlay][vaul-snap-points="false"][vaul-snap-points-overlay="true"] {
  opacity: 0.4 !important;
}

div[data-state="open"][vaul-drawer-visible="true"] {
  border: none !important;
}

div[data-dismissable-layer][role="dialog"][data-state="open"][vaul-drawer-visible="true"]>div:first-child {
  display: none !important;
}

</style>