import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { camelize, getCurrentInstance, toHandlerKey } from 'vue'
import type { Updater } from '@tanstack/vue-table'
import { faker } from "@faker-js/faker/locale/en";
import hashSum from 'hash-sum';
import CustomEase from 'gsap/CustomEase'; 

faker.seed(Math.floor(Math.random() * 10000));

const rootPageRef = ref(null);

export function useRootPageRef() {
  return { rootPageRef };
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function valueUpdater<T extends Updater<any>>(updaterOrValue: T, ref: Ref) {
    ref.value
        = typeof updaterOrValue === 'function'
            ? updaterOrValue(ref.value)
            : updaterOrValue
}

export function dummyProjectTitle(val: string) {
    return `${faker.color.human()}, ${faker.location.city() }`;
}

export function generateRowId(row: Record<string, any>): string {
    // Create a string from all column values except 'heading'
    const rowString = Object.entries(row)
        .filter(([key]) => key !== 'heading')
        .map(([_, value]) => value)
        .join('');
    
    // Generate a hash for the row
    return hashSum(rowString);
}

export function dataURLtoFile(dataurl: string, filename: string) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}


export function deepToRaw<T extends Record<string, any>>(sourceObj: T): T {
  const objectIterator = (input: any): any => {
    if (Array.isArray(input)) {
      return input.map((item) => objectIterator(item));
    }
    if (isRef(input) || isReactive(input) || isProxy(input)) {
      return objectIterator(toRaw(input));
    }
    if (input && typeof input === "object") {
      return Object.keys(input).reduce((acc, key) => {
        acc[key as keyof typeof acc] = objectIterator(input[key]);
        return acc;
      }, {} as T);
    }
    return input;
  };

  return objectIterator(sourceObj);
}


// export a custom gsap ease
export const slowOut = CustomEase.create("slow-out", "0.120, 0.991, 0.000, 0.999");