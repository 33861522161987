import { defineStore } from 'pinia'
import type { Validation, PanelValidations, AvailabilityValidation } from "@/types/validation";

export const useValidationStore = defineStore(
  "ValidationStore",
  () => {
    const allValidations = ref<Record<string, PanelValidations>>({});
    const validationLoadingState = ref<"idle" | "pending" | "error">("idle");

    const addValidation = (validation: Validation | AvailabilityValidation, project_id: string) => {
      if (!allValidations.value[project_id]) {
        allValidations.value[project_id] = {};
      }
      allValidations.value[project_id][validation.name] = validation as Validation;
    };

    const removeAllValidations = (project_id: string) => {
      allValidations.value[project_id] = {};
    };

    // Reset store to empty state
    const resetToInitial = () => {
      allValidations.value = {};
      validationLoadingState.value = "idle";
    };

    return {
      allValidations,
      validationLoadingState,
      addValidation,
      removeAllValidations,
      resetToInitial
    };
  },
  {
    persist: true,
  }
);