import { useUserStore } from '@/stores/userStore';

export default defineNuxtRouteMiddleware((to) => {
  // Only run on team pages
  if (!to.path.startsWith('/team/')) return;

  const teamId = to.params.id as string;
  if (!teamId) return;

  const userStore = useUserStore();
  const team = userStore.teams.find(t => t.id === teamId);
  
  if (team) {
    userStore.setSelectedTeam(team);
  }
}); 