<template>
  <div ref="containerRef" class="w-full inline-block" :class="[$attrs.class]" >
    <div v-auto-animate class="flex items-center ">
      <div v-if="isEditing" class="flex items-center gap-2 max-"
           :class="{ '': !allowOverflow }">
        <input
          v-model="editValue" 
          ref="inputRef"
          :class="['px-2 flex-grow truncate', inputClass]"
          :style="getInputStyle()"
          @keyup.enter="saveEdit"
          :placeholder="placeholder"
        />
        <div :class="['flex items-center gap-1 shrink-0', buttonsClass]">
          <button 
            @click="saveEdit" 
            class="size-6 p-1 rounded-md hover:bg-muted/50 h-full"
            type="button"
          >
            <Icon :name="saveIcon" class="size-4" />
          </button>
          <button 
            @click="cancelEdit" 
            class="size-6 p-1 rounded-md hover:bg-muted/50 h-full"
            type="button"
          >
            <Icon :name="cancelIcon" class="size-4" />
          </button>
        </div>
      </div>
      <div v-else 
           @click="startEditing" 
           class="cursor-pointer w-full flex items-center"
           :class="[previewClass]">
        <span class="truncate max-w-full">{{ modelValue || placeholder }}</span>
        <slot name="preview-suffix" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, nextTick, onMounted, onUnmounted } from 'vue'
import { useMagicKeys, onClickOutside } from '@vueuse/core'

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  previewClass: {
    type: String,
    default: 'text-muted-foreground'
  },
  inputClass: {
    type: String,
    default: ''
  },
  buttonsClass: {
    type: String,
    default: ''
  },
  buttonsWidth: {
    type: Number,
    default: 80
  },
  saveIcon: {
    type: String,
    default: 'lucide:check'
  },
  cancelIcon: {
    type: String,
    default: 'lucide:x'
  },
  scale: {
    type: Number,
    default: 1
  },
  allowOverflow: {
    type: Boolean,
    default: true
  },
  minInputWidth: {
    type: Number,
    default: 90
  },
  maxInputWidth: {
    type: Number,
    default: Infinity
  },
  placeholder: {
    type: String,
    default: ''
  },
  edit: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue', 'update:edit'])
const isEditing = ref(props.edit)
const editValue = ref(props.modelValue)
const containerRef = ref<HTMLElement | null>(null)
const inputRef = ref<HTMLInputElement | null>(null)

const { escape } = useMagicKeys()

watch(escape, (pressed) => {
  if (pressed && isEditing.value) {
    cancelEdit()
  }
})

watch(() => props.edit, (newValue) => {
  isEditing.value = newValue
  if (newValue) {
    nextTick(() => {
      focusInput()
    })
  }
})

onClickOutside(containerRef, () => {
  if (isEditing.value) {
    saveEdit()
  }
})

onMounted(() => {
  if (props.edit) {
    startEditing()
  }
})

watch(() => props.modelValue, (newValue) => {
  if (!isEditing.value) {
    editValue.value = newValue
  }
})

const focusInput = () => {
  if (inputRef.value) {
    inputRef.value.focus()
    inputRef.value.setSelectionRange(
      0,
      editValue.value?.length || 0
    )
  }
}

const startEditing = () => {
  editValue.value = props.modelValue
  isEditing.value = true
  emit('update:edit', true)
  nextTick(() => {
    focusInput()
  })
}

const saveEdit = () => {
  emit('update:modelValue', editValue.value)
  isEditing.value = false
  emit('update:edit', false)
}

const cancelEdit = () => {
  editValue.value = props.modelValue
  isEditing.value = false
  emit('update:edit', false)
}

const getInputStyle = () => {
  const baseStyle = {
    minWidth: `${props.minInputWidth}px`,
    maxWidth: props.maxInputWidth === Infinity ? '100%' : `${props.maxInputWidth}px`,
    width: 'auto',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'inherit',
    fontFamily: 'inherit',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }

  if (!props.allowOverflow) {
    baseStyle.width = `calc(100% - ${props.buttonsWidth}px)`
  }

  return baseStyle
}

onUnmounted(() => {
  isEditing.value = false
  emit('update:edit', false)
})
</script>

<style scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input {
  max-width: 100%;
  box-sizing: border-box;
  font-size: inherit;
}
</style>